import React, { useContext, useState } from "react";
import LoginBg from "../../images/hospital/newPas.png";
import Logo from "../../images/logo.png";
import newLight from "../../images/newLogo.png";
import SliderImage from "../../images/hospital/slider.png";
import ForgotRight from "../../images/patient/forgot.png";

import GlobalStorage from "../../Storage/ContextProvider";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { toast } from "react-toastify";

const HospitalForgotPassword = () => {
  const { darkMode } = useContext(GlobalStorage);
  const [email, setEmail] = useState("");

  const handleForgetPassword = async () => {
    const data = {
      email,
    };

    const activateAccount = await axios({
      method: "POST",
      url: `${BASE_URL}/hospital/forgot-password`,
      data: data,
    })
      .then((response) => {
        console.log("Activation Success", response);
        toast.success(response?.data?.msg);
        setEmail("")
        // navigate("/patient/signin");
        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Hospital Activation  Error", error.response.data);
        toast.error(error.response.data);
      });
  };

  return (
    <>
      <div className="flex w-full">
        <div
          className="w-[35%] h-[100vh] pl-[50px] bg-[#E7F4F2] dark:bg-[#67A599]"
          style={{
            backgroundImage: `url(${LoginBg})`,
            backgroundSize: "cover",

            backgroundBlendMode: "plus darker",
          }}
        >
          {darkMode ? (
            <img src={Logo} className="w-[260px] h-[100px] mt-[30px]" alt="" />
          ) : (
            <img
              src={newLight}
              className="w-[250px] h-[95px] mt-[30px]"
              alt=""
            />
          )}
          <Link to="/hospital/login">
            <div className="my-16 dark:text-white text-[var(--doctor-color)] flex items-center gap-2">
              <FaChevronLeft /> Back to Login
            </div>
          </Link>

          <div className="font-[600] dark:text-[#fff] text-[var(--doctor-color)]  text-[35px]">
            Forgot Your
            <span className=" font-inter text-[#DCA071] dark:text-[#FFCFAA] ml-3">
              Password {/* {userName?.userName} */}
            </span>
          </div>

          <p className="text-[16px] dark:text-[#fff] text-[var(--doctor-color)]">
            Don’t worry, happens to all of us. Enter your email below to recover
            your password
          </p>

          <div className="relative placeholder:text-[var(--doctor-color)] dark:placeholder:text-[#67A599)] w-[90%] py-[11px] rounded-full outline-none text-[var(--doctor-color)] dark:text-[#67A599] text-[16px] px-[20px] my-[20px] bg-[#FAD9BF] dark:bg-[#fff]">
            <input
              type="text"
              className="outline-none w-[90%] bg-transparent"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <button
            className="w-[80%] h-[50px] rounded-full outline-none bg-[var(--doctor-color)] text-[#fff] dark:bg-[#FFCFAA] dark:text-[#000000] font-[500] my-[30px]"
            onClick={handleForgetPassword}
          >
            Submit
          </button>
        </div>
        <div className="w-[65%] h-[100vh] bg-[var(--doctor-color)]">
          <div className="h-[90vh] w-full flex justify-center items-center">
            <div className="">
              <img src={ForgotRight} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HospitalForgotPassword;
