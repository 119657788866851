import React, { useContext, useState } from "react";
import Header from "../../Component/Patient/Header";
import Footer from '../../Component/Patient/Footer'
import GlobalStorage from "../../Storage/ContextProvider";

const TermsOfUse = () => {
  const { isOpen } = useContext(GlobalStorage);

  return (
    <>
    <Header />
    {!isOpen && (
      <>
        <div className="p-[20px] bg-[#CCE1DD] text-[#161C2D] font-inter] 500px:px-[70px] ">
          <div className=" border-b border-[var(--doctor-color)] pb-[15px]">
            <div>
              <h2 className="font-[500] text-[25px]">Privacy & Security</h2>
              {/* <p className="font-[300] text-[16px] text-[var(--doctor-color)]">
            Customization settings
          </p> */}
            </div>
          </div>
         
       
        </div>
        <div className="p-[20px] bg-[#CCE1DD] text-[#161C2D] font-inter] 500px:px-[70px] border-b border-[#fff] pb-8">
          <div className="mt-[35px]">
            <div className="flex gap-[50px] items-center">
              <h3 className="text-[20px]">Terms of Use</h3>
              {/* <p className="text-[16px] font-[300] text-[#FFCFAA]">
            Click to read more in detail
          </p> */}
            </div>
            <div>
              <p className="text-[16px] font-[300] mt-[20px]">
                Please read these Terms of Use carefully before using our
                platform and services. By accessing or using our platform, you
                agree to be bound by these Terms of Use. If you do not agree
                with any part of these terms, please refrain from using our
                platform.
              </p>
              <div className="ml-5 mt-5">
                <h2 className="font-[600] text-[17px] font-inter ">
                  1. Introduction
                </h2>
                <div className="ml-4 mt-2">
                  {/* <h3 className="font-[400]">1.1 Personal Information</h3> */}
                  <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                    1.1 MedInclude operates an online platform that helps
                    patients better understand medical information by making
                    it available in simple terms and multiple languages.
                  </p>
                </div>
                <div className="ml-4 mt-2">
                  {/* <h3 className="font-[400]">1.2 Health Information</h3> */}
                  <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                    1.2 By using our platform, you acknowledge that MedInclude
                    is not a healthcare provider and does not provide medical
                    advice. Our platform is intended to help transcribe
                    medical information from your healthcare provider and
                    should not substitute professional medical advice,
                    diagnosis, or treatment. Always consult a qualified
                    healthcare professional for any medical concerns or
                    questions.
                  </p>
                </div>
              </div>

              <div className="ml-5 mt-5">
                <h2 className="font-[600] text-[17px] font-inter ">
                  2. User Eligibility and Account
                </h2>
                <div className="ml-4 mt-2">
                  <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                    2.1 To use our platform, you must be at least 18 years old
                    and have the legal capacity to enter into a binding
                    agreement. By creating an account, you represent and
                    warrant that you meet these requirements.
                  </p>
                </div>
                <div className="ml-4 mt-2">
                  <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                    2.2 You are responsible for maintaining the
                    confidentiality of your account credentials, including
                    your username and password. Any activity that occurs under
                    your account is your responsibility. If you become aware
                    of any unauthorized use of your account, please notify us
                    immediately
                  </p>
                </div>
              </div>

              <div className="ml-5 mt-5">
                <h2 className="font-[600] text-[17px] font-inter ">
                  3. User Obligations
                </h2>
                <div className="ml-4 mt-2">
                  <h3 className="font-[400]">
                    3.1 You agree to use our platform for lawful purposes and
                    in accordance with these Terms of Use. You shall not:
                  </h3>
                  <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                    a) Violate any applicable laws, regulations, or
                    third-party rights. <br />
                    b) Engage in any fraudulent, abusive, or deceptive
                    behavior. <br />
                    c) Use our platform to transmit any viruses, malware, or
                    harmful content. <br />
                    d) Interfere with the proper functioning of our platform
                    or circumvent any security measures. <br />
                    e) Collect or harvest any user data without our consent.
                  </p>
                </div>
                <div className="ml-4 mt-2">
                  <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                    3.2 You are solely responsible for the accuracy and
                    completeness of any information you provide on our
                    platform. You agree to update your information promptly if
                    there are any changes.
                  </p>
                </div>
              </div>
              <div className="ml-5 mt-5">
                <h2 className="font-[600] text-[17px] font-inter ">
                  4.Intellectual Property Rights
                </h2>

                <div className="ml-4 mt-2">
                  <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                    4.1 Our platform and its contents, including but not
                    limited to text, graphics, logos, images, videos, and
                    software, are owned or licensed by MedInclude and are
                    protected by intellectual property laws. You may not use,
                    reproduce, modify, or distribute any part of our platform
                    without our prior written consent.
                  </p>
                  <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                    4.2 By using our platform, you grant MedInclude a
                    worldwide, non-exclusive, royalty-free license to use,
                    reproduce, distribute, and display any content you submit
                    through the platform for the purpose of providing our
                    services. You represent and warrant that you have the
                    necessary rights to grant this license.
                  </p>
                </div>
              </div>
              <div className="ml-5 mt-5">
                <h2 className="font-[600] text-[17px] font-inter ">
                  5. Third-Party Links and Content
                </h2>

                <div className="ml-4 mt-2">
                  <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                    5.1 Our platform may contain links to third-party websites
                    or resources. These links are provided for your
                    convenience only, and we do not endorse or control the
                    content of any linked websites. Your interactions with any
                    third-party websites are at your own risk, and you should
                    review their terms of use and privacy policies.
                  </p>
                </div>
              </div>
              <div className="ml-5 mt-5">
                <h2 className="font-[600] text-[17px] font-inter ">
                  6. Privacy
                </h2>

                <div className="ml-4 mt-2">
                  <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                    6.1 Our collection and use of your personal information
                    are governed by our Privacy Policy, which forms an
                    integral part of these Terms of Use. By using our
                    platform, you consent to the collection, use, and
                    disclosure of your personal information as described in
                    our Privacy Policy.
                  </p>
                </div>
              </div>
              <div className="ml-5 mt-5">
                <h2 className="font-[600] text-[17px] font-inter ">
                  7. Disclaimer of Warranties
                </h2>

                <div className="ml-4 mt-2">
                  <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                    7.1 We strive to provide accurate and up-to-date
                    information on our platform. However, we do not warrant
                    the completeness, reliability, or accuracy of any
                    information provided. Your use of our platform is at your
                    own risk.
                  </p>
                  <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                    7.2 MedInclude disclaims all warranties, whether express,
                    implied, or statutory, including but not limited to
                    warranties.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mt-[35px]">
        <div className="flex gap-[50px] items-center">
          <h3 className="text-[20px]">Security</h3>
          <p className="text-[16px] font-[300] text-[#FFCFAA]">
            Click to read more in detail
          </p>
        </div>
        <p className="text-[16px] font-[300] mt-[20px]">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa
          mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
          fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
          vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor
          ornare leo, non suscipit magna interdum eu. Curabitur pellentesque
          nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo
          lacus at sodales sodales. Quisque sagittis orci ut diam condimentum,
          vel euismod erat placerat. In iaculis arcu eros, eget tempus orci
          facilisis id. Praesent lorem orci, mattis non efficitur id,
          ultricies vel nibh. Sed volutpat lacus vitae gravida viverra. Fusce
          vel tempor elit. Proin tempus, magna id scelerisque v
        </p>
      </div> */}
        </div>

        <Footer />
      </>
    )}
  </>
  )
}

export default TermsOfUse