import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import BASE_URL from "../../../baseUrl";
import { isAuth } from "../../../auth/helper";
import PatientImage from "../../../images/patientImage.png";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import GlobalStorage from "../../../Storage/ContextProvider";

const NewTransccript = () => {
  const [patients, setPatients] = useState();
  const {
    newPatient1,
    setNewPatient1,
    patientDetail,
    setPatientDetail,
    selectPatient,
    setSelectPatient,
    doctorPatient,
    setDoctorPatient,
  } = useContext(GlobalStorage);
  const navigate = useNavigate();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          //   setShowBtn(false);
          setNewPatient1(false);
          // alert("You clicked outside of me!");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleNavigate = (data) => {
    setPatientDetail(data);
    setSelectPatient(true);
    navigate(`/doctor/dashboard`);
    setNewPatient1(false);
  };
  return (
    <>
      <div className="absolute top-[20%] left-[45%]  z-[101]" ref={wrapperRef}>
        <div>
          <button className="w-[350px] h-[60px] bg-[var(--doctor-color)] dark:bg-[#67A599] text-[23px] font-[500] text-[#fff] rounded-2xl">
            Select a Patient
          </button>
          <div className=" w-[350px] bg-[#CCE1DD] dark:bg-[var(--dark-primary)] p-[15px] mt-[15px] rounded-2xl">
            <div className="flex items-center w-full h-[45px]  bg-[#fff] rounded-lg relative text-[#CCE1DD] ">
              <CiSearch className="absolute left-[10px]" />
              <input
                className="bg-transparent w-full outline-none ml-[35px] mr-[10px] placeholder-[#CCE1DD]"
                type="text"
                placeholder="Search Patient"
              />
            </div>
            <div className="mt-[20px] h-[350px] overflow-y-scroll">
              <div className="lastBorderr p-[10px] border-b border-[rgba(119,119,119,0.3)]">
                {doctorPatient?.map((data, index) => {
                  return (
                    <>
                      <div
                        className="flex items-center justify-between py-3 px-3 cursor-pointer hover:border-[1px] border-[#FFCFAA]"
                        onClick={() => handleNavigate(data)}
                      >
                        <div className="flex gap-[15px] items-center">
                          <img
                            src={PatientImage}
                            className="w-[65px] h-[65px] rounded-full"
                            alt=""
                          />
                          <div>
                            <p className="text-[17px] text-[var(--doctor-color)] dark:text-[#fff]">
                              {data?.name}
                            </p>
                            <span className="text-[15px] text-[#67A599] dark:text-[#FFCFAA]">
                              Last Consult: 1 day ago
                            </span>
                          </div>
                        </div>
                        <div>
                          <p className="text-[17px]">M</p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="flex justify-center items-center text-[#fff] dark:text-[var(--dark-primary)]">
              <div
                className=" flex justify-center items-center w-[26px] h-[26px] bg-[var(--doctor-color)] dark:bg-[#fff] rounded-md cursor-pointer"
                onClick={() => setNewPatient1(false)}
              >
                <RxCross2 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewTransccript;
