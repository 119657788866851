import React, { useContext, useRef, useState } from "react";
import Insta from "../../images/patient/insta.png";
import LinkedIn from "../../images/patient/linkledIn.png";
import Twitor from "../../images/patient/twitter.png";
import Fb from "../../images/patient/fb.png";
import { Global } from "@emotion/react";
import GlobalStorage from "../../Storage/ContextProvider";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const { isRequestDemo, setRequestDemo,toggleProduct, setToggleProduct} = useContext(GlobalStorage);
  const navigate = useNavigate();
  const sectionRef = useRef(null);

  function scrollToTop() {
    window.scrollTo(0, 0);
  }
  // const scrollToSection = () => {
  //   // Find the target section using its ID
  //   const section = document.getElementById("productHome");

  //   // Scroll to the target section
  //   section.scrollIntoView({ behavior: "smooth" }); // You can also use 'auto' instead of 'smooth' for immediate scrolling
  // };

  const handleScollProduct =  () => {
    navigate("/#sectionRef");
    setToggleProduct(!toggleProduct)  
 };
  return (
    <>
      <div className="bg-[#CCE1DD] py-14 600px:px-[100px] px-[30px]  1300px:px-[150px]  text-[#161C2D] font-inter">
        <div className="flex 900px:flex-row  flex-col gap-11 justify-start  items-start 900px:justify-between border-b pb-[90px] 600px:px-[50px]">
          <div className="">
            <p className="opacity-70 text-[15px]">Company</p>
            <div className="text-[17px] mt-2 flex flex-col gap-1 ">
              <p onClick={handleScollProduct} className="py-2 cursor-pointer">
                {" "}
                Product
              </p>
              <Link
                to="/pricing"
                onClick={scrollToTop}
                className="py-2"
              >
                Pricing
              </Link>
              <p
                onClick={() => setRequestDemo(true)}
                className="cursor-pointer"
              >
                Request a Demo
              </p>
            </div>
          </div>
          {/* <div className="">
            <p className="opacity-70 text-[15px] ">Support</p>
            <div className="text-[17px] mt-2">
              <p className="py-2">Contact us</p>
              <p className="py-2">FAQ </p>
              <p className="py-2">Resources</p>
             
            </div>
          </div> */}

          {/* <div className="">
            <p className="opacity-70 text-[15px]">Services</p>
            <div className="text-[17px] mt-2">
              <p className="py-2">Digital Marketing</p>
              <p className="py-2">Content Writing </p>
              <p className="py-2">SEO for business</p>
              <p className="py-2">Help desk</p>
              <p className="py-2">UI Design</p>
            </div>
          </div> */}

          <div className="">
            <p className="opacity-70 text-[15px]">Legal</p>
            <div className="text-[17px] mt-2 flex flex-col ">
              <Link to="/privacy-policy" className="py-2" onClick={scrollToTop}>Privacy Policy</Link>
              <Link to="/terms-of-use" className="py-2" onClick={scrollToTop}>Terms & Condition </Link>
              {/* <p className="py-2">Return and Policy</p> */}
            </div>
          </div>

          <div className="">
            <p className="opacity-70 text-[15px]">Contact Us</p>
            <div className="text-[17px] mt-2 font-[700] text-[var(--doctor-color)]">
              <p className="py-2">admin@medinclude.com</p>
              {/* <p className="py-2">+133-394-3439-1435</p> */}
            </div>
          </div>
        </div>

        <div className="flex 900px: justify-between items-center 900px:flex-row flex-col text-[#161C2D]  text-[15px] gap-10">
          <p>© 2024 Copyright, All Right Reserved, Medinclude</p>
          <div className="flex gap-7">
            <img src={Twitor} className="w-[18px] h-[15px]" alt="" />
            {/* <img src={Fb} className="w-[19px] h-[19px]" alt="" />
                <img src={Insta} className="w-[19px] h-[19px]" alt="" /> */}
            <img src={LinkedIn} className="w-[19px] h-[19px]" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
