import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { isAuth } from "./helper";
const DoctorPrivateRoute = () => {
  return isAuth() && isAuth()?.role === "doctor" ? (
    <Outlet />
  ) : (
    <Navigate to="/doctor/login" />
  );
};

export default DoctorPrivateRoute;
