import React, { useContext, useState } from "react";
import Logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { RiMenu2Line } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import GlobalStorage from "../../Storage/ContextProvider";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { isAuth } from "../../auth/helper";

const Header = () => {
  // const [isOpen, setIsOpen] = useState(false);

  const { isOpen, setIsOpen, isRequestDemo, setRequestDemo } =
    useContext(GlobalStorage);
  const [isLoginDropdown, setIsLoginDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = (data) => {
    setIsOpen(false);
    navigate(data);
  };
  const handleLoginDropdown = () => {
    setIsLoginDropdown(!isLoginDropdown);
  };
  const options = [
    {
      name: "Hospital",
      link: "/hospital/login",
    },
    {
      name: "Doctor",
      link: "/doctor/login",
    },
    {
      name: "Patient",
      link: "/patient/signin",
    },
  ];
  const handleOptionClick = (option) => {
    setIsLoginDropdown(false);
    navigate(`${option?.link}`);
  };
  return (
    <>
      <div className="h-[80px] bg-[var(--doctor-color)] 900px:flex items-center justify-around  hidden">
        <img src={Logo} className="w-[130px] h-[50px]" alt="" />
        <div className="flex items-center gap-[35px] font-inter text-[15px] text-white border-r-2 border-[#fff] pr-8">
          <Link to="/">Home</Link>
          {/* <Link to="/patient">Patient Portal</Link> */}
          <Link to="/pricing">Pricing</Link>
          <Link to="/contact-us">Contact Us</Link>
          <button
            className="rounded-[10px] bg-[#FFCFAA] px-[25px] py-[10px]  font-[600] text-[var(--doctor-color)] "
            onClick={() => setRequestDemo(true)}
          >
            Request Demo
          </button>
        </div>
        <div className="flex items-center gap-[65px] font-inter text-[15px] text-white">
          {!isAuth() && (
            <div className="relative">
              <button
                className="dropdown-toggle flex items-center gap-3 "
                onClick={handleLoginDropdown}
              >
                Login
                {isLoginDropdown ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </button>

              {isLoginDropdown && (
                <div className="absolute  top-[50px] -left-7 w-[120px] flex justify-center flex-col items-center py-3 z-[101] bg-[#67A599] text-[#FFCFAA] font-inter font-[500] text-[13px]">
                  {options.map((option, index) => (
                    <div
                      key={index}
                      className="py-2 cursor-pointer"
                      onClick={() => handleOptionClick(option)}
                    >
                      {option?.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {isAuth() && isAuth()?.role === "doctor" && (
            <Link to="/doctor/dashboard">Dashboard</Link>
          )}
          {isAuth() && isAuth()?.role === "patient" && (
            <Link to="/patient/dashboard">Dashboard</Link>
          )}

          {isAuth() && isAuth()?.role === "hospital" && (
            <Link to="/hospital">Dashboard</Link>
          )}

          {/* <Link to="/patient/signin">Login</Link> */}
          <Link
            to="/pricing"
            className="rounded-[10px] bg-[#354392] px-[25px] py-[10px] font-inter font-[600]  text-[15px] text-white"
          >
            Get Started
          </Link>
        </div>
      </div>

      <div className="900px:hidden flex  h-[60px] bg-[var(--doctor-color)]  items-center px-6 justify-between text-white">
        <img src={Logo} className="w-[120px] h-[40px]" alt="" />
        <div>
          {isOpen ? (
            <>
              <RxCross2 size={22} onClick={() => setIsOpen(!isOpen)} />
            </>
          ) : (
            <RiMenu2Line size={22} onClick={() => setIsOpen(!isOpen)} />
          )}
        </div>
      </div>

      {isOpen && (
        <div className=" bg-white text-black">
          <div className="flex  flex-col h-[90vh]  justify-center items-center gap-10  font-inter text-[17px] font-[500]">
            <button onClick={() => handleNavigate("/")}>Home</button>
            <button onClick={() => handleNavigate("/pricing")}>
              Pricing{" "}
            </button>
            <button onClick={() => handleNavigate("/contact-us")}>
              Contact Us
            </button>
            <button
              className="rounded-[10px] bg-[#FFCFAA] px-[25px] py-[10px]  font-[600] text-[var(--doctor-color)] "
              onClick={() => (setRequestDemo(true), setIsOpen(false))}
            >
              Request Demo
            </button>
            <div className="w-[50%] border-b-[1px] border-[black]"></div>
            <button onClick={() => handleNavigate("/patient/signin")}>
              Login{" "}
            </button>
            <button
              className="rounded-[10px] bg-[#354392] px-[25px] py-[10px] font-inter font-[600]  text-[15px] text-white"
              onClick={() => handleNavigate("/pricing")}
            >
              Get Started
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
