import React, { useState } from "react";
import LoginImage from "../../images/hospital/login.png";
import LoginBg from "../../images/hospital/loginBg.png";
import Logo from "../../images/logo.png";
import { FaXTwitter } from "react-icons/fa6";
import { ImLinkedin2 } from "react-icons/im";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import BASE_URL from "../../baseUrl";
const PasswordSetup = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const params = useParams();
  const navigate = useNavigate()

  const handleSetPassword = async () => {
    // const {password, confirmPassword}
    if (password !== confirmPassword) {
      return toast.error("Password not matched");
    } else {
      const data = {
        password,
        token:params?.token
      };

      const activateAccount = await axios({
        method: "POST",
        url: `${BASE_URL}/hospital/accountActivation`,
        data: data,
      })
        .then((response) => {
          console.log("Login Success", response);
          toast.success(response?.data?.message);
          navigate("/hospital/login");
          // setDataUser(response.data.user)
        })
        .catch((error) => {
          console.log("Login  Error", error.response.data);
          toast.error(error.response.data);
        });
    }
  };
  return (
    <>
      <div className=" w-[100%] dark:bg-[var(--dark-secondary)] flex p-[25px] justify-center items-center">
        <div className="w-[50%] flex flex-col items-center justify-center">
          <img src={LoginImage} className="w-[570px] h-[400px]" alt="" />
          <p className="text-[50px] font-[600] text-[var(--doctor-color)] dark:text-[#fff]">
            Setup your <br /> Password in{" "}
            <span className="text-[#DCA071] dark:text-[#FFCFAA]">
              {" "}
              few minutes.
            </span>
          </p>
        </div>

        <div className="w-[50%] flex items-center justify-center  ">
          <div
            className="w-[500px] h-[645px] flex flex-col bg-[var(--dark-secondary)] items-center justify-center text-[var(--dark-primary)] rounded-[10px]"
            style={{
              backgroundImage: `url(${LoginBg})`,
              boxShadow: "0px 14px 35px 0px #0000004A",
              backgroundPosition: "center",
            }}
          >
            <img src={Logo} className="w-[250px] h-[100px]" alt="" />
            <div className="flex items-center justify-center flex-col w-full  text-[#67A599] text-[16px]">
              <input
                type="text"
                className="w-[400px] h-[50px] rounded-full outline-none ml-[34px] my-[15px] px-[20px]"
                placeholder=" Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                type="text"
                className="w-[400px] h-[50px] rounded-full outline-none ml-[34px] my-[15px] px-[20px]"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <button
              className="bg-[#FFCFAA] w-[250px] h-[50px] ml-[34px] rounded-full mt-[30px] text-[#000000] font-[500] text-[16px]"
              onClick={handleSetPassword}
            >
              Set Your Password
            </button>

            <div className="text-[#fff] flex gap-[15px] mt-[100px] ">
              <FaXTwitter size={22} />
              <ImLinkedin2 size={22} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordSetup;
