import React, { useEffect, useState } from "react";
import PatientSidebar from "../../../Component/Patient/Layout/PatientSidebar";
import { TiFilter } from "react-icons/ti";
import ReportImg from "../../../images/patient/report.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import { getCookie, isAuth } from "../../../auth/helper";
import { HiOutlineShare } from "react-icons/hi";
import moment from "moment";



const PateientReport = () => {
  const [patientReport, setPatientReport] = useState();
  const token = getCookie("token")
  useEffect(() => {
    loadReport();
  }, []);
  const loadReport = () => {
    axios({
      method: "POST",
      url: `${BASE_URL}/reports/patient`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { patientId: isAuth()?._id },
    })
      .then((response) => {
        console.log("Get Patient Report", response.data);
        setPatientReport(response.data);
      })
      .catch((error) => {
        console.log("Get Patient Report Error ERROR", error.response);
      });
  };
  const reportData = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
  ];
  const navigate = useNavigate();
  return (
    <>
      <PatientSidebar activeLink="My Reports">
        <div className="px-5 ">
          <div className="flex justify-between items-center">
            <h2 className="font-inter font-[500] 600px:text-[35px] text-[25px] dark:text-white text-[#052721]">
              Reports
            </h2>
            {/* <button className="flex items-center dark:bg-[#DCA071] bg-[#DCA071] text-white justify-center 600px:w-[150px] h-[45px]  w-[100px] rounded-full">
              {" "}
              <TiFilter /> Filter
            </button> */}
          </div>

          <div className="mt-4">
            {patientReport &&
              patientReport?.map((data, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="1200px:h-[100px] mb-3 1200px:py-0 py-7 bg-white dark:bg-[var(--dark-primary)] flex items-center justify-between 1000px:flex-row flex-col px-4 gap-4 rounded-xl font-inter"
                    >
                      <div className="flex items-center gap-2 ">
                        {/* <img
                          src={ReportImg}
                          className="w-[75px] h-[66px]"
                          alt=""
                        /> */}
                        <div>
                          <p className="font-[500] text-[16px] text-[#67A599] dark:text-white">
                           {data?.doctor?.fullName}
                          </p>
                          <p className="1220px:text-[15px] text-[11px] dark:opacity-50">
                          {moment(new Date(data?.createdAt)).format(
                          "MMMM Do YYYY, h:mm:ss a "
                        )}
                          </p>
                        </div>
                      </div>

                      <div className="1200px:w-[60%] w-full">
                        {/* <h3 className="text-[#67A599] dark:text-white">Migrane</h3> */}
                        <p className=" dark:opacity-50 1200px:text-[16px] text-[14px] break-words	">
                          {data?.originalReport?.substring(0, 200)}
                        </p>
                      </div>
                      <div className="flex items-center gap-3">
                      {/* <HiOutlineShare className="text-[#DCA071] text-[24px]" /> */}
                        <button
                          className="1200px:px-12 px-8 h-[38px] rounded-full border-2  border-[#DCA071] text-[15px] text-[#DCA071]"
                          onClick={() =>
                            navigate(`/dashboard/myreport/${data?._id}`)
                          }
                        >
                          View details
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </PatientSidebar>
    </>
  );
};

export default PateientReport;
