import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import Profile from "../../../images/topProfile.png";
import NewProfile from "../../../images/patient/profile.png";
import { CgProfile } from "react-icons/cg";

import { IoNotificationsSharp } from "react-icons/io5";
import { MdOutlineNotificationAdd } from "react-icons/md";
import { Link } from "react-router-dom";

const PatientTopBar = () => {
  const [showNotification, setShowNotification] = useState(false);

  return (
    <>
      <div className="h-full 600px:mt-1 mt-6 flex items-center w-full justify-end 600px:flex-row flex-col gap-6 600px:mb-1 mb-5  ">
        {/* <div className="flex items-center 900px:w-[600px] h-[45px] w-full  rounded-full relative text-[#000] dark:text-[#fff] 600px:ml-[20px] ">
          <CiSearch className="absolute left-[10px]" />
          <input
            className="bg-transparent w-full outline-none ml-[35px] mr-[10px] placeholder:text-[#000] dark:placeholder-white"
            type="text"
            placeholder="Search Dashboard"
          />
        </div> */}

        <div className="600px:mr-[50px] flex 600px:justify-start justify-between 600px:flex-row flex-row-reverse 600px:w-auto w-[90%] 600px:gap-[30px] 600px:mb-1 mb-4">
          <div
            className="relative p-2 flex justify-center items-center     rounded-full dark:bg-[rgba(244,245,249,1)] bg-[var(--doctor-color)] cursor-pointer"
            // onClick={() => setShowNotification(!showNotification)}
          >
            <MdOutlineNotificationAdd
              
              className="dark:text-[#67A599] text-[#fff] text-[28px] "
            />
            {showNotification && (
              <>
                <div className="absolute top-[60px] -right-[15px] w-[272px] max-h-[312px] bg-[#fff] z-[101] rounded-[11px]">
                  <div className=" notyNotch absolute right-[20px] -top-[25px] "></div>
                  <div>
                    <div className="border-b-[1px] border-[rgba(119, 119, 119, 0.5)] mx-[15px] py-[15px] flex items-center gap-[15px]">
                      <img
                        src={Profile}
                        className="w-[44px] h-[44px] rounded-full"
                        alt=""
                      />
                      <div className="">
                        <p className="text-[13px] font-[500] text-[#373737]">
                          Hospital
                        </p>
                        <p className="text-[13px] text-[#373737]">
                          You have been assigned a new patient
                        </p>
                      </div>
                    </div>
                    <div className="border-b-[1px] border-[rgba(119, 119, 119, 0.5)] mx-[15px] py-[15px] flex items-center gap-[15px]">
                      <img
                        src={Profile}
                        className="w-[44px] h-[44px] rounded-full"
                        alt=""
                      />
                      <div className="">
                        <p className="text-[13px] font-[500] text-[#373737]">
                          Hospital
                        </p>
                        <p className="text-[13px] text-[#373737]">
                          You have been assigned a new patient
                        </p>
                      </div>
                    </div>

                    <div className="border-b-[1px] border-[rgba(119, 119, 119, 0.5)] mx-[15px] py-[15px] flex items-center gap-[15px]">
                      <img
                        src={Profile}
                        className="w-[44px] h-[44px] rounded-full"
                        alt=""
                      />
                      <div className="">
                        <p className="text-[13px] font-[500] text-[#373737]">
                          Hospital
                        </p>
                        <p className="text-[13px] text-[#373737]">
                          You have been assigned a new patient
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <Link to="/dashboard/setting" className="flex items-center gap-[15px] cursor-pointer  ">
            {/* <img
              src={NewProfile}
              className="900px:w-[44px] w-[60px] h-[44px] rounded-full"
              alt=""
            /> */}
            <CgProfile  className="w-[60px] h-[44px]"/>
          </Link>
        </div>
      </div>
    </>
  );
};

export default PatientTopBar;
