import React, { useContext, useState } from "react";
import Header from "../../Component/Patient/Header";
import Footer from "../../Component/Patient/Footer";
import GlobalStorage from "../../Storage/ContextProvider";
import RequestDemo from "../../Component/Patient/RequestDemo";
import { toast } from "react-toastify";
import axios from "axios";
import BASE_URL from "../../baseUrl";

const PatientContactus = () => {
  const { isOpen, setIsOpen, isRequestDemo } = useContext(GlobalStorage);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");

  const handleContactForm = async () => {
    if (!fullName) {
      return toast.error("Please Enter the Full Name");
    }
    if (!email) {
      return toast.error("Please Enter the Email Address");
    }
    if (!reason) {
      return toast.error("Please Enter Your Reason");
    }
    if (!message) {
      return toast.error("Message should not be empty");
    }
    const data = {
      fullName: fullName,
      emailId: email,
      RFRO: reason,
      msg: message,
    };
    const contactForm = await axios({
      method: "POST",
      url: `${BASE_URL}/contactusform`,
      data: data,
    })
      .then((response) => {
        console.log("Hospital Update] Success", response);
        toast.success(response?.data);
        // setRequestDemo(false);
      })
      .catch((error) => {
        console.log("Hospital Update  Error", error.response.data);
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <>
      <Header />
      {!isOpen && (
        <>
          <div className="relative">
            <div
              className={`${
                isRequestDemo ? "h-[90vh] overflow-hidden opacity-25" : ""
              } `}
            >
              <div className="900px:px-[100px] 500px:px-[50px] px-[25px] 1300px:px-[200px] bg-[#CCE1DD] py-[100px] text-[#161C2D]  font-inter">
                <h1 className="font-[700] 500px:text-[45px] text-[35px]">
                  Have a Question for us?
                </h1>
                <p className="text-[19px] opacity-70 mt-3">
                  Need to reach out to us? Fill the form below and we’ll get
                  back to you as soon as possible.
                </p>

                <div className="mt-14">
                  <div className="flex 500px:flex-row flex-col items-center gap-7 w-full mb-6">
                    <input
                      type="text"
                      className="500px:w-[50%] w-full bg-white outline-none h-[60px] px-6"
                      placeholder="Full Name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    <input
                      type="text"
                      className="500px:w-[50%] w-full bg-white outline-none h-[60px] px-6"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="mb-6">
                    <input
                      type="text"
                      className="w-full bg-white outline-none h-[60px] px-6"
                      placeholder="Reason for the reaching out"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </div>
                  <div>
                    <textarea
                      name=""
                      id=""
                      className="w-full bg-white outline-none max-h-[250px] min-h-[250px] px-6 py-5"
                      placeholder="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="flex justify-center items-center mt-12">
                    <button
                      className="bg-[#FFCFAA] px-[25px] py-[10px] text-[var(--doctor-color)] font-[600]"
                      onClick={handleContactForm}
                    >
                      Reach Out
                    </button>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
            {isRequestDemo && (
              <>
                <div className="w-full">
                  <div className="absolute top-[5%]  500px:w-[60%] w-[90%]  bg-[#fff] 500px:left-[18%] left-[20px] right-[40px] rounded-[12px] p-5 font-inter">
                    <RequestDemo />
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PatientContactus;
