import React from "react";
import Logo from "../../images/logo.png";
import SuccessImage from "../../images/hospital/success.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { toast } from "react-toastify";

const TokenExpired = () => {
  const navigate = useNavigate();
  const params = useParams();

  const handleRequestNewLink = async () => {
    const data = {
        patientId : params?.patientId,
    };


    const activateAccount = await axios({
      method: "POST",
      url: `${BASE_URL}/send-token`,
      data: data,
    })
      .then((response) => {
        console.log("Token Resend Success", response);
        toast.success(response?.data);
        //   navigate("/doctor/login");
        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Token Resend Error", error.response.data);
        toast.error(error.response.data);
      });
  };

  return (
    <div className="flex items-center justify-center flex-col dark:bg-[#207A69] h-[100vh]">
      <img src={Logo} className="w-[200px] h-[80px] my-[30px]" alt="" />
      <img
        src={SuccessImage}
        className="W-[180px] h-[180px] my-[30px]"
        alt=""
      />
      <h3 className="text-[30px] my-[10px]">It's been over 72 hours, this link has expired.</h3>
      {/* <p className="text-[22px] text-[#9C9AA5] text-center">
        Welcome aboard! Start your transcribing journey with <br /> Medinclude
      </p> */}
      <button
        className="w-[200px] h-[45px] bg-[#FFCFAA] rounded-[8px] text-[#000000] my-[30px] text-[15px] font-[500]"
        onClick={handleRequestNewLink}
      >
         Request for a new link
      </button>
    </div>
  );
};

export default TokenExpired;
