import React, { useContext, useEffect, useState } from "react";
import Hsidebar from "../../Component/Hospital/Layout/Hsidebar";
import { PiStethoscope } from "react-icons/pi";
import { TbBrandStackshare } from "react-icons/tb";
import { FaArrowUp } from "react-icons/fa6";
import { FaHospitalUser } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import GlobalStorage from "../../Storage/ContextProvider";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Septemper",
  "October",
  "November",
  "December",
];
export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};
export const data = {
  labels,

  datasets: [
    {
      label: "Translations",
      data: [5, 20, 30, 20, 30, 50, 10, 13, 34, 23, 60, 9],
      borderColor: "#FE981E",
      backgroundColor: "#FE981E",
    },
    {
      label: "Transcriptions",
      data: [20, 35, 30, 20, 40, 50, 20, 40, 26, 37, 22, 8],
      borderColor: "#0F123F",
      backgroundColor: "#0F123F",
    },
  ],
};

const Analytics = () => {
  const [isGenerateReport, setIsGenerateReport] = useState(false);
  const { hospitalProfile } = useContext(GlobalStorage);
  const [totalPatients, setTotalPatients] = useState(0);
  const [totalDoctors, setTotalDoctors] = useState(0);
  const [totalDepartments, setTotalDepartments] = useState(0);
  const [hospitalAnalytics, setHospitalAnalytics] = useState();
  const [transcriptionData, setTranscriptionData] = useState();
  useEffect(() => {
    loadPatientNumber();
  }, [hospitalProfile]);
  const loadPatientNumber = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/analytics/${hospitalProfile?._id}`,
    })
      .then((response) => {
        // setPatietnNumber(response?.data);
        console.log("Hospital Analytics", response?.data);
        setTotalPatients(response?.data?.totalPatients);
        setTotalDoctors(response?.data?.totalDoctors);
        setTotalDepartments(response?.data?.totalDepartments);
        setHospitalAnalytics(response?.data);
      })
      .catch((error) => {
        console.log("Get Patient Number  ERROR", error.response);
      });
  };
  useEffect(() => {
    loadTranscriptionData();
  }, [hospitalProfile]);
  const loadTranscriptionData = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/transcription-analytics/${hospitalProfile?._id}`,
    })
      .then((response) => {
        // setPatietnNumber(response?.data);
        setTranscriptionData(response.data);
        console.log(
          "Hospital Analytics",
          response?.data[0].transAndTokensList[0]
        );
      })
      .catch((error) => {
        console.log("Get Patient Number  ERROR", error.response);
      });
  };

  return (
    <Hsidebar activeLink="Analytics">
      <div className={`${isGenerateReport ? "" : ""} relative`}>
        <div
          className={` ${
            isGenerateReport ? "h-[90vh] opacity-40 overflow-hidden" : ""
          } p-[20px]  `}
        >
          <div className="flex justify-between items-center mb-2">
            <h1 className="font-[500] text-[25px]">Analytics</h1>
            <button
              className="rounded-full w-[162px] h-[33px] bg-[#FFCFAA] flex items-center justify-center text-[var(--doctor-color)]"
              onClick={() => setIsGenerateReport(true)}
            >
              Export Analytics
            </button>
          </div>

          <div className="w-[100%] text-[#fff] h-[210px] rounded-[28px] border-[1px] border-[#E6EDFF] my-[15px] bg-[#67A599] dark:bg-[var(--dark-primary)] flex ">
            <div className="w-[25%] border-r-[1px] border-[#E6EDF] my-[25px] px-[55px] flex  justify-center flex-col">
              <div className=" w-full flex items-center justify-between ">
                <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                  {totalDepartments}
                </p>
                <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                  <TbBrandStackshare
                    className="rotate-90 text-[#67A599]"
                    size={20}
                  />
                </div>
              </div>
              <p className="font-outfit font-[400] text-[16px]">
                Total Departments
              </p>
              <div className="flex gap-[10px] items-center font-outfit">
                {hospitalAnalytics?.departmentIncrease < 0 ? (
                  <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
                ) : (
                  <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
                )}

                {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                  -
                </p> */}
                <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                  {Math.round(Math.abs(hospitalAnalytics?.departmentIncrease))}%
                  &nbsp; this Month
                </p>
              </div>

              <button className="mt-[10px] text-[var(--doctor-color)] text-[12px] font-[500]  w-[130px] h-[25px] rounded-full bg-[#FFCFAA] ">
                View Departmernts
              </button>
            </div>
            <div className="w-[25%] border-r-[1px] border-[#E6EDF] my-[25px] px-[55px] flex  justify-center flex-col">
              <div className=" w-full flex items-center justify-between ">
                <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                  {totalDoctors}
                </p>
                <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                  <PiStethoscope className=" text-[#67A599]" size={20} />
                </div>
              </div>
              <p className="font-outfit font-[400] text-[16px]">
                Total Doctors
              </p>
              <div className="flex gap-[10px] items-center font-outfit">
                {hospitalAnalytics?.doctorIncrease < 0 ? (
                  <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
                ) : (
                  <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
                )}
                {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                  -
                </p> */}
                <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                  {Math.round(Math.abs(hospitalAnalytics?.doctorIncrease))}%
                  &nbsp; this Month
                </p>
              </div>
              <button className="mt-[10px] text-[var(--doctor-color)] text-[12px] font-[500]  w-[130px] h-[25px] rounded-full bg-[#FFCFAA] ">
                View Doctors
              </button>
            </div>
            <div className="w-[25%] border-r-[1px] border-[#E6EDF] my-[25px] px-[55px] flex  justify-center flex-col">
              <div className=" w-full flex items-center justify-between ">
                <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                  {totalPatients}
                </p>
                <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                  <FaHospitalUser className=" text-[#67A599]" size={20} />
                </div>
              </div>
              <p className="font-outfit font-[400] text-[16px]">
                Total Patients
              </p>
              <div className="flex gap-[10px] items-center font-outfit">
                {hospitalAnalytics?.patientIncrease < 0 ? (
                  <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
                ) : (
                  <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
                )}
                {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                  -
                </p> */}
                <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                  {Math.round(Math.abs(hospitalAnalytics?.patientIncrease))}%
                  &nbsp; this Month
                </p>
              </div>
              <button className="mt-[10px] text-[var(--doctor-color)] text-[12px] font-[500]  w-[130px] h-[25px] rounded-full bg-[#FFCFAA] ">
                View Patients
              </button>
            </div>
            <div className="w-[25%] my-[25px] px-[55px] flex  justify-center flex-col">
              <div className=" w-full flex items-center justify-between ">
                <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                  {transcriptionData?.totalTranscriptions}
                </p>
                <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                  <PiStethoscope className=" text-[#67A599]" size={20} />
                </div>
              </div>
              <p className="font-outfit font-[400] text-[16px]">
                Total Transcript
              </p>
              <div className="flex gap-[10px] items-center font-outfit">
                {transcriptionData?.transcriptionChange < 0 ? (
                  <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
                ) : (
                  <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
                )}
                {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                  -
                </p> */}
                <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                  {Math.round(Math.abs(transcriptionData?.transcriptionChange))}
                  % &nbsp; this Month
                </p>
              </div>
            </div>
          </div>

          <div className="">
            <div className="p-[20px] bg-[#67A599] dark:bg-[var(--dark-primary)] w-[100%] h-[500px]  my-[20px] border-[1px] border-[#E6EDFF] rounded-[12px]">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-[20px]">
                  <p>Transcript Analytics</p>

                  <select
                    id="countries"
                    className="w-[120px] px-[6px] h-[26px] outline-none bg-[#FFCFAA] rounded-[8px] text-[var(--doctor-color)] text-[12px]"
                  >
                    <option selected>All Doctors</option>
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>

                <div className="flex items-center gap-[15px]  mb-[25px]">
                  <div className="flex items-center gap-[10px]">
                    <div className="bg-[#0F123F] w-[8px] h-[8px] rounded-full"></div>
                    <div className="font-outfit text-[12px]">
                      Transcriptions
                    </div>
                  </div>
                  <div className="flex items-center gap-[10px]">
                    <div className="bg-[#FE981E] w-[8px] h-[8px] rounded-full"></div>
                    <div className="font-outfit text-[12px]">Translations</div>
                  </div>
                  <select
                    id="countries"
                    className="w-[100px] px-[6px] h-[26px] outline-none bg-[#FFCFAA] rounded-[8px] text-[var(--doctor-color)] text-[12px]"
                  >
                    <option selected>Monthly</option>
                    <option value="US">Yearly</option>
                    <option value="CA">Weekly</option>
                    <option value="FR">Daily</option>
                  </select>
                </div>
              </div>
              <Line
                className="w-[100%]"
                width={900}
                height={350}
                options={options}
                data={data}
              />
            </div>
          </div>
        </div>
        {isGenerateReport && (
          <>
            <div className="absolute top-[5%] w-[60%] h-[550px] pt-[25px] left-[20%] ">
              <div className="  bg-[var(--dark-primary)] h-[500px] mt-[30px] text-[#fff] rounded-[23px] border-[1px] border-[#E6EDFF] flex items-center justify-center flex-col">
                <div className="w-[98%] flex justify-end text-[25px] -mt-[55px] ">
                  <RxCross2
                    className="cursor-pointer"
                    onClick={() => setIsGenerateReport(false)}
                  />
                </div>
                <h3 className="text-[20px] font-[500]">Generate Report</h3>
                <p className="text-[16px] font-[300] my-[20px]">
                  Generate a report for your assessments
                </p>

                <select
                  id="countries"
                  className=" my-[10px] w-[70%] h-[45px] outline-none font-inter text-[14px] bg-[#49615C] rounded-full border-[1px]  border-[#fff] text-[#fff] font-[600]  px-[10px]"
                >
                  <option selected>Monthly</option>
                  <option value="FR">Quarterly</option>
                  <option value="US">Yearly</option>
                </select>
                <input
                  type="text"
                  placeholder="Email"
                  className="my-[10px] w-[70%] h-[45px] outline-none font-inter text-[14px] bg-[#49615C] rounded-full border-[1px]  border-[#fff] text-[#fff] font-[600]  px-[10px]"
                />

                {/* <select
                  id="countries"
                  className=" "
                >
                  <option selected>Email</option>
                  <option value="US">Yearly</option>
                  <option value="CA">Weekly</option>
                  <option value="FR">Daily</option>
                </select> */}

                <button className="bg-[#67A599] w-[70%] h-[45px] rounded-full my-[10px] text-[16px]">
                  admin@hospital.com
                </button>

                <button className=" mt-[60px] bg-[#FFCFAA] w-[50%] h-[45px] rounded-full text-[var(--doctor-color)] text-[14px] font-inter font-[700] ">
                  Generate & Export
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Hsidebar>
  );
};

export default Analytics;
