import React, { useEffect, useContext } from "react";
import GlobalStorage from "./ContextProvider";

const ThemeSwitcher = () => {
  const { darkMode, setDarkMode,checked, setChecked } = useContext(GlobalStorage);

  useEffect(() => {
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
    console.log(localStorage.getItem("darkMode"));
  }, []);

  useEffect(() => {
    document.documentElement.classList.toggle("dark", darkMode);
    localStorage.setItem("darkMode", darkMode);
    if (darkMode) {
      document.body.classList.remove("lightmode");
      document.body.classList.add("darkmode");
    } else {
      document.body.classList.remove("darkmode");
      document.body.classList.add("lightmode");

    }
  }, [darkMode, checked]);

  return <div></div>;
};

export default ThemeSwitcher;
