import React, { useContext, useEffect, useState } from "react";
import PatientSidebar from "../../../Component/Patient/Layout/PatientSidebar";
import ProfilePatient from "../../../Component/Patient/Setting/ProfilePatient";
import Preferences from "../../../Component/Patient/Setting/Preferences";
import Security from "../../../Component/Patient/Setting/Security";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import { toast } from "react-toastify";
import GlobalStorage from "../../../Storage/ContextProvider";
import { getCookie } from "../../../auth/helper";

const PatientSetting = () => {
  const {
    patientProfile,
    setPatientProfile,
    refreshPatient,
    setRefreshPatient,
    activeTab,
    setActiveTab,
  } = useContext(GlobalStorage);
  const token = getCookie("token");
  const [countries, setCoutries] = useState();
  const [states, setState] = useState([]);
  const [countryName, setCountryName] = useState("default");
  const [stateName, setStateName] = useState("default");
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [isStateOpen, setIsStateOpen] = useState(false);
  // const [refreshUpdate, setRefreshUpdate] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    dob: "",
    presentAddress: "",
    postalCode: null,
    permanentAddress: "",
    country: "",
    city: "",
  });

  const { name, email, dob, presentAddress, permanentAddress, postalCode } =
    values;

  useEffect(() => {
    setValues({
      ...values,
      name: patientProfile?.name,
      email: patientProfile?.email,
      dob: patientProfile?.dob,
      presentAddress: patientProfile?.presentAddress,
      permanentAddress: patientProfile?.permanentAddress,
      postalCode: patientProfile?.postalCode,
    });
    setSelectedCountry(patientProfile?.country);
    setSelectedState(patientProfile?.state);
  }, [patientProfile, refreshPatient]);

  const handleChange = (name) => (event) => {
    // console.log(event.target.value);
    setValues({ ...values, [name]: event.target.value });
  };
  console.log("Valuess", values);
  const handleUpdateProfile = async () => {
    const data = {
      name,
      email,
      dob,
      presentAddress,
      permanentAddress,
      postalCode,
      country: selectedCountry,
      state: selectedState,
      // subscriptionType,
    };

    const updateProfile = await axios({
      method: "POST",
      url: `${BASE_URL}/patient/update-profile`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        console.log("Hospital register Success", response);
        toast.success(response?.data?.msg);
        setRefreshPatient(!refreshPatient);
        // navigate("/superadmin");
        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Hospital Register  Error", error.response.data);
        toast.error(error.response.data);
      });
  };

  useEffect(() => {
    // Convert date from MongoDB format to the format accepted by input type date
    const formatDate = () => {
      if (dob) {
        const dateObj = new Date(dob);
        const formatted = dateObj.toISOString().split("T")[0];
        setValues({ ...values, dob: formatted });
      }
    };
    formatDate();
  }, [dob]);

  useEffect(() => {
    if (countryName == "") {
      setCountryName("default");
    }
    loadCountry();
  }, [countryName]);

  const loadCountry = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/search/cs?country=${countryName}`,
    })
      .then((response) => {
        console.log("Get Coutries", response);
        // setHospitals(response.data);
        setCoutries(response.data);
      })
      .catch((error) => {
        console.log("Get Hospitals ERROR", error.response);
      });
  };

  useEffect(() => {
    if (stateName == "") {
      setStateName("default");
    }
    loadState();
  }, [stateName, selectedCountry]);

  const loadState = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/search/cs?country=${selectedCountry}&state=${stateName}`,
    })
      .then((response) => {
        console.log("Get States", response);
        // setHospitals(response.data);
        setState(response.data);
      })
      .catch((error) => {
        console.log("Get State ERROR", error.response);
      });
  };
  const handleSelectCountry = (data) => {
    setSelectedCountry(data);
    // setCountryName(data)
    setIsCountryOpen(false);
  };
  const handleSelectState = (data) => {
    setSelectedState(data);
    // setCountryName(data)
    setIsStateOpen(false);
  };
  const handleCountryFocus = () => {
    setIsCountryOpen(true);
    setSelectedCountry();
  };
  const handleStateFocus = () => {
    setIsStateOpen(true);
    setSelectedState();
  };
  const handlePatientSaveClick = () => {};
  return (
    <>
      <PatientSidebar activeLink="Setting">
        <div className="px-5">
          <div className="flex justify-between items-center">
            <h2 className="font-inter font-[500] text-[40px] text-[#052721] dark:text-white">
              Setting
            </h2>
            <button
              className="w-[160px] h-[45px] rounded-full text-white bg-[#DCA071]"
              onClick={handleUpdateProfile}
            >
              Save
            </button>
          </div>

          <div className=" dark:bg-[var(--dark-primary)] bg-white w-full rounded-3xl mt-6 p-6 mb-4">
            <div className="flex border-b border-[#F4F5F7] ">
              <p
                className={`${
                  activeTab === 0
                    ? "border-b-[2px] border-[#DCA071] text-[#DCA071] -mb-[2px]"
                    : ""
                } 500px:px-6 px-3  pb-2 cursor-pointer text-[#CCE1DD] dark:text-white`}
                onClick={() => setActiveTab(0)}
              >
                Edit Profile
              </p>
              <p
                className={`${
                  activeTab === 1
                    ? "border-b-[2px] border-[#DCA071] text-[#DCA071] -mb-[2px]"
                    : ""
                } 500px:px-6 px-3  pb-2 cursor-pointer text-[#CCE1DD] dark:text-white`}
                onClick={() => setActiveTab(1)}
              >
                Preferences
              </p>
              <p
                className={`${
                  activeTab === 2
                    ? "border-b-[2px] border-[#DCA071] text-[#DCA071] -mb-[2px]"
                    : ""
                } 500px:px-6 px-3  pb-2 cursor-pointer text-[#CCE1DD] dark:text-white`}
                onClick={() => setActiveTab(2)}
              >
                Security
              </p>
            </div>
            {activeTab === 0 && (
              <>
                <ProfilePatient
                  name={name}
                  email={email}
                  dob={dob}
                  handleChange={handleChange}
                  postalCode={postalCode}
                  handleCountryFocus={handleCountryFocus}
                  selectedCountry={selectedCountry}
                  setIsCountryOpen={setIsCountryOpen}
                  setCountryName={setCountryName}
                  isCountryOpen={isCountryOpen}
                  countries={countries}
                  handleSelectCountry={handleSelectCountry}
                  handleStateFocus={handleStateFocus}
                  selectedState={selectedState}
                  setStateName={setStateName}
                  isStateOpen={isStateOpen}
                  setIsStateOpen={setIsStateOpen}
                  states={states}
                  handleSelectState={handleSelectState}
                  permanentAddress={permanentAddress}
                  presentAddress={presentAddress}
                  handleUpdateProfile={handleUpdateProfile}
                />
              </>
            )}
            {activeTab === 1 && (
              <>
                <Preferences />
              </>
            )}
            {activeTab === 2 && (
              <>
                <Security />
              </>
            )}
          </div>
        </div>
      </PatientSidebar>
    </>
  );
};

export default PatientSetting;
