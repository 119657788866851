import React from "react";
import SuperSidebar from "../../Component/SuperAdmin/Layout/SuperSidebar";
import { PiArrowLeftFill } from "react-icons/pi";
import PImage from "../../images/patientImage.png";
import { useNavigate } from "react-router-dom";
const ManageSubscriptionSuper = () => {
  const navigate = useNavigate();
  const tableData = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
  ];
  return (
    <SuperSidebar>
      <div className="h-[290px] w-[100%] bg-[#67A599] dark:bg-[var(--dark-primary)] p-[20px] text-[#fff]">
        <div className="flex items-center justify-between">
          <div className="flex gap-[10px] items-center text-[25px] font-[500]">
            <PiArrowLeftFill className="mt-[5px]" />
            <p>Back</p>
          </div>
        </div>
        <div className="flex mt-[40px] items-center">
          <div className="flex gap-[15px] items-center border-r-[1px] border-[#fff] pr-[50px] h-[100px]">
            <img
              src={PImage}
              className="w-[103px] h-[103px] rounded-full border-[5px] border-[#fff]"
              alt=""
            />
            <div>
              <p className="font-[600] text-[29px] leading-[40px]">
                Toronto General Hospital
              </p>

              <p className="text-[17px] text-[#FFCFAA]">janedoe@hospital.ca</p>
            </div>
          </div>
          <div className="px-[40px] border-r-[1px] border-[#fff] h-[100px]">
            <div className="flex gap-[20px] items-center my-[15px] ">
              <button className="w-[151px] h-[29px] bg-[#67A599] rounded-full">
                Speciality
              </button>
              <p className="text-[#FFCFAA] text-[17px]">General</p>
            </div>
            <div className="flex gap-[20px] items-center">
              <button className="w-[151px] h-[29px] bg-[#67A599] rounded-full">
                Date Joined
              </button>
              <p className="text-[#FFCFAA] text-[17px]">13th January, 2024</p>
            </div>
          </div>
          <div className="px-[40px]">
            <p className="font-inter font-[700] text-[70px] leading-[60px]">
              Pro
            </p>
            <p className="font-inter font-[700] text-[18px]">Subscription</p>
          </div>
        </div>
      </div>
      <div className="p-5">
        <table className="w-full text-left rtl:text-right ">
          <thead className="dark:bg-[var(--dark-primary)] bg-[#67A599] text-[#CCE1DD] dark:text-[#CCE1DD] text-[18px]  rounded-full">
            <tr className="">
              <th scope="col" className="px-4 py-3 font-[500]">
                Package
              </th>
              <th scope="col" className="px-4 py-3 font-[500]">
                Monthly Fee
              </th>

              <th scope="col" className="px-4 py-3 font-[500]">
                Yearly Fee
              </th>
              <th scope="col" className="px-4 py-3 font-[500]">
                Features
              </th>
              <th scope="col" className="px-8 py-3 rounded-r-full">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="mt-[60px]">
            {tableData?.map((data, index) => {
              return (
                <>
                  <tr
                    key={index}
                    className="border-b-[1px] border-[rgba(119,119,119,.2)] h-[95px] text-[17px]"
                  >
                    <td className="px-4 text-[#DCA071] dark:text-[#FFCFAA] ">Starter Package</td>
                    <td className="px-4 ">Free</td>
                    <td className="px-4 ">Free</td>
                    <td className="px-4 ">Lorem Ipsum</td>
                    <td className="flex items-center h-full justify-center -px-9 py-4 gap-6  mt-[12px]">
                      <button className="w-[122px] h-[39px] bg-[#FF0000] text-white rounded-full text-[18px] font-[500]">
                        Inactive
                      </button>
                      <div className="flex flex-col gap-3">
                        <button
                          className="w-[122px] h-[39px] bg-[#DCA071] dark:bg-[#FFCFAA] text-[#fff] dark:text-[var(--doctor-color)] rounded-full text-[18px] font-[500]"
                        //   onClick={() =>
                        //     // navigate("/superadmin/hospital/detail")
                        //   }
                        >
                          View
                        </button>
                        <button
                          className="w-[122px] h-[39px] bg-[#00FF66] dark:bg-[#00FF66] text-[#fff] dark:text-[var(--doctor-color)] rounded-full text-[18px] font-[500]"
                        //   onClick={() =>
                        //     // navigate("/superadmin/hospital/detail")
                        //   }
                        >
                          Activate
                        </button>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </SuperSidebar>
  );
};

export default ManageSubscriptionSuper;
