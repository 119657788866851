import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { isAuth } from "./helper";

const HospitalPrivateRoute = ({ element, ...props }) => {
//   console.log("Log", isAuth() && isAuth()?.role === "hospital");
  return isAuth() && isAuth()?.role === "hospital" ? (
    <Outlet />
  ) : (
    <Navigate to="/hospital/login" />
  );
};

export default HospitalPrivateRoute;
