import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginBg from "../../images/hospital/loginBg.png";
import Logo from "../../images/logo.png";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { authenticate } from "../../auth/helper";
import { ToastContainer, toast } from "react-toastify";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const handleSignIn = async () => {
    const data = {
      email,
      password,
    };

    const LOGINData = await axios({
      method: "POST",
      url: `${BASE_URL}/superadmin/login`,
      data: data,
    })
      .then((response) => {
        console.log("Login Success", response);
        // toast.success("Login success");
        authenticate(response, () => {
          setEmail("");
          setPassword("");
        });
        navigate("/superadmin");

        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Login  Error", error.response.data);
        toast.error(error.response.data);
      });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className=" w-[100%] dark:bg-[var(--dark-secondary)] flex p-[25px] justify-center items-center">
      <div className="w-[50%] flex items-center justify-center  ">
        <div
          className="w-[500px] h-[645px] flex flex-col bg-[var(--dark-secondary)] items-center justify-center text-[var(--dark-primary)] rounded-[10px]"
          style={{
            backgroundImage: `url(${LoginBg})`,
            boxShadow: "0px 14px 35px 0px #0000004A",
            backgroundPosition: "center",
          }}
        >
          <img src={Logo} className="w-[250px] h-[100px] mb-[50px]" alt="" />
          <div className="flex items-center justify-center flex-col w-full  text-[#67A599] text-[16px]">
            <input
              type="text"
              className="w-[400px] h-[50px] rounded-full outline-none ml-[34px] my-[15px] px-[20px]"
              placeholder="Admin Username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

<div className="relative w-[400px] h-[50px] rounded-full  ml-[34px] my-[15px] px-[20px] bg-[#fff]">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="w-[300px] h-[50px] outline-none rounded-full"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {
                  showPassword ? (
                    <IoEyeOutline
                    // color="red"
                    className="absolute left-[360px] z-50 top-[18px] cursor-pointer"
                    onClick={handleTogglePassword}
                  />
                  ): (
                    <IoEyeOffOutline
                    // color="red"
                    className="absolute left-[360px] z-50 top-[18px] cursor-pointer"
                    onClick={handleTogglePassword}
                  />
                  )
                }
               
              </div>

            {/* <input
              type="text"
              className="w-[400px] h-[50px] rounded-full outline-none ml-[34px] my-[15px] px-[20px]"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            /> */}
          </div>
          <button
            className="bg-[#FFCFAA] w-[250px] h-[50px] ml-[34px] rounded-full mt-[30px] text-[#000000] font-[500] text-[16px]"
            onClick={handleSignIn}
          >
            Sign In
          </button>

          <div className="text-[#fff] flex gap-[5px] mt-[100px] text-[19px] font-[500] ">
            <p>Forget Password?</p>
            <p className="text-[#FFCFAA]">Reset Password?</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
