import React, { useEffect, useState } from "react";
import PatientSidebar from "../../../Component/Patient/Layout/PatientSidebar";
import { RiArrowLeftSLine } from "react-icons/ri";
import { IoIosMore } from "react-icons/io";
import { FiSend } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import PatientDetail from "../../doctor/PatientDetail";
import { MdEmail } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";

import {
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import Popup from "reactjs-popup";
import moment from "moment";
import { toast } from "react-toastify";

const ReportDetail = () => {
  const params = useParams();

  const [reportDetail, setReportDetail] = useState();
  const [orgnlText, setOrgnlText] = useState("");
  const [notes, setNotes] = useState();
  const [toggle, setToggle] = useState(false);
  const [patientNotes, setPatientNotes] = useState();

  const loadReportDetail = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/report/details/${params?.id}`,
    })
      .then((response) => {
        console.log("Get detail Report", response.data);
        setReportDetail(response.data);
        setOrgnlText(response?.data?.originalReport);
      })
      .catch((error) => {
        console.log("Get Detail Report Error ERROR", error.response);
      });
  };
  const handlePushNotes = () => {
    const data = {
      text: notes,
    };
    axios({
      method: "PUT",
      url: `${BASE_URL}/reports/pushNotes/${reportDetail?._id}`,
      data,
    })
      .then((response) => {
        console.log("Update Report", response);
        setToggle(!toggle);
        setNotes("");
      })
      .catch((error) => {
        console.log("Update Report Error ", error.response);
      });
  };
  useEffect(() => {
    loadReportDetail();
  }, []);
  useEffect(() => {
    loadPatientNotes();
  }, [toggle]);

  const loadPatientNotes = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/report/note/${params?.id}`,
    })
      .then((response) => {
        console.log("Get Pataient Notes", response.data);
        setPatientNotes(response?.data);
      })
      .catch((error) => {
        console.log("Get Patient Notes Error", error.response);
      });
  };

  const handleDelete = (noteId) => {
    axios({
      method: "DELETE",
      url: `${BASE_URL}/notes/${noteId}`,
    })
      .then((response) => {
        console.log("Delete Pataient Notes", response.data);
        toast.success("Note Deleted Successfully");
        setToggle(!toggle);
      })
      .catch((error) => {
        console.log("Delete Patient Notes Error", error.response);
      });
  };

  const fData = (data) => {
    return data?.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  return (
    <>
      <PatientSidebar activeLink="My Reports">
        <div className="px-5">
          <div className="flex items-center justify-between">
            <div className="">
              <Link
                to="/dashboard/myreport"
                className="flex gap-1 items-center text-[#052721] dark:text-white 500px:text-[40px] text-[25px] font-[500] font-inter"
              >
                <RiArrowLeftSLine />
                {/* <h2> Migraine</h2> */}
              </Link>
              <p className="text-[15px] opacity-50 500px:ml-12 ml-10">
                {/* February 12, 2024 */}
                {moment(new Date(reportDetail?.createdAt)).format(
                  "MMMM Do YYYY, h:mm:ss a "
                )}
              </p>
            </div>
            {/* <button className="dark:bg-[#DCA071] px-9 py-3 rounded-full">
              Share Report
            </button> */}
            {/* <EmailShareButton
              // url="www.gmail.com"
              subject="MedInclude Report"
              body={` ${orgnlText}`}
              // className="Demo__some-network__share-button"
            >
              <button className="dark:bg-[#DCA071] px-9 py-3 rounded-full">
                Share Report
              </button>
            </EmailShareButton> */}
            {/* <Popup
              trigger={
                <button className="dark:bg-[#DCA071] px-9 py-3 rounded-full">
                  {" "}
                  Share Report{" "}
                </button>
              }
              modal
            >
              <div className="p-8 text-black">
                <div className="py-1">
                  <EmailShareButton
                    // url="www.gmail.com"
                    subject="MedInclude Report"
                    // body={`${userTranslateInput}`}
                    // className="Demo__some-network__share-button"
                  >
                    <MdEmail size={40} />
                  </EmailShareButton>

                  <WhatsappShareButton
                    url="www.gmail.com"
                    title="MedInclude Report"
                    // body={`${userTranslateInput}`}
                    // className="Demo__some-network__share-button"
                  >
                    <MdEmail size={40} />
                  </WhatsappShareButton>
                </div>
              </div>
            </Popup> */}
          </div>

          <div className="flex mt-4 gap-4 900px:flex-row flex-col">
            <div className="900px:w-[55%] w-full">
              <div className=" py-6 bg-white  dark:bg-[var(--dark-primary)] rounded-md p-6 mb-4   ">
                <div className="flex justify-between items-center">
                  <p className="font-[500] text-[#67A599] dark:text-[#DCA071] text-[22] font-inter">
                    Medical Note
                  </p>
                  <IoIosMore className="opacity-20" size={25} />
                </div>
                <p className="text-sm mt-3 font-inter  break-words">
                  {fData(reportDetail?.originalReport)}
                  {/* {reportDetail?.originalReport} */}
                </p>
              </div>

              <div className=" py-6 bg-white  dark:bg-[var(--dark-primary)] rounded-md p-6 mb-4">
                <div className="flex justify-between items-center">
                  <p className="font-[500] text-[#67A599] dark:text-[#DCA071] text-[22] font-inter">
                    Simplified Note
                  </p>
                  <IoIosMore className="opacity-20" size={25} />
                </div>
                <p className="text-sm mt-3 font-inter text-wrap">
                  {fData(reportDetail?.transcribedText)}

                  {/* {reportDetail?.transcribedText} */}
                </p>
              </div>

              <div className=" py-6 bg-white  dark:bg-[var(--dark-primary)] rounded-md p-6 mb-4">
                <div className="flex justify-between items-center">
                  <p className="font-[500] text-[#67A599] dark:text-[#DCA071] text-[22] font-inter">
                    Translated Note
                  </p>
                  <IoIosMore className="opacity-20" size={25} />
                </div>
                <p className="text-sm mt-3 font-inter">
                  {fData(reportDetail?.nativeLanguageTranscribedText)}

                  {/* {reportDetail?.nativeLanguageTranscribedText} */}
                </p>
              </div>
            </div>

            <div className="900px:w-[45%] h-full w-full  py-6 bg-white dark:bg-[var(--dark-primary)] p-6 rounded-lg">
              <h3 className="text-center text-[#67A599] dark:text-[#DCA071] font-inter text-2xl font-[500]">
                Notes
              </h3>

              <div className="mt-5">
                {patientNotes?.map((data, index) => {
                  return (
                    <>
                      <div className="border-b py-4">
                        <p className="font-inter text-sm">{data?.text}</p>

                        <div className="flex justify-end items-center gap-6 ">
                          <div className="mt-2">
                            <MdDeleteForever
                              size={23}
                              className="cursor-pointer text-[#FF0000]"
                              onClick={() => handleDelete(data?._id)}
                            />
                          </div>
                          <p className="text-end text-[#9FC0BA] font-inter text-[10px] mt-2">
                            {moment(new Date(data?.createdAt)).format(
                              "MMMM Do YYYY, h:mm:ss a "
                            )}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="mt-5 bg-[#CCE1DD] py-3 rounded-2xl">
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="w-[100%] h-[100px] outline-none  bg-transparent rounded-lg px-[15px] text-[16px] text-black opacity-45"
                  placeholder="New note here "
                ></textarea>

                <div className="flex justify-end mr-2">
                  <div
                    className="w-[33px] h-[33px] text-white rounded-full bg-[var(--dark-primary)] flex justify-center items-center cursor-pointer"
                    onClick={handlePushNotes}
                  >
                    <FiSend />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PatientSidebar>
    </>
  );
};

export default ReportDetail;
