import React, { useContext, useEffect, useState } from "react";
import LoginBg from "../../images/hospital/newPas.png";
import Logo from "../../images/logo.png";
import newLight from "../../images/newLogo.png";
import ResetRight from "../../images/patient/reset.png";
import GlobalStorage from "../../Storage/ContextProvider";
import { FaChevronLeft } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { toast } from "react-toastify";
import { FiCheckCircle } from "react-icons/fi";

const HospitalResetPassword = () => {
  const { darkMode } = useContext(GlobalStorage);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglePassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleResetPassword = async (req, res) => {
    if (password !== confirmPassword) {
      return toast.error("Password not matched");
    } else {
      const data = {
        password,
        token: params?.token,
      };

      const activateAccount = await axios({
        method: "POST",
        url: `${BASE_URL}/hospital/reset-password`,
        data: data,
      })
        .then((response) => {
          console.log("Reset Password Success", response);
          toast.success(response?.data?.msg);
          navigate("/hospital/login");
          // setDataUser(response.data.user)
        })
        .catch((error) => {
          console.log("Reset Password  Error", error.response.data);
          toast.error(error.response.data.error);
        });
    }
  };
  useEffect(() => {
    loadToken();
  }, []);

  const loadToken = () => {
    axios({
      method: "POST",
      url: `${BASE_URL}/check-expiry`,
      data: { token: params?.token },
    })
      .then((response) => {
        console.log("Get Token ", response);
      })
      .catch((error) => {
        // if (error.response?.data === "Password Already setUp") {
        //   navigate("/doctor/password-done");
        // }
        if (error.response?.data === "Expired link") {
          navigate(`/hospital/expire-token`);
        }
        console.log("Get Token ERROR", error.response);
      });
  };
  return (
    <>
      <div className="flex w-full min-h-[100vh]">
        <div
          className="w-[35%]  pl-[50px] bg-[#E7F4F2] dark:bg-[#67A599]"
          style={{
            backgroundImage: `url(${LoginBg})`,
            backgroundSize: "cover",
            backgroundBlendMode: "plus darker",
          }}
        >
          {darkMode ? (
            <img src={Logo} className="w-[260px] h-[100px] mt-[30px]" alt="" />
          ) : (
            <img
              src={newLight}
              className="w-[250px] h-[95px] mt-[30px]"
              alt=""
            />
          )}
          <Link to="/doctor/login">
            <div className="my-10 dark:text-white text-[var(--doctor-color)] flex items-center gap-2">
              <FaChevronLeft /> Back to Login
            </div>
          </Link>

          <div className="font-[600] dark:text-[#fff] text-[var(--doctor-color)]  text-[35px]">
            Set a new
            <span className=" font-inter text-[#DCA071] dark:text-[#FFCFAA] ml-3">
              Password {/* {userName?.userName} */}
            </span>
          </div>

          <p className="text-[16px] dark:text-[#fff] text-[var(--doctor-color)]">
            You have requested to reset your password, please set up a new
            password for your account
          </p>

          <p className="text-[25px] dark:text-[#fff] text-[var(--doctor-color)]">
            Create Your Password
          </p>

          <div className="relative placeholder:text-[var(--doctor-color)] dark:placeholder:text-[#67A599)] w-[90%] py-[11px] rounded-full outline-none text-[var(--doctor-color)] dark:text-[#67A599] text-[16px] px-[20px] my-[20px] bg-[#FAD9BF] dark:bg-[#fff]">
            <input
              type={showPassword ? "text" : "password"}
              className="outline-none w-[90%] bg-transparent"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {showPassword ? (
              <IoEyeOutline
                // color="red"
                className="absolute left-[90%] z-50 top-[18px] cursor-pointer"
                onClick={handleTogglePassword}
              />
            ) : (
              <IoEyeOffOutline
                // color="red"
                className="absolute left-[90%] z-50 top-[18px] cursor-pointer"
                onClick={handleTogglePassword}
              />
            )}
          </div>

          <div className="mt-[10px] text-[var(--doctor-color)] dark:opacity-60">
            <div className="text-[13px]  flex items-center gap-[10px] py-[5px]">
              <FiCheckCircle />
              <p>Password must be at least 8 characters long.</p>
            </div>
            <div className="text-[13px]  flex items-center gap-[10px] py-[5px]">
              <FiCheckCircle />
              <p> Password must contain at least one upper case</p>
            </div>
            <div className="text-[13px]  flex items-center gap-[10px] py-[5px]">
              <FiCheckCircle />
              <p> Password must contain at least One lower case letter</p>
            </div>
            <div className="text-[13px]  flex items-center gap-[10px] py-[5px]">
              <FiCheckCircle />
              <p>
                {" "}
                Password must contain at least one number and special character
              </p>
            </div>
          </div>

          <div className="relative placeholder:text-[var(--doctor-color)] dark:placeholder:text-[#67A599)] w-[90%] py-[11px] rounded-full outline-none text-[var(--doctor-color)] dark:text-[#67A599] text-[16px] px-[20px] my-[20px] bg-[#FAD9BF] dark:bg-[#fff]">
            <input
              type={showPassword2 ? "text" : "password"}
              className="outline-none w-[90%] bg-transparent"
              placeholder="New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {showPassword ? (
              <IoEyeOutline
                // color="red"
                className="absolute left-[90%] z-50 top-[18px] cursor-pointer"
                onClick={handleTogglePassword2}
              />
            ) : (
              <IoEyeOffOutline
                // color="red"
                className="absolute left-[90%] z-50 top-[18px] cursor-pointer"
                onClick={handleTogglePassword2}
              />
            )}
          </div>

          {/* <div className="relative placeholder:text-[var(--doctor-color)] dark:placeholder:text-[#67A599)] w-[90%] py-[11px] rounded-full outline-none text-[var(--doctor-color)] dark:text-[#67A599] text-[16px] px-[20px] my-[20px] bg-[#FAD9BF] dark:bg-[#fff]">
            <input
              type="text"
              className="outline-none w-[90%] bg-transparent"
              placeholder="Email Address"
              //   value={email}
              //   onChange={(e) => setEmail(e.target.value)}
            />
          </div> */}

          <button
            className="w-[80%] h-[50px] rounded-full outline-none bg-[var(--doctor-color)] text-[#fff] dark:bg-[#FFCFAA] dark:text-[#000000] font-[500] my-[30px]"
            onClick={handleResetPassword}
          >
            Submit
          </button>
        </div>
        <div className="w-[65%] h-[111vh] bg-[var(--doctor-color)]">
          <div className="h-[90vh] w-full flex justify-center items-center">
            <div className="">
              <img src={ResetRight} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HospitalResetPassword;
