import React from "react";
import HeroBlur from "../../../images/patient/herobg.png";
import SingleCard from "./SingleCard";
import Slider from "react-slick";
const CustomDots = ({ onClick, active }) => (
  <span
    style={{
      width: active ? "18px" : "10px",
      height: "10px",
      backgroundColor: active ? "white" : "rgba(255,255,255,.8)",
      borderRadius: "50%",
      display: "inline-block",
      margin: "0 5px",
      cursor: "pointer",
    }}
    onClick={onClick}
  />
);

const settings = {
  dots: true,
  infinite: true,
  // speed: 500,
  centerPadding: "0px",
  arrows: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 8000,
  swipeToSlide: true,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const TestimonialHome = () => {
  return (
    <>
      <div
        className="h-[100vh] w-full  bg-[#373737] relative overflow-hidden 1024px:py-[14%] 500px:py-[18%] pt-[25%] px-[30px] 1024px-[8%] 1300px:px-[15%]"
        style={{
          backgroundImage: `url(${HeroBlur})`,
          backgroundPosition: "center",
          boxShadow: "0px 14px 35px 0px #0000004A",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full h-full mx-auto ">
          <Slider {...settings}>
            <div>
              <SingleCard />
            </div>
            <div>
              <SingleCard />
            </div>
            <div>
              <SingleCard />
            </div>
            <div>
              <SingleCard />
            </div>

            {/* Add more slides as needed */}
          </Slider>
        </div>

        {/* <div className="flex items-center gap-6">
         
        </div> */}
      </div>
    </>
  );
};

export default TestimonialHome;
