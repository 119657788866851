import React, { useEffect, useState } from "react";
import Hsidebar from "../../Component/Hospital/Layout/Hsidebar";
import { PiArrowLeftFill } from "react-icons/pi";
import PImage from "../../images/patientImage.png";
import { useNavigate, useParams } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import moment from "moment";
import { toast } from "react-toastify";
import { isAuth } from "../../auth/helper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Septemper",
  "October",
  "November",
  "December",
];
export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};
export const data = {
  labels,
  datasets: [
    {
      label: "Translations",
      data: [5, 20, 30, 20, 30, 50, 10, 13, 34, 23, 60, 9],
      borderColor: "#FE981E",
      backgroundColor: "#FE981E",
    },
    {
      label: "Transcriptions",
      data: [20, 35, 30, 20, 40, 50, 20, 40, 26, 37, 22, 8],
      borderColor: "#0F123F",
      backgroundColor: "#0F123F",
    },
  ],
};

export const DepartmentProfile = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [departmentDetails, setDepartmentDetails] = useState();
  const [accountPopup, setAccountPopup] = useState(false);
  const [updateAssigned, setUpdateAssigned] = useState(0);

  useEffect(() => {
    loadDepartmentDetail();
  }, [params?.doctorId]);

  const loadDepartmentDetail = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/department/${params?.departmentId}`,
    })
      .then((response) => {
        console.log("Get Department Detail", response);
        setDepartmentDetails(response?.data);
        // setPatients(response.data);
      })
      .catch((error) => {
        console.log("Get Department Detail  ERROR", error.response);
      });
  };
  useEffect(() => {
    setUpdateAssigned(departmentDetails?.assignedAccount);
  }, [departmentDetails, accountPopup]);

  const handleUpdateAccount = async () => {
    if (!updateAssigned) {
      return toast.error("Add Assign Account");
    }
    const data = {
      assignedAccount: updateAssigned,
      hospitalId: isAuth()?._id,
      departmentId:params?.departmentId
    };

    const updateDepartment = await axios({
      method: "POST",
      url: `${BASE_URL}/hospital/updateDepartment`,
      data: data,
    })
      .then((response) => {
        console.log("Hospital register Success", response);
        toast.success(response?.data?.msg);
        setAccountPopup(false)
        // setRefreshDepartment(!refreshDepartment);
        // setCreateDepartment(false);
        // navigate("/superadmin");
        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Department Register  Error", error.response.data);
        toast.error(error.response.data);
      });
  };
  return (
    <Hsidebar activeLink="Departments">
      <div
        className={`${
          accountPopup
            ? "max-h-[90vh] overflow-hidden opacity-35"
            : "opacity-100"
        } relative`}
      >
        <div className="h-[290px] w-[100%] bg-[#67A599] dark:bg-[var(--dark-primary)] p-[20px] text-[#fff]">
          <div className="flex items-center justify-between">
            <div
              className="flex gap-[10px] items-center text-[25px] font-[500] cursor-pointer"
              onClick={() => navigate("/hospital/departments")}
            >
              <PiArrowLeftFill className="mt-[5px]" />
              <p>Departments</p>
            </div>
            {/* <button className="w-[151px] h-[47px] bg-[#FF0000] rounded-full font-inter font-[700] text-[14px] mt-[15px] ">
            Deactivate
          </button> */}
          </div>
          <div className="flex mt-[40px] items-center">
            <div className="flex gap-[15px] items-center border-r-[1px] border-[#fff] pr-[50px] h-[100px]">
              <div>
                <p className="font-[600] text-[50px] leading-[40px]">
                  {departmentDetails?.departmentName}
                </p>
                <p className="font-[500] text-[17px] text-[#FFCFAA] leading-[30px]">
                  {departmentDetails?.departmentCode}
                </p>
              </div>
            </div>
            <div className="px-[40px] border-r-[1px] border-[#fff] h-[100px]">
              {/* <div className="flex gap-[20px] items-center my-[15px] ">
              <button className="w-[151px] h-[29px] bg-[#FFCFAA] text-[#000] dark:bg-[#67A599] rounded-full">
                HOD
              </button>
              <p className="dark:text-[#FFCFAA] text-[#fff] text-[17px]">Dr. Raj</p>
            </div> */}
              <div className="flex gap-[20px] items-center justify-center h-[100px]">
                <button className="w-[151px] h-[29px] bg-[#FFCFAA] text-[#000] dark:bg-[#67A599] rounded-full">
                  Date Created
                </button>
                <p className="dark:text-[#FFCFAA] text-[#fff] text-[17px]">
                  {moment(new Date(departmentDetails?.createdAt)).format(
                    "MMMM Do YYYY, h:mm:ss a "
                  )}
                </p>
              </div>
            </div>
            <div className="px-[40px] flex gap-[15px] items-end">
              <div>
                <p className="font-inter font-[700] text-[70px] leading-[60px]">
                  {departmentDetails?.assignedAccount}
                </p>
                <p className="font-inter font-[700] text-[18px]">
                  Assigned Account
                </p>
              </div>
              <button
                className="w-[200px] h-[45px] bg-[#FFCFAA] text-[#000] dark:bg-[#67A599] rounded-full text-[20px]"
                onClick={() => setAccountPopup(true)}
              >
                Manage Accounts
              </button>
            </div>
          </div>
        </div>
        <div className="p-[20px]">
          <div className="p-[20px] bg-[#67A599] dark:bg-[var(--dark-primary)] w-[100%] h-[500px]  my-[20px] border-[1px] border-[#E6EDFF] rounded-[12px]">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-[20px]">
                <p>Transcript Analytics</p>

                <select
                  id="countries"
                  className="w-[120px] px-[6px] h-[26px] outline-none bg-[#FFCFAA] rounded-[8px] text-[var(--doctor-color)] text-[12px]"
                >
                  <option selected>All Doctors</option>
                  <option value="US">United States</option>
                  <option value="CA">Canada</option>
                  <option value="FR">France</option>
                  <option value="DE">Germany</option>
                </select>
              </div>

              <div className="flex items-center gap-[15px]  mb-[25px]">
                <div className="flex items-center gap-[10px]">
                  <div className="bg-[#0F123F] w-[8px] h-[8px] rounded-full"></div>
                  <div className="font-outfit text-[12px]">Transcriptions</div>
                </div>
                <div className="flex items-center gap-[10px]">
                  <div className="bg-[#FE981E] w-[8px] h-[8px] rounded-full"></div>
                  <div className="font-outfit text-[12px]">Translations</div>
                </div>
                <select
                  id="countries"
                  className="w-[100px] px-[6px] h-[26px] outline-none bg-[#FFCFAA] rounded-[8px] text-[var(--doctor-color)] text-[12px]"
                >
                  <option selected>Monthly</option>
                  <option value="US">Yearly</option>
                  <option value="CA">Weekly</option>
                  <option value="FR">Daily</option>
                </select>
              </div>
            </div>
            <Line
              className="w-[100%]"
              width={900}
              height={350}
              options={options}
              data={data}
            />
          </div>
        </div>
      </div>

      {accountPopup && (
        <>
          <div className="absolute top-0 flex justify-center items-center custom-width overflow-x-hidden h-[100vh] ">
            <div className="w-[50%] bg-white  p-5 rounded-lg">
              <div className="flex justify-between items-start">
                <button
                  className="text-[#101828] px-[22px] py-[10px] border-[#E4E7EC] border-[1px] rounded-lg text-[17px] font-inter font-[600]"
                  style={{ boxShadow: " 0px 1px 2px 0px #1018280D" }}
                >
                  Assigned Account: {departmentDetails?.assignedAccount}
                </button>
                <RxCross2
                  className="text-[#667085] cursor-pointer text-[24px]"
                  onClick={() => setAccountPopup(false)}
                />
              </div>

              <div className="flex flex-col gap-2 my-4 text-[#475467] text-sm ">
                <label>Assigned Account </label>
                <input
                  type="number"
                  className="outline-none border-[1px] border-[#D0D5DD] px-[14px] py-[10px] w-full rounded-lg"
                  placeholder="Hospital Name"
                  value={updateAssigned}
                  onChange={(e) => setUpdateAssigned(e.target.value)}
                />
              </div>

              <div className="flex items-center gap-5 my-4">
                <button
                  className="w-[50%] text-[#101828]  py-[10px] border-[#E4E7EC] border-[1px] rounded-lg text-[17px] font-inter font-[600]"
                  onClick={() => setAccountPopup(false)}
                >
                  Cancel
                </button>
                <button
                  className="w-[50%] text-[#fff] bg-[var(--doctor-color)]  py-[10px] border-[#E4E7EC] border-[1px] rounded-lg text-[17px] font-inter font-[600]"
                  onClick={handleUpdateAccount}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Hsidebar>
  );
};
