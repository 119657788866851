import React, { useContext, useEffect, useState } from "react";
import Logo from "../../../images/logo.png";
import GlobalStorage from "../../../Storage/ContextProvider";
const tableData1 = [
  {
    department: "OnCology",
    account: 10,
  },
  {
    department: "Intensive Care Unit",
    account: 10,
  },
  {
    department: "Denatal",
    account: 10,
  },
];

const ImportAccount = () => {
  const {
    onBoard,
    setOnBoard,
    excelFileData,
    checkedDepartments,
    setCheckedDepartmeant,
  } = useContext(GlobalStorage);
  const [allDepart, setAllDepart] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [assignedAccount, setAssignedAccount] = useState();
  const [UNassignedAccount, setUnAssignedAccount] = useState();

  // function getCountsInJSON(array) {
  //   // Create an object to store element counts
  //   const counts = {
  //     department: "",
  //     count: "",
  //   };

  //   const array2 = [];

  //   // Count occurrences of each element in the array
  //   array.forEach((element) => {
  //     // counts = {
  //     //   "department":counts[element],
  //     //   "count":(counts[element] || 0) + 1
  //     // }
  //     //  counts.department = counts[element]
  //     // counts.count = (counts[element] || 0) + 1
  //     // counts[element] = (counts[element] || 0) + 1;
  //     // counts[element] = (counts[element] || 0) + 1;
  //     // counts[element] = (counts[element] || 0) + 1;

  //     const elementfOUND = array2.find((obj) => obj.department === element);
  //     if (elementfOUND) {
  //       elementfOUND.count += elementfOUND.count;
  //     } else {
  //       array2.push({ department: element, count: 1 });
  //     }
  //   });
  //   // console.log();

  //   // Convert the counts object to JSON format
  //   // const jsonCounts = JSON.stringify(counts);

  //   return array2;
  // }
  function countElements(array) {
    const counts = {};

    // Count occurrences of each element in the array
    array.forEach((element) => {
      counts[element] = (counts[element] || 0) + 1;
    });

    // Convert counts object to array of arrays
    const result = Object.entries(counts).map(([element, count]) => [
      element,
      count,
    ]);

    return result;
  }

  useEffect(() => {
    const arrayOfObjects = excelFileData.map((arr) => {
      return {
        fullName: arr[1],
        email: arr[2],
        department: arr[3],
        staffId: arr[4],
      };
    });
    console.log("hhh", arrayOfObjects);
  }, []);

  useEffect(() => {
    const singleArray = excelFileData.map((arr) => arr[3]).flat();
    setAssignedAccount(singleArray?.length)
    console.log(singleArray);
    const uniqueArray = singleArray.filter(
      (item, index, self) => self.indexOf(item) === index
    );
    console.log(uniqueArray);
    const elementCounts = countElements(singleArray);
    setAllDepart(elementCounts);
    console.log("logjfjfdjd", elementCounts);
  }, []);

  const handleCheckboxChange = (id) => {
    // Check if the item is already in the array
    const index = checkedDepartments.indexOf(id);
    if (index === -1) {
      // If not, add it to the array
      setCheckedDepartmeant([...checkedDepartments, id]);
    } else {
      // If it's already in the array, remove it
      const updatedItems = [...checkedDepartments];
      updatedItems.splice(index, 1);
      setCheckedDepartmeant(updatedItems);
    }
  };
  console.log("CheckkkedArraty", checkedDepartments);
  return (
    <>
      <img src={Logo} className="w-[250px] h-[100px]" alt="" />
      <p className="text-[20px] text-[#000000] my-[20px]">
        Confirm and assign accounts to your departments
      </p>

      <div className="flex text-[#fff] flex-col items-end justify-end gap-[20px] my-[30px]">
        <div className="w-[430px] text-[#fff]  border-[1px] border-[#5B5B5B] bg-[#363636] rounded-[4px] ">
          <div className="flex">
            <div className="w-[45%] bg-[#404040] border-r-[1px] border-[rgba(255,255,255,.5)]">
              <div className="  px-[10px] text-[12px] font-inter font-[600] h-[35px] border-b-[1px]  border-[rgba(255,255,255,.5)] flex items-center justify-end ">
                TOTAL ACCOUNTS
              </div>
            </div>
            <div className=" w-[55%] text-[12px] font-inter h-[35px] border-b-[1px]  border-[rgba(255,255,255,.5)] flex items-center justify-center">
            {assignedAccount}

            </div>
          </div>

          <div className="flex border-b-[1px]  border-[rgba(255,255,255,.5)]">
            <div className="w-[45%] bg-[#404040] border-r-[1px] border-[rgba(255,255,255,.5)]">
              <div className="  px-[10px] text-[12px] font-inter font-[600] h-[35px]  flex items-center justify-end ">
              ASSIGNED ACCOUNTS
              </div>
            </div>
            <div className=" w-[55%] text-[12px] font-inter h-[35px] flex items-center justify-center">
              {/* {checkedDepartments?.length} */}100
            </div>
          </div>
          <div className="flex">
            <div className="w-[45%] bg-[#404040] border-r-[1px] border-[rgba(255,255,255,.5)]">
              <div className="  px-[10px] text-[12px] font-inter font-[600] h-[35px]  flex items-center justify-end ">
              UNASSIGNED ACCOUNTS
              </div>
            </div>
            <div className=" w-[55%] text-[12px] font-inter h-[35px] flex items-center justify-center">
              100
            </div>
          </div>
        </div>
        <div className="w-[900px]  border-[1px] border-[#5B5B5B] bg-[#363636] rounded-[4px]">
          <div className="flex bg-[#404040]">
            <div className="w-[6%] border-r-[1px] border-[rgba(255,255,255,.5)] ">
              <div className="h-[35px] flex items-center justify-center border-b-[1px] border-[rgba(255,255,255,.5)]">
                <input
                  type="checkbox"
                  name="business"
                  className=" w-[17px] h-[17px] accent-[var(--doctor-color)] border-none outline-none"
                />
              </div>
            </div>
            <div className="w-[47%] border-r-[1px] border-[rgba(255,255,255,.5)]">
              <div className="h-[35px] font-inter font-[600] text-[12px] flex items-center justify-center border-b-[1px] border-[rgba(255,255,255,.5)]">
                Department
              </div>
            </div>
            <div className="w-[47%]">
              <div className="h-[35px] font-inter font-[600] text-[12px] flex items-center justify-center border-b-[1px] border-[rgba(255,255,255,.5)]">
                Assigned Account
              </div>
            </div>
          </div>
          {allDepart?.map((data, index) => {
            return (
              <>
                <div className="flex">
                  <div className="w-[6%] border-r-[1px] bg-[#404040] border-[rgba(255,255,255,.5)] ">
                    <div className="h-[35px] flex items-center justify-center border-b-[1px] border-[rgba(255,255,255,.5)] ">
                      <input
                        type="checkbox"
                        name="business"
                        className=" w-[17px] h-[17px] accent-[var(--doctor-color)] border-none outline-none"
                        checked={checkedDepartments.includes(data[0])}
                        onChange={() => handleCheckboxChange(data[0])}
                      />
                    </div>
                  </div>
                  <div className="w-[47%] border-r-[1px] border-[rgba(255,255,255,.5)]">
                    <div className="h-[35px] font-inter  text-[12px] flex items-center px-[15px] border-b-[1px] border-[rgba(255,255,255,.5)] ">
                      {data[0]}
                    </div>
                  </div>
                  <div className="w-[47%]">
                    <div className="h-[35px] font-inter font-[400] text-[12px] flex items-center justify-center border-b-[1px] border-[rgba(255,255,255,.5)] ">
                      {data[1]}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>

      <button
        className="bg-[#FFCFAA] w-[200px] h-[45px] rounded-[8px] text-[#000000] text-[15px] font-[500] my-[20px]"
        onClick={() => setOnBoard(3)}
      >
        Continue
      </button>

      <div className="flex gap-[20px] mt-[20px]">
        <div className="w-[67px] h-[13px] rounded-full bg-[var(--doctor-color)]"></div>
        <div className="w-[13px] h-[13px] opacity-[.5] rounded-full bg-[var(--doctor-color)]"></div>
      </div>
    </>
  );
};

export default ImportAccount;
