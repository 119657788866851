import React, { useContext, useEffect, useState } from "react";
import Logo from "../../../images/logo.png";
import { FaChevronLeft } from "react-icons/fa";
import GlobalStorage from "../../../Storage/ContextProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import { toast } from "react-toastify";
import { isAuth } from "../../../auth/helper";

const FinalOnBOARD = () => {
  const {
    onBoard,
    setOnBoard,
    excelFileData,
    setExcelData,
    checkedDepartments,
    setCheckedDepartmeant,
  } = useContext(GlobalStorage);
  const navigate = useNavigate();
  const [allDepartment, setAllDepartment] = useState();
  const [staffData, setStaffData] = useState();

  const [checkedItems, setCheckedItems] = useState([]);
  const [checkeddata, setCheckedData] = useState();

  const [isChecked, setIsChecked] = useState(false);
  const tableData1 = [
    {
      name: "Dr. Jane Doe",
      email: "janedoe@hospital.com",
      department: "Oncology",
      staff: 2451435170001,
    },
    {
      name: "Dr. Jane Doe",
      email: "janedoe@hospital.com",
      department: "Oncology",
      staff: 2451435170001,
    },
    {
      name: "Dr. Jane Doe",
      email: "janedoe@hospital.com",
      department: "Oncology",
      staff: 2451435170001,
    },
    {
      name: "Dr. Jane Doe",
      email: "janedoe@hospital.com",
      department: "Oncology",
      staff: 2451435170001,
    },
    {
      name: "Dr. Jane Doe",
      email: "janedoe@hospital.com",
      department: "Oncology",
      staff: 2451435170001,
    },
  ];

  const tableData2 = [
    {
      name: "Dr. Jane Doe",
      email: "janedoe@hospital.com",
      department: "Dentist",
      staff: 2451435170001,
    },
    {
      name: "Dr. Jane Doe",
      email: "janedoe@hospital.com",
      department: "Dentist",
      staff: 2451435170001,
    },
    {
      name: "Dr. Jane Doe",
      email: "janedoe@hospital.com",
      department: "Dentist",
      staff: 2451435170001,
    },
    {
      name: "Dr. Jane Doe",
      email: "janedoe@hospital.com",
      department: "Dentist",
      staff: 2451435170001,
    },
    {
      name: "Dr. Jane Doe",
      email: "janedoe@hospital.com",
      department: "Dentist",
      staff: 2451435170001,
    },
  ];

  function getCounts(array) {
    // Create an object to store term counts
    const counts = [];

    // Count occurrences of each element in the array
    array.forEach((element) => {
      counts[element] = (counts[element] || 0) + 1;
    });

    return counts;
  }

  useEffect(() => {
    const arrayOfObjects = excelFileData.map((arr) => {
      return {
        fullName: arr[1],
        email: arr[2],
        department: arr[3],
        staffId: arr[4],
        hospitalId:isAuth()?._id
      };
    });
    setStaffData(arrayOfObjects);
  }, []);

  const handleCheckboxChange = (id) => {
    // Check if the item is already in the array
    const index = checkedItems.indexOf(id);
    if (index === -1) {
      // If not, add it to the array
      setCheckedItems([...checkedItems, id]);
    } else {
      // If it's already in the array, remove it
      const updatedItems = [...checkedItems];
      updatedItems.splice(index, 1);
      setCheckedItems(updatedItems);
    }
  };
  console.log("theData", checkedItems);

  const handleSendDoctorData = async () => {
    const newData = [...checkedItems];
    const myObject = { 
      hospitalId:isAuth()?._id
     };
  const newArr=  newData.push(myObject)

    const data = {
       theData:checkedItems
    };

    const LOGINData = await axios({
      method: "POST",
      url: `${BASE_URL}/hospital/registerAllStaff`,
      data: data,
    })
      .then((response) => {
        console.log("Hospital Staff register Success", response);
        toast.success("Email is sent to the given emails");
       navigate("/hospital")
        // setCreateHospital(false);
        // navigate("/superadmin");

        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Hospital Register  Error", error.response.data);
        toast.error("Failed! Please try again");
      });
  };
  return (
    <>
      <div className="flex items-center justify-center">
        <img src={Logo} className="w-[150px] h-[60px]" alt="" />
      </div>
      <p className="text-[20px] font-[300] text-center">
        Select staffs to assign accounts to
      </p>
      <div className="absolute top-[70px] left-[30px]">
        <div
          className="flex items-center font-[400] text-[18px] cursor-pointer gap-[1px] text-[#fff]"
          onClick={() => setOnBoard(2)}
        >
          <FaChevronLeft />
          Back
        </div>
      </div>

      <div className=" flex items-center justify-between my-[20px]">
        <div className="flex items-center gap-[20px]">
          <button className="w-[220px] h-[45px] rounded-full bg-[#FFCFAA] text-[14px] text-[var(--doctor-color)] font-inter font-[700]">
            TOTAL ACCOUNT:100
          </button>
          <button className="w-[220px] h-[45px] rounded-full bg-[#67A599] text-[14px] text-[#fff] font-inter font-[700]">
            TOTAL SELECTED: 12
          </button>
        </div>

        <div className="flex items-center gap-[20px]">
          <button
            className="w-[220px] h-[45px] rounded-full bg-[#00FF66] text-[14px] text-[#000000] font-inter font-[700]"
            onClick={handleSendDoctorData}
          >
            Import Selected
          </button>
          <button
            className="w-[130px] h-[45px] rounded-full bg-[#FF0000] text-[14px] text-[#fff] font-inter font-[700]"
            onClick={() => setOnBoard(2)}
          >
            Cancel
          </button>
        </div>
      </div>

      {checkedDepartments?.map((data, index) => {
        return (
          <>
            <div className="my-[30px]">
              <h2 className="text-[25px] font-[600] my-[20px]">
                {data} Department
              </h2>

              <div className="text-[#fff] border-[1px] border-[#5B5B5B] bg-[#363636] rounded-[4px]">
                <div className="h-[36px] border-b-[1px] border-[#5B5B5B] bg-[#404040] flex">
                  <div className="w-[4%]">
                    <div className="h-[35px] flex items-center justify-center border-r-[1px] border-[#5B5B5B]">
                      <input
                        type="checkbox"
                        name="business"
                        className=" w-[17px] h-[17px] accent-[var(--doctor-color)] border-none outline-none"
                      />
                    </div>
                  </div>
                  <div className="w-[24%]">
                    <div className="h-[35px] flex items-center justify-center border-r-[1px] border-[#5B5B5B] text-[11px] font-[600] font-inter">
                      Full Name
                    </div>
                  </div>
                  <div className="w-[24%]">
                    <div className="h-[35px] flex items-center justify-center border-r-[1px] border-[#5B5B5B] text-[11px] font-[600] font-inter">
                      Email Address
                    </div>
                  </div>
                  <div className="w-[24%]">
                    <div className="h-[35px] flex items-center justify-center border-r-[1px] border-[#5B5B5B] text-[11px] font-[600] font-inter">
                      Department
                    </div>
                  </div>
                  <div className="w-[24%]">
                    <div className="h-[35px] flex items-center justify-center border-r-[1px] border-[#5B5B5B] text-[11px] font-[600] font-inter">
                      Staff ID No.
                    </div>
                  </div>
                </div>
                {staffData?.map((d, index) => {
                  return (
                    <>
                      {d?.department == data && (
                        <div
                          key={index}
                          className="h-[36px] border-b-[1px] border-[#5B5B5B] flex"
                        >
                          <div className="w-[4%] bg-[#404040]">
                            <div className="h-[35px] flex items-center justify-center border-r-[1px] border-[#5B5B5B]">
                              <input
                                type="checkbox"
                                name={d}
                                className=" w-[17px] h-[17px] accent-[var(--doctor-color)] border-none outline-none"
                                checked={checkedItems.includes(d)}
                                onChange={() => handleCheckboxChange(d)}
                              />
                            </div>
                          </div>
                          <div className="w-[24%]">
                            <div className="h-[35px] flex items-center px-[10px] border-r-[1px] border-[#5B5B5B] text-[12px]  font-inter">
                              {d?.fullName}
                            </div>
                          </div>
                          <div className="w-[24%]">
                            <div className="h-[35px] flex items-center px-[10px] border-r-[1px] border-[#5B5B5B] text-[12px] font-inter">
                              {d?.email}
                            </div>
                          </div>
                          <div className="w-[24%]">
                            <div className="h-[35px] flex items-center px-[10px] border-r-[1px] border-[#5B5B5B] text-[12px]  font-inter">
                              {d?.department}
                            </div>
                          </div>
                          <div className="w-[24%]">
                            <div className="h-[35px] flex items-center px-[10px] border-r-[1px] border-[#5B5B5B] text-[12px]  font-inter">
                              {d?.staffId}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default FinalOnBOARD;
