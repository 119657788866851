import React from "react";
import SuperSidebar from "../../../Component/SuperAdmin/Layout/SuperSidebar";
import PatientImage from "../../../images/patientImage.png";

import { PiArrowLeftFill } from "react-icons/pi";
import { FaPlus } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { FaFilter } from "react-icons/fa6";

const tableData = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

const SuperSubcriptions = () => {
  return (
    <SuperSidebar activeLink="Subscriptions">
      <div className="p-5">
        <h1 className="font-[500] text-2xl">Subscriptions</h1>

        <div className="flex w-[100%] gap-[25px] my-[15px]">
          <div className="flex items-center h-[45px] w-[85%]  bg-[#67A599] rounded-full relative text-[#fff]  ">
            <CiSearch className="absolute left-[10px]" />
            <input
              className="bg-transparent w-full outline-none ml-[35px] mr-[10px] placeholder-white"
              type="text"
              placeholder="Search Hospitals"
            />
          </div>
          <div className="w-[15%]">
            <button className=" rounded-full flex items-center justify-center text-[#fff] bg-[var(--dark-secondary)] h-[46px] w-full">
              <FaFilter /> Sort By
            </button>
          </div>
        </div>

        {/* <div className="relative overflow-x-auto rounded-full"> */}

        <table className="w-full text-left rtl:text-right ">
          <thead className="dark:bg-[var(--dark-primary)] bg-[#67A599] text-[#CCE1DD] dark:text-[#CCE1DD] text-[18px]  rounded-full">
            <tr className="">
              <th scope="col" className="p-4 rounded-l-full">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="tableInput"
                  />
                </div>
              </th>
              <th scope="col" className="w-[350px] font-[500] ">
                Hospital Name
              </th>
              <th scope="col" className="px-4 py-3 font-[500]">
                Hospital Code
              </th>
              <th scope="col" className="px-4 py-3 font-[500]">
                Subscription Type
              </th>
              <th scope="col" className="px-4 py-3 font-[500]">
                Status
              </th>
              <th scope="col" className="px-4 py-3 rounded-r-full font-[500]">
              
              </th>
            </tr>
          </thead>
          <tbody className="mt-[60px]">
            {tableData?.map((data, index) => {
              return (
                <>
                  <tr
                    key={index}
                    className="border-b-[1px] border-[rgba(119,119,119,.2)] h-[95px] text-[17px]"
                  >
                    <td className="w-4 p-4">
                      <div className="flex items-center">
                        <input
                          id="checkbox-all-search"
                          type="checkbox"
                          className="tableInput"
                        />
                      </div>
                    </td>
                    <td className="w-[350px]">
                      <div className="flex items-center gap-[15px]">
                        <img
                          src={PatientImage}
                          className="w-[65px] h-[65px] rounded-full"
                          alt=""
                        />
                        <div className="">
                          <p className="text-[17px]">Dr. Jane</p>
                          <span className="text-[14px] text-[#DCA071] dark:text-[#FFCFAA]">
                            Last Login: 1 hour ago
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 ">MI001243248</td>

                    <td className="px-4 ">Pro</td>
                    <td className="px-4 ">Active</td>
                    <td className="flex items-center h-full justify-center px-6 py-4 gap-[20px] mt-[12px]">
                     
                      <button
                        className="w-[122px] h-[39px] bg-[#FF0000] dark:bg-[#FF0000] text-[#fff]  rounded-full text-[14px] font-[400]"
                        // onClick={() => navigate("/superadmin/hospital/detail")}
                      >
                        Deactivate
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </SuperSidebar>
  );
};

export default SuperSubcriptions;
