import React, { useContext, useEffect, useState } from "react";
import Hsidebar from "../../Component/Hospital/Layout/Hsidebar";
import { FaPlus } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { FaFilter } from "react-icons/fa6";
import PatientImage from "../../images/patientImage.png";
import { useNavigate } from "react-router-dom";
import GlobalStorage from "../../Storage/ContextProvider";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { toast } from "react-toastify";
import { getCookie, isAuth } from "../../auth/helper";
const Doctors = () => {
  // const [createDoctor, setCreateDoctor] = useState(false);

  const {
    createDoctor,
    setCreateDoctor,
    hospitalProfile,
    hospitalDepartment,
    refreshDepartment,
    setRefreshDepartment,
    hospitalDoctors,
    setHospitalDoctors,
  } = useContext(GlobalStorage);
  const [allDepartment, setAllDepartment] = useState();
  const [titleValue, setTitleValue] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [departmentValue, setDepartmentValue] = useState("");
  const [staffId, setStaffId] = useState("");
  // const [doctors, setDoctors] = useState();
  // const [refreshDoctor, setRefreshDoctor] = useState(false);
  const [loadedDoctors, setLoadedDoctors] = useState(false);
  const [updatedDoctors, setUpdatedDoctors] = useState();
  const [isResendLink, setIsResetLink] = useState(false);
  const [resendData, setResendData] = useState();
  const [resendEmail, setResendEmail] = useState();

  // const [statusText, setStatusText] = useState([]);

  const token = getCookie("token");

  const tableData = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
  ];
  const navigate = useNavigate();
  const handleCreateStaff = async () => {
    const data = {
      fullName: `${titleValue} ${firstName} ${lastName}`,
      email,
      department: departmentValue,
      staffId: staffId,
      hospitalId: isAuth()?._id,
    };
    const staffData = await axios({
      method: "POST",
      url: `${BASE_URL}/hospital/registerSingleStaff`,
      data: data,
    })
      .then((response) => {
        console.log("Hospital Staff register Success", response);
        toast.success("Email is sent to the given email");
        setRefreshDepartment(!refreshDepartment);
        setCreateDoctor(false);
      })
      .catch((error) => {
        console.log("Staff  Register  Error", error.response.data);
        toast.error(error.response.data);
      });
  };

  useEffect(() => {
    const updatedObjects = hospitalDoctors?.map((object) => {
      const createdAtMilliseconds = new Date(object?.updatedAt).getTime();
      const fiveMinutesLater = new Date(
        // createdAtMilliseconds + 3 * 60000 * 60 * 24
        createdAtMilliseconds + 3 * 60000 * 60 * 24
      );
      const currentTime = new Date();
      if (object?.status === "Pending" && currentTime >= fiveMinutesLater) {
        return {
          ...object,
          status: "Expired",
        };
      }
      return object;
    });
    setUpdatedDoctors(updatedObjects);
  }, [hospitalDoctors]);

  const handleCreatDoctorPopup = () => {
    if (hospitalDepartment.length <= 0) {
      return toast.error("Add Department First");
    }
    setCreateDoctor(true);
  };

  const handleResend = (data) => {
    setIsResetLink(true);
    setResendData(data);
    setResendEmail(data?.email);
  };

  // console.log("Resend Data", resendData);
  const handleResendLink = async () => {

    if(!resendEmail) {
      return toast.error("Please Enter the email")
    }
    const data = {
      hospitalId: resendData?.hospitalId,
      doctorId:resendData?._id,
      email:resendEmail
    };

    const activateAccount = await axios({
      method: "POST",
      url: `${BASE_URL}/doctor/resend-link`,
      data: data,
    })
      .then((response) => {
        console.log("Token Resend Success", response);
        toast.success(response?.data.msg);
        setIsResetLink(false);
        setRefreshDepartment(!refreshDepartment);

        //   navigate("/doctor/login");
        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Token Resend Error", error.response.data);
        toast.error(error.response.data);
      });
  };
  return (
    <Hsidebar activeLink="Doctors">
      <div
        className={` ${
          createDoctor || isResendLink
            ? "bg-[rgba(0,0,0,.3)] dark:opacity-[.4] opacity-70 overflow-y-hidden h-[88vh]"
            : ""
        } p-[20px]`}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-[25px] font-[500]">Doctors</h1>
          <button
            className="rounded-full w-[162px] h-[33px] bg-[#DCA071] dark:bg-[#FFCFAA] flex items-center justify-center text-[#fff] dark:text-[var(--doctor-color)]"
            onClick={handleCreatDoctorPopup}
          >
            <FaPlus /> New Doctor
          </button>
        </div>
        <div className="flex w-[100%] gap-[25px] my-[15px]">
          <div className="flex items-center h-[45px] w-[85%]  bg-[#67A599] rounded-full relative text-[#fff]  ">
            <CiSearch className="absolute left-[10px]" />
            <input
              className="bg-transparent w-full outline-none ml-[35px] mr-[10px] placeholder-white"
              type="text"
              placeholder="Search Doctors"
            />
          </div>
          <div className="w-[15%]">
            <button className=" rounded-full flex items-center justify-center text-[#fff] bg-[var(--dark-secondary)] h-[46px] w-full">
              <FaFilter /> Filter
            </button>
          </div>
        </div>

        {/* <div className="relative overflow-x-auto rounded-full"> */}

        <table className="w-full text-left rtl:text-right ">
          <thead className="dark:bg-[var(--dark-primary)] bg-[#67A599] text-[#CCE1DD] dark:text-[#CCE1DD] text-[18px]  rounded-full">
            <tr className="font-[500]">
              <th scope="col" className="p-4 rounded-l-full">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="tableInput"
                  />
                </div>
              </th>
              <th scope="col" className="w-[350px] font-[500] ">
                Name
              </th>
              <th scope="col" className="px-4 py-3 font-[500]">
                Department
              </th>

              <th scope="col" className="px-4 py-3 font-[500]">
                Email
              </th>
              <th scope="col" className="px-4 py-3 font-[500]">
                Staff ID No.
              </th>
              <th scope="col" className="px-4 py-3 rounded-r-full font-[500]">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="mt-[60px]">
            {updatedDoctors?.map((data, index) => {
              return (
                <>
                  <tr
                    key={index}
                    className="border-b-[1px] border-[rgba(119,119,119,.2)] h-[95px] text-[17px]"
                  >
                    <td className="w-4 p-4">
                      <div className="flex items-center">
                        <input
                          id="checkbox-all-search"
                          type="checkbox"
                          className="tableInput"
                        />
                      </div>
                    </td>
                    <td className="w-[350px]">
                      <div className="flex items-center gap-[15px]">
                        <img
                          src={PatientImage}
                          className="w-[65px] h-[65px] rounded-full"
                          alt=""
                        />
                        <div className="">
                          <p className="text-[17px]">{data?.fullName}</p>
                          <span className="text-[14px] text-[#DCA071] dark:text-[#FFCFAA]">
                            Last Login: 1 hour ago
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 ">
                      {data?.department?.departmentName}
                    </td>

                    <td className="px-4 ">{data?.email}</td>
                    <td className="px-4 ">{data?.staffId}</td>
                    <td className="flex items-center h-full justify-center px-6 py-4 gap-[20px] mt-[12px]">
                      {/* {data?.status === "Pending" && (
                        <>
                          {statusText?.map((s, i) => {
                            if (s?.id == data?._id) {
                              // console.log("IDDD", s?.id, data?._id);
                              return (
                                <button
                                  className="bg-yellow-400 text-[#FFFFF] w-[122px] h-[39px] text-[18px] rounded-full"
                                  // onClick={() => handleActivity(data)}
                                >
                                  {s?.text}
                                </button>
                              );
                            }
                          })}
                        </>
                      )} */}
                      {data?.status == "Expired" && (
                        <button
                          className="bg-yellow-400 text-[#FFFFF] w-[122px] h-[39px] text-[18px] rounded-full"
                          onClick={() => handleResend(data)}
                        >
                          {data?.status}
                        </button>
                      )}
                      {data?.status == "Pending" && (
                        <button
                          className="bg-yellow-400 text-[#FFFFF] w-[122px] h-[39px] text-[18px] rounded-full"
                          onClick={() => handleResend(data)}
                        >
                          {data?.status}
                        </button>
                      )}
                      {data?.status == "Active" && (
                        <button className="w-[122px] h-[39px] bg-[#1CB8EA] rounded-full text-[18px] font-[500]">
                          Active
                        </button>
                      )}
                      {data?.status == "InActive" && (
                        <button
                          className="bg-[#FF0000] dark:text-[#FFFFF] w-[122px] h-[39px] text-[18px] rounded-full"
                          // onClick={() => handleViewPatient(data)}
                        >
                          Inactive
                        </button>
                      )}

                      <button
                        className="w-[122px] h-[39px] bg-[#DCA071] dark:bg-[#FFCFAA] text-[#fff] dark:text-[var(--doctor-color)] rounded-full text-[18px] font-[500]"
                        onClick={() =>
                          navigate(`/hospital/doctor/${data?._id}`)
                        }
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* </div> */}

      {createDoctor && (
        <>
          <div className="absolute top-[150px]  left-[36%] w-[45%]  ">
            <button className="w-[100%] bg-[#67A599] dark:bg-[var(--doctor-color)] text-[#fff] h-[50px] rounded-[20px] font-[500] text-[20px]">
              Create New Profile
            </button>
            <div className="mt-[15px] bg-[#CCE1DD] rounded-[20px]  px-[20px] py-[35px] ">
              <div className="flex items-center gap-[15px]">
                <select
                  id="countries"
                  className="w-[90px] px-[6px] h-[45px] outline-none bg-[#67A599] rounded-[8px] text-[#fff] text-[16px]"
                  value={titleValue}
                  onChange={(e) => setTitleValue(e.target.value)}
                >
                  <option selected value="">
                    Title
                  </option>
                  <option value="Dr.">Dr.</option>
                </select>
                <input
                  type="text"
                  className="w-[250px] h-[45px] text-[16px] rounded-[11px] outline-none bg-transparent border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  type="text"
                  className="w-[250px] h-[45px] text-[16px] rounded-[11px] outline-none bg-transparent border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                  placeholder="Last  Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <input
                type="text"
                className="w-full h-[45px] my-[20px] text-[16px] rounded-[11px] outline-none bg-transparent border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="flex items-center gap-5">
                <select
                  id="countries"
                  className="w-[50%]  h-[45px] outline-none bg-transparent rounded-[11px] border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                  value={departmentValue}
                  onChange={(e) => setDepartmentValue(e.target.value)}
                >
                  <option selected value="">
                    Department
                  </option>
                  {hospitalDepartment?.map((data, index) => {
                    return (
                      <>
                        <option value={data?._id}>
                          {data?.departmentName}
                        </option>
                      </>
                    );
                  })}
                </select>

                <input
                  type="text"
                  className="w-[50%] h-[45px] my-[20px] text-[16px] rounded-[11px] outline-none bg-transparent border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                  placeholder="Staff Id"
                  value={staffId}
                  onChange={(e) => setStaffId(e.target.value)}
                />
              </div>

              <div className=" mt-[30px] flex items-center gap-[20px] font-[600] font-inter text-[21px] text-[#fff]">
                <button
                  className="w-[70%] h-[50px] rounded-[37px] bg-[#DDBEA6] "
                  onClick={handleCreateStaff}
                >
                  Create Profile
                </button>
                <button
                  className="w-[30%] h-[50px] rounded-[37px] bg-[#FF0000]"
                  onClick={() => setCreateDoctor(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {isResendLink && (
        <>
          <div className="absolute top-[150px]  left-[36%] w-[45%]  ">
            <button className="w-[100%] bg-[#67A599] dark:bg-[var(--doctor-color)] text-[#fff] h-[50px] rounded-[20px] font-[500] text-[20px]">
              Resend Link
            </button>

            <div className="mt-[15px] bg-[#CCE1DD] rounded-[20px]  px-[20px] py-[35px] ">
              <input
                type="text"
                className="w-full h-[45px] my-[20px] text-[16px] rounded-[11px] outline-none bg-transparent border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                placeholder="Email Address"
                value={resendEmail}
                onChange={(e) => setResendEmail(e.target.value)}
              />
              <div className=" mt-[30px] flex items-center gap-[20px] font-[600] font-inter text-[21px] text-[#fff]">
                <button
                  className="w-[70%] h-[50px] rounded-[37px] bg-[#DDBEA6] "
                  onClick={handleResendLink}
                >
                  Resend Link
                </button>
                <button
                  className="w-[30%] h-[50px] rounded-[37px] bg-[#FF0000]"
                  onClick={() => setIsResetLink(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Hsidebar>
  );
};

export default Doctors;
