import React from "react";
import DoctorSidebar from "../../../Component/Doctor/Layout/DoctorSidebar";

const Notifications = () => {
  return (
    <DoctorSidebar activeLink="Notifications">
      <div className="p-[20px] text-[#052721] dark:text-[#fff] ">
        <div className=" border-b border-[var(--doctor-color)] dark:text-[#fff] pb-[15px]">
          <div>
            <h2 className="font-[500] text-[25px]">Notificatons</h2>
            <p className="font-[300] text-[16px] text-[var(--doctor-color)] dark:text-[#fff]">
              Indicate how you’ll like to be notified
            </p>
          </div>
        </div>
        <div className="px-[15px] py-[15px]">
          <h2 className=" text-[20px] font-[500]">Internal</h2>
          <p className="font-[300] py-[15px] text-[16px]  w-[40%]">
            Set how you’ll like to receive notification from the users of the
            plateform withing the organization
          </p>
        </div>
        <div>
          <div className="flex items-center py-[15px]">
            <input type="checkbox" name="business" className="notyInput" />
            <label className="text-[20px]  ml-[20px]">Help and Assistance</label>
          </div>
          <div className="flex items-center py-[15px]">
            <input type="checkbox" name="economy"  className="notyInput" />
            <label className="text-[20px]  ml-[20px]">Transcription  Report Alert</label>
          </div>
        </div>

        <div className="px-[15px] py-[25px]">
          <h2 className=" text-[20px] font-[500]">External</h2>
          <p className="font-[300] py-[15px] text-[16px]  w-[40%]">
            Set how you’ll like to receive notification from Medinclude
          </p>
        </div>
        <div>
          <div className="flex items-center py-[15px]">
            <input type="checkbox" name="business"  className="notyInput" />
            <label className="text-[20px] ml-[20px]">Newsletter</label>
          </div>
          <div className="flex items-center py-[15px]">
            <input type="checkbox" name="economy"  className="notyInput" />
            <label className="text-[20px] ml-[20px]">Updates</label>
          </div>
          <div className="flex items-center py-[15px]">
            <input type="checkbox" name="economy"  className="notyInput" />
            <label className="text-[20px] ml-[20px]">Tips and Tutorials</label>
          </div>
          {/* <div className="flex items-center py-[15px]">
            <input type="checkbox" name="economy"  className="notyInput" />
            <label className="text-[20px]  ml-[20px]">Offers and Promotions</label>
          </div> */}
        </div>
      </div>
    </DoctorSidebar>
  );
};

export default Notifications;
