import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RiMenu3Fill } from "react-icons/ri";
import { TiPlus } from "react-icons/ti";
import { MdDashboard } from "react-icons/md";
import { FaHospitalUser } from "react-icons/fa";
import { IoSettings } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { FaCaretSquareLeft } from "react-icons/fa";
import { FaCaretSquareRight } from "react-icons/fa";

import { useState } from "react";
import Logo from "../../../images/logo.png";
import ShortLogo from "../../../images/shortLogo.png";
import ShortLightLogo from "../../../images/shoetLightLogo.png";
import LightLogo from "../../../images/newLogo.png";
import Topbar from "./Topbar";
import { useContext } from "react";
import GlobalStorage from "../../../Storage/ContextProvider";
import cookie from "js-cookie";
import NewTransccript from "../Transcript/NewTransccript";
import { MdContactMail } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { GrResources } from "react-icons/gr";
import { VscSettings } from "react-icons/vsc";
import { RiFileSettingsFill } from "react-icons/ri";
import { IoNotificationsSharp } from "react-icons/io5";
import { PiSignOutBold } from "react-icons/pi";
import { FaRegCopyright } from "react-icons/fa6";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import { getCookie, isAuth } from "../../../auth/helper";
import { toast } from "react-toastify";

const DoctorSidebar = ({ children, activeLink }) => {
  // const [settingActive, setSettingActive] = useState(false);
  // const [supportActive, setSupportActive] = useState(false);
  const navigate = useNavigate();
  const { settingActive, setSettingActive, supportActive, setSupportActive } =
    useContext(GlobalStorage);
  const {
    darkMode,
    newPatient1,
    setNewPatient1,
    doctorActiveBar,
    setDoctorActiveBar,
    doctorPatient,
    setDoctorPatient,
    refreshPatient,
    setRefreshPatient,
    doctorProfile,
    setDoctorProfile,
  } = useContext(GlobalStorage);

  const token = getCookie("token");
  const navItem = [
    {
      link: "/doctor/dashboard",
      icon: <MdDashboard size={20} />,
      title: "Dashboard",
    },
    {
      link: "/doctor/patients",
      icon: <FaHospitalUser size={22} />,
      title: "Patient",
    },
    {
      icon: <IoSettings size={22} />,
      title: "Settings",
      subLinks: [
        {
          link: "/doctor/setting/general",
          icon: <RiFileSettingsFill size={22} />,
          title: "General",
        },
        {
          link: "/doctor/setting/preferences",
          icon: <VscSettings size={22} />,
          title: "Preferences ",
        },
        {
          link: "/doctor/setting/notifications",
          icon: <IoNotificationsSharp size={22} />,
          title: "Notifications",
        },
      ],
    },
  ];
  const supportItem = [
    {
      link: "/doctor/support/resources",
      icon: <GrResources size={22} />,
      title: "Resources",
    },
    {
      link: "/doctor/support/privacy",
      icon: <MdOutlineSecurity size={22} />,
      title: "Privacy & Security",
    },
    {
      link: "/doctor/support/contact",
      icon: <MdContactMail size={22} />,
      title: "Contact Admin",
    },
  ];
  const handlesignout = () => {
    if (window !== "undefined") {
      cookie.remove("token", {
        expires: 1,
      });
    }
    if (window !== "undefined") {
      localStorage.removeItem("user");
    }
    navigate("/doctor/login");
  };

  useEffect(() => {
    loadPData();
  }, [refreshPatient]);
  const loadPData = () => {
    axios({
      method: "POST",
      url: `${BASE_URL}/doctor/patient`,
      data: { doctorId: isAuth()?._id },
    })
      .then((response) => {
        console.log("Get pATIENT", response);
        // setPatients(response.data);
        setDoctorPatient(response?.data);
      })
      .catch((error) => {
        console.log("Get Patient ERROR", error.response);
      });
  };
  const handleTranscript = () => {
    if (doctorPatient?.length < 1) {
      return toast.error("Please Add the patient First");
    }
    setNewPatient1(true);
  };

  useEffect(() => {
    loadDoctorProfile();
  }, []);

  const loadDoctorProfile = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/doctor/profile`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("Get Doctor Profile", response);
        setDoctorProfile(response.data);
      })
      .catch((error) => {
        console.log("Get Doctor Profile ERROR", error.response);
      });
  };

  return (
    <>
      <div className="flex">
        {!doctorActiveBar && (
          <>
            <div className="flex flex-col p-3 min-h-screen bg-[#fff] dark:bg-[var(--doctor-color)] w-[285px] relative z-10">
              <div className="space-y-3">
                <div className="flex items-center justify-center pt-[10px]">
                  {darkMode ? (
                    <img src={Logo} className="w-[165px] h-[60px]" alt="" />
                  ) : (
                    <img
                      src={LightLogo}
                      className="w-[165px] h-[60px]"
                      alt=""
                    />
                  )}
                </div>

                <div className="flex justify-center pt-[40px]">
                  <button
                    className="flex items-center justify-center bg-[var(--doctor-color)] text-[#fff] dark:bg-[#fff] w-[230px] h-[40px] rounded-full text-[16px] dark:text-[var(--doctor-color)] font-[500]"
                    onClick={handleTranscript}
                  >
                    <TiPlus /> New Transcript
                  </button>
                </div>

                <div className="flex-1">
                  <ul className="pt-2 pb-4  text-md ">
                    {navItem?.map((item) => {
                      return (
                        <>
                          {item?.link && !item?.subLinks && (
                            <li
                              className={`${
                                activeLink === item?.title
                                  ? "dark:bg-[rgba(255,207,170,1)] bg-[#FFCFAA] text-[#fff] dark:text-[var(--doctor-color)]"
                                  : "dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)]"
                              } rounded-md  hover:bg-[rgba(255,207,170,1)] hover:dark:text-[var(--doctor-color)] hover:text-[#fff] py-[3px] my-[12px] text-[16px] font-[400]`}
                            >
                              <Link
                                to={item?.link}
                                className="flex items-center p-2 space-x-3 rounded-md"
                              >
                                <div className="">{item?.icon}</div>
                                <span>{item?.title}</span>
                              </Link>
                            </li>
                          )}
                          {!item?.link && item?.subLinks && (
                            <>
                              <div className="mt-[10px]">
                                <li className=" cursor-pointer rounded-md dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)] py-[3px] my-[12px] text-[16px] font-[400]">
                                  <div
                                    to="/doctor/setting/general"
                                    className="flex items-center p-2 space-x-3 rounded-md"
                                    onClick={() =>
                                      setSettingActive(!settingActive)
                                    }
                                  >
                                    <div className="">{item?.icon}</div>
                                    <span>{item?.title}</span>
                                  </div>
                                </li>
                                {settingActive && (
                                  <>
                                    {item?.subLinks.map((sub) => {
                                      return (
                                        <div
                                          className={`${
                                            activeLink === sub?.title
                                              ? "bg-[rgba(255,207,170,1)] dark:text-[var(--doctor-color)] text-[#fff]"
                                              : "dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)]"
                                          } cursor-pointer rounded-md  pl-[50px]  hover:bg-[rgba(255,207,170,1)] hover:dark:text-[var(--doctor-color)] hover:text-[#fff] `}
                                        >
                                          <li className=" rounded-md  hover:bg-[rgba(255,207,170,1)] hover:text-[var(--doctor-color)] hover:text-[#fff] py-[3px] my-[12px] text-[16px] font-[500]">
                                            <Link
                                              to={sub?.link}
                                              className="flex items-center p-2 space-x-3 rounded-md"
                                            >
                                              <div className="">
                                                {sub?.icon}
                                              </div>
                                              <span>{sub?.title}</span>
                                            </Link>
                                          </li>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}
                  </ul>
                  <div className="pl-[10px] text-[16px] dark:text-[#fff] text-[rgba(0,0,0,1)] mb-[100px]">
                    <h3>SUPPORT</h3>
                    <div
                      className=" cursor-pointer rounded-md dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)]  py-[3px] my-[12px] text-[16px] font-[400]"
                      onClick={() => setSupportActive(!supportActive)}
                    >
                      <div
                        to="/doctor/support/resources"
                        className="flex items-center p-2 space-x-3 rounded-md "
                      >
                        <div className="">
                          <FiPhone size={22} />
                        </div>
                        <span className="">Help & Support</span>
                      </div>
                    </div>
                    {supportActive && (
                      <>
                        {supportItem.map((sub) => {
                          return (
                            <div
                              className={`${
                                activeLink === sub?.title
                                  ? "bg-[rgba(255,207,170,1)] dark:text-[var(--doctor-color)] text-[#fff] "
                                  : "dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)]"
                              } cursor-pointer rounded-md   pl-[30px]  hover:bg-[rgba(255,207,170,1)] hover:dark:text-[var(--doctor-color)] hover:text-[#fff] `}
                            >
                              <div
                                className={`${
                                  activeLink === sub?.title
                                    ? "bg-[rgba(255,207,170,1)] dark:text-[var(--doctor-color)] text-[#fff]"
                                    : "dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)]"
                                } rounded-md hover:bg-[rgba(255,207,170,1)] hover:dark:text-[var(--doctor-color)] hover:text-[#fff] py-[3px] my-[12px] text-[16px] font-[500]`}
                              >
                                <Link
                                  to={sub?.link}
                                  className="flex items-center p-2 space-x-3 rounded-md"
                                >
                                  <div className="">{sub?.icon}</div>
                                  <span>{sub?.title}</span>
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="absolute bottom-[40px] -right-[18px] cursor-pointer font-[500] text-[16px] text-[#fff] z-[101]"
                  onClick={() => setDoctorActiveBar(!doctorActiveBar)}
                >
                  <FaCaretSquareLeft
                    size={25}
                    className="text-[var(--doctor-color)]"
                  />
                </div>
                {/* <div
                  className="flex justify-center items-center cursor-pointer my-10"
                  onClick={handlesignout}
                >
                  <div className="  pt-[20px] font-[500] text-[16px] text-[#fff]">
                    <p>Signout</p>
                  </div>
                </div> */}
                <div className="flex justify-center items-center mt-5">
                  <div className="absolute bottom-[20px]  font-[500] text-[16px] text-[#fff]">
                    <div
                      className="flex justify-center items-center cursor-pointer "
                      onClick={handlesignout}
                    >
                      <div className=" pt-[20px] font-[500] text-[16px] text-[rgba(0,0,0,.75)] dark:text-[#fff] my-3">
                        <p>Signout</p>
                      </div>
                    </div>
                    <p className="text-[rgba(0,0,0,.75)] dark:text-[#fff]">
                      © Medinclude
                    </p>
                    {/* <p>Signout</p> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {doctorActiveBar && (
          <>
            <div className="flex flex-col p-3 min-h-screen bg-[#fff] dark:bg-[var(--doctor-color)] w-[100px] relative z-10">
              <div className="space-y-3">
                <div className="flex items-center justify-center pt-[10px]">
                  {darkMode ? (
                    <img src={ShortLogo} className="w-[60px] h-[50px]" alt="" />
                  ) : (
                    <img
                      src={ShortLightLogo}
                      className="w-[60px] h-[50px]"
                      alt=""
                    />
                  )}
                </div>
                <div className="flex justify-center pt-[40px]">
                  <button
                    className="flex items-center justify-center bg-[var(--doctor-color)] text-[#fff] dark:bg-[#fff] w-[230px] h-[40px] rounded-full text-[16px] dark:text-[var(--doctor-color)] font-[500]"
                    onClick={handleTranscript}
                  >
                    <TiPlus />
                  </button>
                </div>

                <div className="flex-1">
                  <ul className="pt-2 pb-4  text-md ">
                    {navItem?.map((item) => {
                      return (
                        <>
                          {item?.link && !item?.subLinks && (
                            <li
                              className={`${
                                activeLink === item?.title
                                  ? "dark:bg-[rgba(255,207,170,1)] bg-[#FFCFAA] text-[#fff] dark:text-[var(--doctor-color)]"
                                  : "dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)]"
                              } rounded-md  hover:bg-[rgba(255,207,170,1)] hover:dark:text-[var(--doctor-color)] hover:text-[#fff] py-[3px] my-[12px] text-[16px] font-[400]`}
                            >
                              <Link
                                to={item?.link}
                                className="flex items-center p-2 space-x-3 rounded-md"
                              >
                                <div className="">{item?.icon}</div>
                                {/* <span>{item?.title}</span> */}
                              </Link>
                            </li>
                          )}
                          {!item?.link && item?.subLinks && (
                            <>
                              <div className="mt-[10px]">
                                <li className=" cursor-pointer rounded-md dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)] py-[3px] my-[12px] text-[16px] font-[400]">
                                  <div
                                    to="/doctor/setting/general"
                                    className="flex items-center p-2 rounded-md"
                                    onClick={() =>
                                      setSettingActive(!settingActive)
                                    }
                                  >
                                    <div className="">{item?.icon}</div>
                                    {/* <span>{item?.title}</span> */}
                                  </div>
                                </li>
                                {settingActive && (
                                  <>
                                    {item?.subLinks.map((sub) => {
                                      return (
                                        <div
                                          className={`${
                                            activeLink === sub?.title
                                              ? "bg-[rgba(255,207,170,1)] dark:text-[var(--doctor-color)] text-[#fff]"
                                              : "dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)]"
                                          } cursor-pointer rounded-md  pl-[20px]  hover:bg-[rgba(255,207,170,1)] hover:dark:text-[var(--doctor-color)] hover:text-[#fff] `}
                                        >
                                          <li className=" rounded-md  hover:bg-[rgba(255,207,170,1)] hover:text-[var(--doctor-color)] hover:text-[#fff] py-[3px] my-[12px] text-[16px] font-[500]">
                                            <Link
                                              to={sub?.link}
                                              className="flex items-center p-2 space-x-3 rounded-md"
                                            >
                                              <div className="">
                                                {sub?.icon}
                                              </div>
                                              {/* <span>{sub?.title}</span> */}
                                            </Link>
                                          </li>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}
                  </ul>
                  <div className="pl-[10px] text-[16px] dark:text-[#fff] text-[rgba(0,0,0,1)] mb-[100px]">
                    {/* <h3>SUPPORT</h3> */}
                    <div
                      className=" cursor-pointer rounded-md dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)]  py-[3px] my-[12px] text-[16px] font-[400]"
                      onClick={() => setSupportActive(!supportActive)}
                    >
                      <div
                        to="/doctor/support/resources"
                        className="flex items-center p-2 space-x-3 rounded-md "
                      >
                        <div className="">
                          <FiPhone size={22} />
                        </div>
                        {/* <span className="">Help & Support</span> */}
                      </div>
                    </div>
                    {supportActive && (
                      <>
                        {supportItem.map((sub) => {
                          return (
                            <div
                              className={`${
                                activeLink === sub?.title
                                  ? "bg-[rgba(255,207,170,1)] dark:text-[var(--doctor-color)] text-[#fff] "
                                  : "dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)]"
                              } cursor-pointer rounded-md   pl-[20px]  hover:bg-[rgba(255,207,170,1)] hover:dark:text-[var(--doctor-color)] hover:text-[#fff] `}
                            >
                              <div
                                className={`${
                                  activeLink === sub?.title
                                    ? "bg-[rgba(255,207,170,1)] dark:text-[var(--doctor-color)] text-[#fff]"
                                    : "dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)]"
                                } rounded-md hover:bg-[rgba(255,207,170,1)] hover:dark:text-[var(--doctor-color)] hover:text-[#fff] py-[3px] my-[12px] text-[16px] font-[500]`}
                              >
                                <Link
                                  to={sub?.link}
                                  className="flex items-center p-2 space-x-3 rounded-md"
                                >
                                  <div className="">{sub?.icon}</div>
                                  {/* <span>{sub?.title}</span> */}
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="absolute bottom-[40px] -right-[18px] cursor-pointer font-[500] text-[16px] text-[#fff] z-[101]"
                  onClick={() => setDoctorActiveBar(!doctorActiveBar)}
                >
                  <FaCaretSquareRight
                    size={25}
                    className="text-[var(--doctor-color)]"
                  />
                </div>

                <div className="flex justify-center items-center mt-5">
                  <div className="absolute bottom-[20px]  font-[500] text-[16px] text-[#fff]">
                    <div
                      className="flex justify-center items-center cursor-pointer "
                      onClick={handlesignout}
                    >
                      <div className=" pt-[20px] font-[500] text-[15px] text-[rgba(0,0,0,.75)] dark:text-[#fff] mt-3 mb-5">
                        {/* <PiSignOutBold size={18} /> */}
                        Signout
                      </div>
                    </div>
                    <p className="text-[rgba(0,0,0,.75)] dark:text-[#fff]">
                      <FaRegCopyright size={18} />
                    </p>
                    {/* <p>Signout</p> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="w-full">
          <div className="h-[86px] dark:bg-[var(--doctor-color)] bg-[#fff] w-full">
            <Topbar />
          </div>
          <div>
            {newPatient1 && <NewTransccript />}
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorSidebar;
