import React from "react";
import Patient from "../../images/patient/patient.png";
import NewLogo from '../../images/newLogo.png'
import { Link } from "react-router-dom";

const Signup = () => {
  return (
    <>
      <div
        className=" w-full min-h-[110vh]   bg-[#CCE1DD]"
        style={{
          backgroundImage: `url(${Patient})`,

          backgroundPosition: "center",
        }}
      >
        <div className="pl-[170px]">
          <img src={NewLogo} alt="" />
        </div>
        <div className="flex justify-center ">
          <div className="w-[50%] pl-[170px] h-[520px] -mt-[50px]  ">
            <div className="flex flex-col items-start justify-end  h-[520px] mt-6">
              <p className="font-inter font-[300] text-[13px] text-[#000] w-[60%]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>

              <div className="flex justify-start items-start"> 
              <Link to="/patient" className="bg-[#FFCFAA] flex items-center justify-center mt-[25px] w-[200px] h-[50px] rounded-[10px] text-[var(--doctor-color)] font-[600] text-[16px]">
                Back to Home
              </Link>
              </div>
            </div>
          </div>
          <div className="w-[50%] flex justify-evenly -mt-[50px]">
            <div className="w-[450px] h-[580px]  bg-[rgba(1,104,85,0.7)]  rounded-[40px] px-6 py-6 font-inter">
              <p className="text-[16px] ">
                Welcome to{" "}
                <span className="text-[#FFCFAA] font-[600]">Medinclude</span>
              </p>
              <h2 className="text-[50px] font-[500]">Sign up</h2>
              <div>
                <label className="font-inter text-[15px] ">
                  {" "}
                  Email Address
                </label>
                <br />
                <input
                  type="text"
                  className="w-[100%] h-[45px] rounded-[9px] outline-none px-4 text-[#808080]"
                  placeholder="Email Address"
                />
                <div className="flex w-full my-6">
                  <div className="w-[50%] mr-1">
                    <label className="font-inter text-[15px] ">
                      {" "}
                      Hospital Code
                    </label>
                    <br />
                    <input
                      type="text"
                      className="w-[100%] h-[45px] rounded-[9px] outline-none px-4 text-[#808080]"
                      placeholder="Hospital Code"
                    />
                  </div>
                  <div className="w-[50%] ml-1">
                    <label className="font-inter text-[15px] ">
                      {" "}
                      Pateint ID
                    </label>
                    <br />
                    <input
                      type="text"
                      className="w-[100%] h-[45px] rounded-[9px] outline-none px-4 text-[#808080]"
                      placeholder="Pateint ID"
                    />
                  </div>
                </div>
                <label className="font-inter text-[15px] ">
                  {" "}
                  Enter Your Password
                </label>
                <br />
                <input
                  type="text"
                  className="w-[100%] h-[45px] rounded-[9px] outline-none px-4 text-[#808080]"
                  placeholder="Password"
                />

                <div className="mt-6">
                  <div className="flex gap-4 items-center">
                    <div class="round">
                      <input type="checkbox" id="checkbox" />
                      <label for="checkbox"></label>
                    </div>
                    <p className="w-[70%] font-[300] text-[13px]">
                      {" "}
                      I agree with the Terms of Service & Privacy Policy
                    </p>
                  </div>

                  <div className="flex gap-4 items-center pt-2">
                    <div class="round mt-2">
                      <input type="checkbox" id="checkbox2" />
                      <label for="checkbox2"></label>
                    </div>
                    <p className="w-[70%] font-[300] text-[13px]">
                      {" "}
                      Subscribe to our Newsletter
                    </p>
                  </div>
                </div>

                <div className="mt-[20px] flex items-center justify-between">
                  <div>
                    <p className="text-[rgba(255,207,170,.4)] font-inter font-[500] text-[13px]">
                      Have an Account ?
                    </p>
                    <Link to="/patient/signin" className="text-[#FFCFAA] font-inter font-[500] text-[13px]">
                      Sign in{" "}
                    </Link>
                  </div>
                  <button
                    className="bg-[#FFCFAA] w-[200px] h-[50px] rounded-[10px] text-[var(--doctor-color)] font-[600] text-[16px]"
                    style={{ boxShadow: "0px 4px 19px 0px #7793414D" }}
                  >
                    Sign up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
