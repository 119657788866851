import React, { useContext, useEffect, useRef, useState } from "react";
import DoctorSidebar from "../../Component/Doctor/Layout/DoctorSidebar";
import { TiPlus } from "react-icons/ti";
import { CiSearch } from "react-icons/ci";
import PatientImage from "../../images/patientImage.png";
import { PiUploadFill } from "react-icons/pi";
import { RiEdit2Fill } from "react-icons/ri";
import GlobalStorage from "../../Storage/ContextProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { isAuth } from "../../auth/helper";
import { toast } from "react-toastify";
import moment from "moment";
import { FaRegUserCircle } from "react-icons/fa";
import DeleteIcon from "../../images/deleteIcon.png";
import { AiOutlineDelete } from "react-icons/ai";

const Patients = () => {
  const [showAdd, setShowAdd] = useState("");
  const [showAdd2, setShowAdd2] = useState(false);
  const {
    addPatient,
    setAddPatient,
    doctorPatient,
    setDoctorPatient,
    refreshPatient,
    setRefreshPatient,
  } = useContext(GlobalStorage);
  // const [patients, setPatients] = useState();
  const [patientId, setPatientId] = useState("");
  const [file, setFile] = useState(null);
  const [patientEmail, setPatientEmail] = useState("");
  const [name, setName] = useState("");
  const [statusText, setStatusText] = useState("Pending");
  // const [refreshPateint, setRefreshPatient] = useState(false);
  const [transcribingText, setTranscribingText] = useState("Transcribe");
  const [transCribeClick, setTranscribeClick] = useState(false);
  const [updatedPatients, setUpdatedPatients] = useState();
  const [isResendLink, setIsResetLink] = useState(false);
  const [resendData, setResendData] = useState();
  const [resendEmail, setResendEmail] = useState();
  const [selectedOption, setSelectedOption] = useState("");

  const {
    rawData,
    setRawData,
    processedData,
    setProcessedData,
    patientDetail,
    setPatientDetail,
    selectPatient,
    setSelectPatient,
    doctorProfile,
  } = useContext(GlobalStorage);

  const navigate = useNavigate();
  const pData = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
  ];

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowAdd(false);
          // alert("You clicked outside of me!");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const handleChange = (event, data) => {
    if (doctorProfile?.status === "InActive") {
      return toast.error("Account Deactivated");
    }

    setFile(event.target.files[0]);
    console.log("DATA", data?._id);
    setPatientId(data?._id);
    // setPatientDetail(data);
    // console.log("LOGG", event.target.files[0]);
  };

  const handletranscribe = (event, data) => {
    setTranscribingText("Transcribing...");
    setTranscribeClick(true);
    const formData = new FormData();
    formData.append("medReportFile", file);
    formData.append("doctorId", isAuth()?._id);
    formData.append("patientId", patientId);
    formData.append("medTextReport", "");
    axios
      .post(`${BASE_URL}/transcribe`, formData)
      .then((response) => {
        // console.log("Translate Success", response);
        setTranscribingText("Transcribe");
        setTranscribeClick(false);

        setFile(null);
        setRawData(response?.data?.report?.original);
        setProcessedData(response?.data?.report?.transcribe);
        // navigate(`/doctor/transcribe/${patientId}`);
        navigate(`/doctor/transcribe/${patientId}`, {
          state: {
            rawData: response?.data?.report?.original,
            processedData: response?.data?.report?.transcribe,
          },
        });
        // setTranslatedData(response.data.translatedText);
      })
      .catch((error) => {
        setFile(null);
        setTranscribingText("Transcribe");
        setTranscribeClick(false);

        toast.error("Error While Reading the file");
        console.log("Transcribe  Error", error.response.data);
      });
  };
  const handleAddPatient = async () => {
    const data = {
      email: patientEmail,
      doctorId: isAuth()?._id,
      name,
    };
    const addPatient = await axios({
      method: "POST",
      url: `${BASE_URL}/doctor/patientRegister`,
      data: data,
    })
      .then((response) => {
        console.log("Patient Registered Success", response);
        toast.success(response.data?.msg);
        setAddPatient(false);
        setRefreshPatient(!refreshPatient);
      })
      .catch((error) => {
        console.log("Login  Error", error.response.data);
        toast.error(error.response.data);
      });
  };
  const handleViewPatient = (data) => {
    setPatientDetail(data);
    setSelectPatient(true);
    navigate("/doctor/dashboard");
  };

  useEffect(() => {
    const updatedObjects = doctorPatient?.map((object) => {
      const createdAtMilliseconds = new Date(object?.updatedAt).getTime();
      const fiveMinutesLater = new Date(
        // createdAtMilliseconds + 3 * 60000 * 60 * 24
        createdAtMilliseconds + 3 * 60000 * 60 * 24
      );
      const currentTime = new Date();
      if (object?.status === "Pending" && currentTime >= fiveMinutesLater) {
        return {
          ...object,
          status: "Expired",
        };
      }
      return object;
    });
    setUpdatedPatients(updatedObjects);
  }, [doctorPatient]);

  const handleEditData = (data) => {
    setPatientDetail(data);
    navigate(`/doctor/transcribe/${data?._id}`);
  };
  const handleAddPatients = () => {
    if (doctorProfile?.status === "InActive") {
      return toast.error("Account Deactivated");
    }
    const isNotDeleted = (task) => task.deleteForDoctor === false;
    const nonDeletedPatient = doctorPatient.filter(isNotDeleted);

    if (nonDeletedPatient?.length > 9) {
      return toast.error(
        "You can only have a maximum of 10 patients, please contact admin"
      );
    }
    setAddPatient(true);
  };

  const handleResend = (data) => {
    if (doctorProfile?.status === "InActive") {
      return toast.error("Account Deactivated");
    }
    setIsResetLink(true);
    setResendData(data);
    setResendEmail(data?.email);
  };

  const handleResendLink = async () => {
    if (doctorProfile?.status === "InActive") {
      return toast.error("Account Deactivated");
    }
    if (!resendEmail) {
      return toast.error("Please Enter the email");
    }
    const data = {
      doctorId: resendData?.doctorId,
      patientId: resendData?._id,
      email: resendEmail,
    };

    const activateAccount = await axios({
      method: "POST",
      url: `${BASE_URL}/patient/resend-link`,
      data: data,
    })
      .then((response) => {
        console.log("Token Resend Success", response);
        toast.success(response?.data.msg);
        setIsResetLink(false);
        setRefreshPatient(!refreshPatient);
        // setRefreshDepartment(!refreshDepartment);
        //   navigate("/doctor/login");
        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Token Resend Error", error.response.data);
        toast.error(error.response.data);
      });
  };

  const handleRadioChange = (data) => {
    if (selectedOption && selectedOption == data?._id) {
      setSelectedOption("");
    } else {
      setSelectedOption(data?._id);
    }
  };

  const handleDeletePatient = () => {
    if (doctorProfile?.status === "InActive") {
      return toast.error("Account Deactivated");
    }
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this Patient?"
    );
    if (isConfirmed) {
      axios({
        method: "PUT",
        url: `${BASE_URL}/doctor/delete-patient/${selectedOption}`,
      })
        .then((response) => {
          console.log("Delete Patient", response.data);
          toast.success("Patient Deleted Successfully");
          setRefreshPatient(!refreshPatient);
          // setToggle(!toggle);
        })
        .catch((error) => {
          console.log("Delete Patient Error", error.response);
        });
    }
  };
  // console.log("Selected Options", selectedOption);
  return (
    <>
      <DoctorSidebar activeLink="Patient">
        <div
          className={`${
            addPatient || isResendLink
              ? "bg-[rgba(0,0,0,0.7)] opacity-[.8] dark:opacity-[.3] overflow-hidden h-[90vh]"
              : "lightmode dark:bg-[#373737] dark:text-[#fff] min-h-fit"
          } p-[20px] `}
        >
          <div className={`${addPatient ? "opacity-[.3]" : ""}`}>
            <div className="flex justify-between ">
              <h2 className="font-[500] text-[25px]">Patients</h2>
              <button
                className="w-[162px] h-[33px] rounded-full flex items-center justify-center bg-[#FFCFAA] text-[var(--doctor-color)] text-[14px]"
                onClick={handleAddPatients}
              >
                <TiPlus /> New Patient
              </button>
            </div>
            <div className="flex items-center gap-8">
              <div className="flex items-center w-full h-[46px] bg-[#FFFFFF] dark:bg-[#67A599] dark:text-[#fff] rounded-lg relative text-[grey] my-[20px] ">
                <CiSearch className="absolute left-[10px] top-[15px]" />
                <input
                  className="bg-transparent w-full outline-none ml-[35px] mr-[10px] placeholder-[grey] dark:placeholder-[white]"
                  type="text"
                  placeholder="Search Patients"
                />
              </div>
              {selectedOption && (
                <div
                  className="w-[47px] h-[44px] rounded-full flex items-center justify-center bg-[#FF5252] opacity-70 cursor-pointer"
                  onClick={handleDeletePatient}
                >
                  <AiOutlineDelete size={22} />
                </div>
              )}
            </div>
            <div className="">
              {updatedPatients?.map((data, index) => {
                return (
                  <>
                    {data?.deleteForDoctor === false && (
                      <div
                        key={index}
                        className="lastBorderr flex justify-between items-center border-dashed border-b-[0.5px] border-[#67A599] py-[20px]"
                      >
                        <input
                          type="radio"
                          className="mr-3 mt-1"
                          value={data?._id}
                          checked={selectedOption === data?._id}
                          onClick={() => handleRadioChange(data)}
                        />
                        <div className="flex items-center gap-[25px] border-dotted border-r border-[#67A599] pr-[25px] w-[250px]">
                          {/* <img
                          src={PatientImage}
                          alt=""
                          className="W-[65px] h-[65px] rounded-full"
                        /> */}
                          <FaRegUserCircle className="text-[40px]" />
                          <div>
                            <p className="text-[17px] text-[var(--doctor-color)] dark:text-[#fff]">
                              {data?.name}
                            </p>
                            {/* <p className="text-[#67A599] text-[15px] dark:text-[#FFCFAA]">
                            Last Consult: Wed, Nov 22, 2023
                          </p> */}
                          </div>
                        </div>
                        <div className="w-[250px] border-r border-[#67A599] text-[#67A599] text-[15px] dark:text-[#FFCFAA]">
                          {data?.email}
                        </div>
                        <div>
                          {/* <p className="text-[17px]">
                          Hello Miss Jane, Would you be coming in for your
                          appointment today?
                        </p> */}
                          <p className="text-[#67A599] text-[14px] dark:text-[#FFCFAA]">
                            Last Transcript:{" "}
                            {moment(new Date(data?.lastUpdate)).format(
                              "MMMM Do YYYY, h:mm:ss a "
                            )}
                          </p>
                        </div>
                        <div className="text-[#fff] flex gap-5">
                          {data?.status == "Expired" && (
                            <button
                              className="bg-yellow-400 text-[#FFFFF] w-[122px] h-[39px] text-[18px] rounded-full"
                              onClick={() => handleResend(data)}
                            >
                              {data?.status}
                            </button>
                          )}

                          {data?.status == "Pending" && (
                            <button
                              className="bg-yellow-400 text-[#FFFFF] w-[122px] h-[39px] text-[18px] rounded-full"
                              onClick={() => handleResend(data)}
                            >
                              {data?.status}
                            </button>
                          )}
                          {data?.status == "Active" && (
                            <button className="w-[122px] h-[39px] bg-[#1CB8EA] rounded-full text-[18px] font-[500]">
                              Active
                            </button>
                          )}
                          {data?.status == "InActive" && (
                            <button
                              className="bg-[#FF0000] dark:text-[#FFFFF] w-[122px] h-[39px] text-[18px] rounded-full"
                              // onClick={() => handleViewPatient(data)}
                            >
                              Inactive
                            </button>
                          )}
                          <button
                            className="bg-[#67A599] dark:bg-[#FFCFAA] dark:text-[var(--doctor-color)] w-[122px] h-[39px] text-[18px] rounded-full"
                            onClick={() => handleViewPatient(data)}
                          >
                            View{" "}
                          </button>
                          <button
                            className={`${
                              showAdd === index ? "hidden" : ""
                            } bg-[#67A599] dark:bg-[#FFCFAA] dark:text-[var(--doctor-color)] w-[122px] h-[39px] text-[18px] rounded-full flex items-center justify-center`}
                            onClick={() => setShowAdd(index)}
                          >
                            <TiPlus size={21} />
                          </button>

                          {showAdd === index && (
                            <>
                              <div
                                ref={wrapperRef}
                                className="w-[122px] h-[83px] bg-[#67A599] dark:bg-[#FFCFAA] dark:text-[var(--doctor-color)]  text-[#fff] rounded-[3px] text-[16px]"
                              >
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
                                  onChange={(event) =>
                                    handleChange(event, data)
                                  }
                                />
                                <button
                                  className="w-full h-[42px] border-b-[1px] border-[rgba(255,255,255,.4)] flex items-center justify-center gap-[6px]"
                                  onClick={() =>
                                    document
                                      .querySelector('input[type="file"]')
                                      .click()
                                  }
                                >
                                  <PiUploadFill />
                                  Upload
                                </button>
                                <button
                                  className="w-full h-[42px] flex items-center justify-center gap-[6px] "
                                  onClick={() => handleEditData(data)}
                                >
                                  <RiEdit2Fill /> Edit Text
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>

        {addPatient && (
          <>
            <div className="absolute top-[200px] left-[40%] right-[40%]">
              <div>
                <button className="w-[550px] h-[60px] bg-[var(--doctor-color)] dark:bg-[#67A599] text-[21px] font-[500] text-[#fff] rounded-2xl">
                  New Patient
                </button>
                <div className=" w-[550px] bg-[#CCE1DD] dark:bg-[var(--dark-primary)] p-[35px] mt-[15px] rounded-2xl">
                  <input
                    className="text-[#052721] border-[1px] pl-5 border-[rgba(0,0,0,.4)] w-full outline-none h-[45px] rounded-lg mb-7 "
                    style={{ background: "rgba(217, 217, 217, .9)" }}
                    type="text"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <div
                    className="flex items-center w-full h-[45px] rounded-lg relative text-[#052721] border-[1px] border-[rgba(0,0,0,.4)]"
                    style={{ background: "rgba(217, 217, 217, .9)" }}
                  >
                    <CiSearch className="absolute left-[10px]" />
                    <input
                      className="bg-transparent w-full outline-none ml-[35px] mr-[10px] "
                      type="text"
                      placeholder="Email Address"
                      value={patientEmail}
                      onChange={(e) => setPatientEmail(e.target.value)}
                    />
                  </div>
                  <div className="flex gap-[20px] mt-[30px] text-[21px] font-inter font-[700] text-[#fff]">
                    <button
                      className="w-[65%] h-[53px] bg-[#DDBEA6] rounded-full"
                      onClick={handleAddPatient}
                    >
                      Send Link
                    </button>
                    <button
                      className="w-[35%] h-[53px] bg-[#FF5252] rounded-full"
                      onClick={() => setAddPatient(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {isResendLink && (
          <>
            <div className="absolute top-[150px]  left-[36%] w-[45%]  ">
              <button className="w-[100%] bg-[#67A599] dark:bg-[var(--doctor-color)] text-[#fff] h-[50px] rounded-[20px] font-[500] text-[20px]">
                Resend Link
              </button>

              <div className="mt-[15px] bg-[#CCE1DD] rounded-[20px]  px-[20px] py-[35px] ">
                <input
                  type="text"
                  className="w-full h-[45px] my-[20px] text-[16px] rounded-[11px] outline-none bg-transparent border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                  placeholder="Email Address"
                  value={resendEmail}
                  onChange={(e) => setResendEmail(e.target.value)}
                />
                <div className=" mt-[30px] flex items-center gap-[20px] font-[600] font-inter text-[21px] text-[#fff]">
                  <button
                    className="w-[70%] h-[50px] rounded-[37px] bg-[#DDBEA6] "
                    onClick={handleResendLink}
                  >
                    Resend Link
                  </button>
                  <button
                    className="w-[30%] h-[50px] rounded-[37px] bg-[#FF0000]"
                    onClick={() => setIsResetLink(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {file && (
          <>
            <div className="absolute top-[200px] bg-white w-[750px]  left-[34%] right-[30%] rounded-lg">
              <div>
                <div className="text-black p-[35px] mt-[15px] rounded-2xl">
                  <h2 className="text-center text-2xl font-[600]">Your File</h2>
                  <p className="text-center my-8">{file?.name}</p>
                  <div className="w-full flex items-center justify-center gap-6">
                    <button
                      disabled={transCribeClick}
                      className={` ${
                        transCribeClick ? "opacity-40" : "opacity-100"
                      } flex items-center justify-center bg-[var(--doctor-color)] text-white py-3 px-12 rounded-lg`}
                      onClick={handletranscribe}
                    >
                      {transcribingText}
                    </button>
                    <button
                      className="flex items-center justify-center bg-[#FF5252] text-white py-3 px-12 rounded-lg"
                      onClick={() => setFile(null)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </DoctorSidebar>
    </>
  );
};

export default Patients;
