import React, { useContext, useState } from "react";
import ShortLogo from "../../../images/shortLogo.png";
import ShortKight from "../../../images/shoetLightLogo.png";
import { RiMenu2Line } from "react-icons/ri";
import GlobalStorage from "../../../Storage/ContextProvider";
import { RxCross2 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { MdOutlineLightMode } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";
import Profile from "../../../images/topProfile.png";
import { MdOutlineNotificationAdd } from "react-icons/md";
import cookie from "js-cookie";

const PatientSmallSidebar = ({ patientOpen, setPatientOpen }) => {
  const { darkMode, setDarkMode, showSearachBar, setShowSearchBar } =
    useContext(GlobalStorage);
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();

  const handleToggleMode = () => {
    setDarkMode(!darkMode);
    setPatientOpen(!patientOpen);
  };
  const handlesignout = () => {
    if (window !== "undefined") {
      cookie.remove("token", {
        expires: 1,
      });
    }
    if (window !== "undefined") {
      localStorage.removeItem("user");
    }
    navigate("/patient/signin");
  };
  return (
    <>
      <div className=" px-10 flex justify-between items-center py-4 bg-[var(--dark-primary)] fixed w-full  z-[101]">
        <img src={ShortLogo} className="w-[50px] h-[50px] " alt="" />

        <div className="flex justify-center items-center  gap-5">
          {/* <div
            className="w-[40px] h-[40px] flex text-[#67A599]  justify-center items-center rounded-full bg-[rgba(244,245,249,1)]  cursor-pointer"
            onClick={() => setShowSearchBar(!showSearachBar)}
          >
            <CiSearch size={22} />
          </div> */}
          <div className=" ">
            <div
              className="relative flex justify-center items-center h-[40px]  w-[40px] rounded-full bg-[rgba(244,245,249,1)]  cursor-pointer"
              // onClick={() => setShowNotification(!showNotification)}
            >
              <MdOutlineNotificationAdd className="text-[#67A599]  text-[22px] " />
              {showNotification && (
                <>
                  <div className="absolute top-[60px] -right-[15px] w-[272px] max-h-[312px] bg-[#fff] z-[101] rounded-[11px]">
                    <div className=" notyNotch absolute right-[20px] -top-[25px] "></div>
                    <div>
                      <div className="border-b-[1px] border-[rgba(119, 119, 119, 0.5)] mx-[15px] py-[15px] flex items-center gap-[15px]">
                        <img
                          src={Profile}
                          className="w-[44px] h-[44px] rounded-full"
                          alt=""
                        />
                        <div className="">
                          <p className="text-[13px] font-[500] text-[#373737]">
                            Hospital
                          </p>
                          <p className="text-[13px] text-[#373737]">
                            You have been assigned a new patient
                          </p>
                        </div>
                      </div>
                      <div className="border-b-[1px] border-[rgba(119, 119, 119, 0.5)] mx-[15px] py-[15px] flex items-center gap-[15px]">
                        <img
                          src={Profile}
                          className="w-[44px] h-[44px] rounded-full"
                          alt=""
                        />
                        <div className="">
                          <p className="text-[13px] font-[500] text-[#373737]">
                            Hospital
                          </p>
                          <p className="text-[13px] text-[#373737]">
                            You have been assigned a new patient
                          </p>
                        </div>
                      </div>

                      <div className="border-b-[1px] border-[rgba(119, 119, 119, 0.5)] mx-[15px] py-[15px] flex items-center gap-[15px]">
                        <img
                          src={Profile}
                          className="w-[44px] h-[44px] rounded-full"
                          alt=""
                        />
                        <div className="">
                          <p className="text-[13px] font-[500] text-[#373737]">
                            Hospital
                          </p>
                          <p className="text-[13px] text-[#373737]">
                            You have been assigned a new patient
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {patientOpen ? (
            <RxCross2
              size={24}
              className="cursor-pointer text-white"
              onClick={() => setPatientOpen(!patientOpen)}
            />
          ) : (
            <RiMenu2Line
              size={24}
              className="cursor-pointer text-white"
              onClick={() => setPatientOpen(!patientOpen)}
            />
          )}
        </div>
      </div>

      {patientOpen && (
        <div className="max-h-[110vh] bg-white">
          <div className="flex  flex-col h-[100vh]  justify-center items-center gap-7  font-inter text-[17px] font-[500] text-black bg-white">
            <div className="cursor-pointer" onClick={() => {navigate("/patient/dashboard");setPatientOpen(false)}}>Dashboard</div>
            <div className="cursor-pointer" onClick={() => {navigate("/dashboard/myreport");setPatientOpen(false)}}>My Reports</div>
            {/* <div className="cursor-pointer" onClick={() => {navigate("/dashboard/hospital");setPatientOpen(false)}}>My Hospital</div> */}
            <div className="cursor-pointer" onClick={() => {navigate("/dashboard/setting");setPatientOpen(false)}}>Setting</div>
            {/* <Link to="/dashboard/support">Support</Link> */}

            <p className="cursor-pointer" onClick={handlesignout}>
              Logout
            </p>
            <div className="cursor-pointer" onClick={() => {navigate("/dashboard/setting");setPatientOpen(false)}}>Profile</div>

            {/* <div className="flex items-center  h-[45px] w-[85%] border border-[rgba(0,0,0,.5)]  rounded-md relative text-[#000]   ">
              <CiSearch className="absolute left-[10px]" />
              <input
                className="bg-transparent w-full outline-none ml-[35px] mr-[10px] placeholder:text-[rgba(0,0,0,.5)] "
                type="text"
                placeholder="Search Dashboard"
              />
            </div> */}

            <div
              to=""
              className="flex items-center  rounded-md  text-black cursor-pointer"
              onClick={handleToggleMode}
            >
              <div className="">
                {darkMode ? (
                  <MdOutlineLightMode size={20} />
                ) : (
                  <MdDarkMode size={20} />
                )}
              </div>
              {darkMode ? <span>Light Mode</span> : <span>Dark Mode</span>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PatientSmallSidebar;
