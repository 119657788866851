import React, { useState } from "react";
import LoginImage from "../../images/hospital/login.png";
import LoginBg from "../../images/hospital/loginBg.png";
import Logo from "../../images/logo.png";
import { FaXTwitter } from "react-icons/fa6";
import { ImLinkedin2 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { authenticate } from "../../auth/helper";
import { ToastContainer, toast } from "react-toastify";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
const DoctorLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSignIn = async () => {
    const data = {
      email,
      password: pwd,
    };

    const LOGINData = await axios({
      method: "POST",
      url: `${BASE_URL}/doctors/login`,
      data: data,
    })
      .then((response) => {
        console.log("Login Success", response);
        // toast.success("Login success");
        authenticate(response, () => {
          setEmail("");
          setPwd("");
        });

        navigate("/doctor/dashboard");

        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Login  Error", error.response.data);
        toast.error(error.response.data);
      });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <div className=" w-[100%] dark:bg-[var(--dark-secondary)] flex p-[25px] justify-center items-center">
        <div className="w-[50%] flex flex-col items-center justify-center">
          <img src={LoginImage} className="w-[570px] h-[400px]" alt="" />
          <p className="text-[50px] font-[600] text-[var(--doctor-color)] dark:text-[#fff]">
            Simplify medical <br /> information in{" "}
            <span className="text-[#DCA071] dark:text-[#FFCFAA]">
              {" "}
              3 minutes.
            </span>
          </p>
        </div>

        <div className="w-[50%] flex items-center justify-center  ">
          <div
            className="w-[500px] h-[645px] flex flex-col bg-[var(--dark-secondary)] items-center justify-center text-[var(--dark-primary)] rounded-[10px]"
            style={{
              backgroundImage: `url(${LoginBg})`,
              boxShadow: "0px 14px 35px 0px #0000004A",
              backgroundPosition: "center",
            }}
          >
            <img src={Logo} className="w-[250px] h-[100px]" alt="" />
            <div className="flex items-center justify-center flex-col w-full  text-[#67A599] text-[16px]">
              <input
                type="text"
                className="w-[400px] h-[50px] rounded-full outline-none ml-[34px] my-[15px] px-[20px]"
                placeholder="Email ID"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="relative w-[400px] h-[50px] rounded-full  ml-[34px] my-[15px] px-[20px] bg-[#fff]">
                <input
                  type={showPassword ? "text" : "password"}
                  className="w-[300px] h-[50px] outline-none rounded-full"
                  placeholder="Password"
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                />
                {showPassword ? (
                  <IoEyeOutline
                    // color="red"
                    className="absolute left-[360px] z-50 top-[18px] cursor-pointer"
                    onClick={handleTogglePassword}
                  />
                ) : (
                  <IoEyeOffOutline
                    // color="red"
                    className="absolute left-[360px] z-50 top-[18px] cursor-pointer"
                    onClick={handleTogglePassword}
                  />
                )}
              </div>
              {/* <input
                type="text"
                className="w-[400px] h-[50px] rounded-full outline-none ml-[34px] my-[15px] px-[20px]"
                placeholder="Password"
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
              /> */}
            </div>
            <p className="text-right w-[80%] -mt-2 text-[14px] text-[#FFCFAA] cursor-pointer" onClick={() => navigate("/doctor/forget-password")}>Forgot Password</p>

            <button
              className="bg-[#FFCFAA] w-[250px] h-[50px] ml-[34px] rounded-full mt-[30px] text-[#000000] font-[500] text-[16px]"
              onClick={handleSignIn}
            >
              Sign In
            </button>


            <div className="text-[#fff] flex gap-[15px] mt-[100px] ">
              <FaXTwitter size={22} />
              <ImLinkedin2 size={22} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorLogin;
