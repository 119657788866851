import React from "react";
import { RiStarSFill } from "react-icons/ri";
import { FaThumbsUp } from "react-icons/fa6";
const SingleCard = () => {
  return (
    <>
      <div className=" 900px:w-[500px] w-[100%]  bg-white ">
        <div className="flex items-center justify-between p-5">
          <div className="flex gap-[1px] text-[#67A599] text-[24px]">
            <RiStarSFill />
            <RiStarSFill />
            <RiStarSFill />
            <RiStarSFill />
            <RiStarSFill />
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center bg-[#FFCFAA] text-[#fff] w-[30px] h-[30px] rounded-full">
              <FaThumbsUp />
            </div>
            <h2 className="text-[#202020] text-[17px] font-[700] font-inter">
              Testimonial
            </h2>
          </div>
        </div>

        <div className="py-8 px-5">
          <p className="text-black font-inter text-[16px]">
            By introducing a method to transcribe and translate written
            information in real-time, we significantly improved equity in the
            services we provide to our families. Ultimately, this will lead to a
            more inclusive and enhanced experience for all. 
          </p>
        </div>
        <div className="bg-[var(--doctor-color)] w-full 500px:h-[80px] text-white p-4 500px:text-[18px] text-[16px] 500px:font-[600] font-[500] font-inter">
          <p>
            Director of Innovation and Research at a Rehabilitation Health
            Center
          </p>
        </div>
      </div>
    </>
  );
};

export default SingleCard;
