import React, { useContext, useEffect, useState } from "react";
import Hsidebar from "../../Component/Hospital/Layout/Hsidebar";
import { FaPlus } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { FaFilter } from "react-icons/fa6";
import PatientImage from "../../images/patientImage.png";
import { useNavigate } from "react-router-dom";
import GlobalStorage from "../../Storage/ContextProvider";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { toast } from "react-toastify";
import { getCookie, isAuth } from "../../auth/helper";

export const Departments = () => {
  // const [createDoctor, setCreateDoctor] = useState(false);
  const {
    createDepartment,
    setCreateDepartment,
    hospitalProfile,
    refreshDepartment,
    setRefreshDepartment,
    hospitalDepartment,
    setHospitalDepartment,
    hospitalDoctors,
    setHospitalDoctors,
  } = useContext(GlobalStorage);

  const token = getCookie("token");
  const [values, setValues] = useState({
    assignedAccount: "",
    departmentId: "",
    departmentCode: "",
    departmentName: "",
  });
  const [totalUsedAccount, setTotalUsedAccount] = useState(0);
  const [totalAccounts, setTotalAccounts] = useState(0);

  const [updatedDepartment, setUpdatredDepartment] = useState();
  const { assignedAccount, departmentId, departmentCode, departmentName } =
    values;

  const handleChange = (name) => (event) => {
    // console.log(event.target.value);
    setValues({ ...values, [name]: event.target.value });
  };

  const navigate = useNavigate();
  const handleAddDepartment = async () => {
    if (!assignedAccount) {
      return toast.error("Add Assign Account");
    }
    const data = {
      assignedAccount,
      departmentId,
      departmentCode,
      departmentName,
      hospital: isAuth()?._id,
    };

    const LOGINData = await axios({
      method: "POST",
      url: `${BASE_URL}/hospital/addDepartment`,
      data: data,
    })
      .then((response) => {
        console.log("Hospital register Success", response);
        toast.success(response?.data?.msg);
        setValues({
          ...values,
          assignedAccount: "",
          departmentId: "",
          departmentCode: "",
          departmentName: "",
        });
        setRefreshDepartment(!refreshDepartment);
        setCreateDepartment(false);
        // navigate("/superadmin");

        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Department Register  Error", error.response.data);
        toast.error(error.response.data);
      });
  };

  useEffect(() => {
    let count = 0;
    let count2 = 0;
    let activeDoctorCount = 0;

    const updatedArray = hospitalDepartment?.map((item) => {
      const matchedItem = hospitalDoctors?.find((x) => {
        if (x?.department?._id == item?._id) {
          count++;
          count2++;
        }
        if (x?.department?._id == item?._id && x?.status == "Active") {
          activeDoctorCount++;
        }
      });
      const array = {
        ...item,
        count: count,
        activeDoctorCount: activeDoctorCount,
      };
      count = 0;
      activeDoctorCount = 0;
      setTotalUsedAccount(count2);
      return array;
    });
    setUpdatredDepartment(updatedArray);
  }, [hospitalDoctors, hospitalDepartment]);

  useEffect(() => {
    // const sum = updatedDepartment?.reduce((acc, curr) => acc + curr?.count, 0);
    const sum = hospitalDepartment?.reduce(
      (acc, curr) => acc + curr?.assignedAccount,
      0
    );
    // setTotalUsedAccount(sum);
    setTotalAccounts(sum);
  }, [hospitalDepartment]);

  console.log("Hospital Department", totalAccounts);
  return (
    <Hsidebar activeLink="Departments">
      <div
        className={` ${
          createDepartment
            ? "bg-[rgba(0,0,0,.1)] opacity-[.4] overflow-hidden h-[88vh]"
            : ""
        } p-[20px]`}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-[20px] ">
            <h1 className="text-[25px] font-[500]">Departments</h1>
            <button className="bg-[#67A599] rounded-full w-[220px] h-[45px]">
              Total Accounts:{totalAccounts}
            </button>
            <button className="bg-[#67A599] rounded-full w-[220px] h-[45px]">
              Used Accounts:{totalUsedAccount}
            </button>
          </div>
          <button
            className="rounded-full w-[162px] h-[33px] bg-[#FFCFAA] flex items-center justify-center text-[var(--doctor-color)]"
            onClick={() => setCreateDepartment(true)}
          >
            <FaPlus /> New Department
          </button>
        </div>
        <div className="flex w-[100%] gap-[25px] my-[15px]">
          <div className="flex items-center h-[45px] w-[85%] bg-[#67A599]  rounded-full relative text-[#fff]  ">
            <CiSearch className="absolute left-[10px]" />
            <input
              className="bg-transparent w-full outline-none ml-[35px] mr-[10px] placeholder-white"
              type="text"
              placeholder="Search Department"
            />
          </div>
          <div className="w-[15%]">
            <button className=" rounded-full flex items-center justify-center bg-[var(--dark-secondary)] h-[46px] w-full text-[#fff]">
              <FaFilter /> Filter
            </button>
          </div>
        </div>

        {/* <div className="relative overflow-x-auto rounded-full"> */}

        <table className="w-full text-left rtl:text-right ">
          <thead className="dark:bg-[var(--dark-primary)] bg-[#67A599] text-[#CCE1DD] text-[18px] font-[400] rounded-full">
            <tr className="">
              <th scope="col" className="p-4 rounded-l-full">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="tableInput"
                  />
                </div>
              </th>
              <th scope="col" className="px-4 font-[500]">
                ID
              </th>
              <th scope="col" className="px-4 py-3 font-[500]">
                Department
              </th>

              <th scope="col" className="px-4 py-3 font-[500]">
                Code
              </th>
              <th scope="col" className="px-4 py-3 font-[500]">
                Active Doctors
              </th>
              <th scope="col" className="px-4 py-3 font-[500] ">
                Total Accounts
              </th>
              <th scope="col" className="px-4 py-3 font-[500]">
                Used Accounts
              </th>
              <th scope="col" className="px-4 py-3 rounded-r-full"></th>
            </tr>
          </thead>
          <tbody className="mt-[60px]">
            {updatedDepartment?.map((data, index) => {
              return (
                <>
                  <tr
                    key={index}
                    className="border-b-[1px] border-[rgba(119,119,119,.2)] h-[95px] text-[17px]"
                  >
                    <td className="w-4 p-4">
                      <div className="flex items-center">
                        <input
                          id="checkbox-all-search"
                          type="checkbox"
                          className="tableInput"
                        />
                      </div>
                    </td>
                    <td className="px-4">{data?.departmentId}</td>
                    <td className="px-4 ">{data?.departmentName}</td>
                    <td className="px-4 ">{data?.departmentCode}</td>
                    <td className="px-4 ">{data?.activeDoctorCount}</td>
                    <td className="px-4 ">{data?.assignedAccount}</td>
                    <td className="px-4 ">{data?.count}</td>
                    <td className="flex items-center h-full justify-center px-6 py-4 gap-[20px] mt-[12px]">
                      <button
                        className="w-[122px] h-[39px] bg-[#FFCFAA] text-[var(--doctor-color)] rounded-full text-[18px font-[500]]"
                        onClick={() =>
                          navigate(`/hospital/department/${data?._id}`)
                        }
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* </div> */}

      {createDepartment && (
        <>
          <div className="absolute top-[150px]  left-[36%] w-[45%]  ">
            <button className="w-[100%] bg-[#67A599] text-[#fff] dark:bg-[var(--doctor-color)] h-[50px] rounded-[20px] font-[500] text-[20px]">
              Create New Profile
            </button>
            <div className="mt-[15px] bg-[#CCE1DD] rounded-[20px]  px-[20px] py-[35px] ">
              <input
                type="text"
                className="w-full h-[45px] my-[20px] text-[16px] rounded-[11px] outline-none bg-transparent border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                placeholder="Department Name"
                onChange={handleChange("departmentName")}
                value={departmentName}
              />

              <input
                type="text"
                className="w-full h-[45px] my-[20px] text-[16px] rounded-[11px] outline-none bg-transparent border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                placeholder="Department Code"
                onChange={handleChange("departmentCode")}
                value={departmentCode}
              />
              <div className="flex items-center gap-[15px]">
                <input
                  type="text"
                  className="w-[50%] h-[45px] text-[16px] rounded-[11px] outline-none bg-transparent border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                  placeholder="Department ID"
                  onChange={handleChange("departmentId")}
                  value={departmentId}
                />
                <button className="bg-[#67A599] text-[16px] w-[185px] h-[50px] rounded-[11px]">
                  Assigned Accounts
                </button>

                <input
                  type="number"
                  className="w-[90px] h-[45px] outline-none bg-transparent rounded-[8px]  text-[16px]  border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                  onChange={handleChange("assignedAccount")}
                  value={assignedAccount}
                />
              </div>

              <div className=" mt-[30px] flex items-center gap-[20px] font-[600] font-inter text-[21px] text-[#fff]">
                <button
                  className="w-[70%] h-[50px] rounded-[37px] bg-[#DDBEA6]"
                  onClick={handleAddDepartment}
                >
                  Create Profile
                </button>
                <button
                  className="w-[30%] h-[50px] rounded-[37px] bg-[#FF0000]"
                  onClick={() => setCreateDepartment(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Hsidebar>
  );
};
