import React, { useContext, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import GlobalStorage from "../../Storage/ContextProvider";
import { toast } from "react-toastify";
import axios from "axios";
import BASE_URL from "../../baseUrl";

const RequestDemo = () => {
    const {isRequestDemo, setRequestDemo} = useContext(GlobalStorage);
    const [hName, setHName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const handleConfirmRequest = async () => {
      if(!hName) {
        return toast.error("Please Enter the Hospital Name")
      }
      if(!email) {
        return toast.error("Please Enter the Email Address")
      }
      const data = {
        hpName: hName,
        emailId: email,
        phoneNumber: phone
      };

      const requestDemo = await axios({
        method: "POST",
        url: `${BASE_URL}/requestdemoform`,
        data: data,
      })
        .then((response) => {
          console.log("Hospital Update] Success", response);
          toast.success(response?.data);
          setRequestDemo(false);
        

        })
        .catch((error) => {
          console.log("Hospital Update  Error", error.response.data);
          toast.error(error?.response?.data?.message);
        });
    }


  return (
    <>
      <div className="flex justify-between items-start">
        <button
          className="text-[#101828] px-[22px] py-[10px] border-[#E4E7EC] border-[1px] rounded-lg text-[17px] font-inter font-[600]"
          style={{ boxShadow: " 0px 1px 2px 0px #1018280D" }}
        >
          Request a Demo
        </button>
        <RxCross2
          className="text-[#667085] cursor-pointer text-[24px]"
          onClick={() => setRequestDemo(false)}
        />
      </div>

      <p className="text-[#475467] text-sm mt-6">
        Fill in the form to get started
      </p>
      <div className="font-inter font-[500] text-[#344054] text-sm mt-9">
        <div className="flex flex-col gap-2 my-4">
          <label>Hospital Name *</label>
          <input
            type="text"
            className="outline-none border-[1px] border-[#D0D5DD] px-[14px] py-[10px] w-full rounded-lg"
            placeholder="Hospital Name"
            value={hName}
            onChange={(e) => setHName(e.target.value)}
          />
        </div>

        <div className=" my-4 flex items-center w-full gap-5">
          <div className="flex flex-col gap-2 w-[50%]">
            <label>Email Address*</label>
            <input
              type="text"
              className="outline-none border-[1px] border-[#D0D5DD] px-[14px] py-[10px] w-full rounded-lg"
              placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-2 w-[50%]">
            <label>Phone Number</label>
            <input
              type="text"
              className="outline-none border-[1px] border-[#D0D5DD] px-[14px] py-[10px] w-full rounded-lg"
              placeholder="Phone Number "
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center gap-5 my-7">
        <button
          className="w-[50%] text-[#101828]  py-[10px] border-[#E4E7EC] border-[1px] rounded-lg text-[17px] font-inter font-[600]"
          onClick={() => setRequestDemo(false)}
        >
          Cancel
        </button>

        <button
          className="w-[50%] text-[#fff] bg-[var(--doctor-color)]  py-[10px] border-[#E4E7EC] border-[1px] rounded-lg text-[17px] font-inter font-[600]"
            onClick={handleConfirmRequest}
        >
          Confirm
        </button>
      </div>
    </>
  );
};

export default RequestDemo;
