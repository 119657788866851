import React from "react";
import DoctorSidebar from "../../../Component/Doctor/Layout/DoctorSidebar";
import Upload from "../../../images/upload.png";

const General = () => {
  return (
    <>
      <DoctorSidebar
      activeLink="General"
      >
        <div className="p-[20px] text-[#052721] dark:text-[#fff]">
          <div className="flex items-center justify-between border-b border-[var(--doctor-color)] pb-[15px]">
            <div>
              <h2 className="font-[500] text-[25px]">General</h2>
              <p className="font-[300] text-[16px] text-[var(--doctor-color)] dark:text-[#fff]">
                Your Profile
              </p>
            </div>
            <div className="w-[75px] h-[75px] bg-[#CCE1DD] rounded-full flex justify-center items-center">
              <p className="text-[34px] text-[var(--doctor-color)]">KA</p>
            </div>
          </div>
          <div className="pt-[15px] pb-[30px] border-dashed border-b border-[#016855] border-spacing-11">
            <h2 className="text-[20px] font-[500]">
              Profile Information
            </h2>
            <div className="py-[15px] flex gap-[20px] item-center">
              <img src={Upload} className="w-[130px] h-[133px]" alt="" />
              <div className="w-[100%] flex gap-[15px] items-center">
                <div className="w-[50%]">
                  <input
                    className="px-[15px] first-letter:text-[15px] text-[#67A599] rounded-md w-full border-[2px] border-[#CCE1DD] h-[50px] placeholder-[#67A599] bg-transparent outline-none my-[10px]"
                    type="text"
                    placeholder="First Name"
                  />
                  <input
                    className="px-[15px] first-letter:text-[15px] text-[#67A599] rounded-md w-full border-[2px] border-[#CCE1DD] h-[50px] placeholder-[#67A599] bg-transparent outline-none my-[10px]"
                    type="text"
                    placeholder="Phone Number"
                  />
                </div>
                <div className="w-[50%]">
                  <input
                    className="px-[15px] first-letter:text-[15px] text-[#67A599] rounded-md w-full border-[2px] border-[#CCE1DD] h-[50px] placeholder-[#67A599] bg-transparent outline-none my-[10px]"
                    type="text"
                    placeholder="Last Name"
                  />
                  <input
                    className="px-[15px] first-letter:text-[15px] text-[#67A599] rounded-md w-full border-[2px] border-[#CCE1DD] h-[50px] placeholder-[#67A599] bg-transparent outline-none my-[10px]"
                    type="text"
                    placeholder="Work Email Address"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button className="w-[166px] h-[39px] bg-[#FFCFAA] text-[16px] text-[var(--doctor-color)] font-[300] rounded-lg">
                Save
              </button>
            </div>
          </div>

          <div className="py-[15px]">
            <h2 className=" text-[20px] font-[500]">
              Language and Localization
            </h2>

            <div>
              <div className="flex gap-[30px] item-center justify-between w-[100%] my-[20px]">
                <label for="time" className="inline text-[20px]  pt-[7px]">
                  Time Zone
                </label>
                <select
                  id="time"
                  className=" caret-slate-700 outline-none bg-[#CCE1DD] h-[42px]  px-[20px] dark:bg-[var(--dark-primary)] text-[16px] w-[85%] rounded-md"
                >
                  <option selected>
                    (UTC - 05:00, EST) Eastern Standard Time
                  </option>
                  <option value="US">United States</option>
                  <option value="CA">Canada</option>
                  <option value="FR">France</option>
                  <option value="DE">Germany</option>
                </select>
              </div>

              <div className="flex gap-[30px] item-center justify-between w-[100%] my-[20px]">
                <label for="time" className="inline text-[20px]  pt-[7px]">
                  Languages
                </label>
                <select
                  id="time"
                  className=" caret-slate-700 outline-none bg-[#CCE1DD] dark:bg-[var(--dark-primary)] h-[42px]  px-[20px]  text-[16px] w-[85%] rounded-md"
                >
                  <option selected>
                   English(CA)
                  </option>
                  <option value="US">United States</option>
                  <option value="CA">Canada</option>
                  <option value="FR">France</option>
                  <option value="DE">Germany</option>
                </select>
              </div>
              <div className="flex justify-end">
              <button className="w-[166px] h-[39px] bg-[#FFCFAA] text-[16px] text-[var(--doctor-color)] font-[300] rounded-lg">
                Save
              </button>
            </div>

            </div>
          </div>
        </div>
      </DoctorSidebar>
    </>
  );
};

export default General;
