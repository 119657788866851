import React from "react";
import DoctorSidebar from "../../../Component/Doctor/Layout/DoctorSidebar";
import Hsidebar from "../../../Component/Hospital/Layout/Hsidebar";

const Privacy = () => {
  return (
    <Hsidebar activeLink="Privacy & Security">
      <div className="p-[20px] dark:text-[#fff] text-[#052721]">
        <div className=" border-b border-[var(--doctor-color)] pb-[15px]">
          <div>
            <h2 className="font-[500] text-[25px]">Privacy & Security</h2>
            {/* <p className="font-[300] text-[16px] text-[var(--doctor-color)]">
              Customization settings
            </p> */}
          </div>
        </div>
        <div className="mt-[35px]">
          <div className="flex gap-[50px] items-center">
            <h3 className="text-[20px]">Privacy</h3>
            {/* <p className="text-[16px] font-[300] text-[#FFCFAA]">
              Click to read more in detail
            </p> */}
          </div>
          <div>
            <p className="text-[16px] font-[300] mt-[20px]">
              Our privacy policy was last updated on [05/24/23]. MedInclude is
              committed to protecting the privacy of our users and maintaining
              the confidentiality of the information you provide to us. This
              Privacy Policy outlines the types of information we collect from
              users of the platform, how we use and protect that information,
              and your rights regarding your personal data. By accessing or
              using our Platform, you acknowledge that you have read,
              understood, and agree to be bound by this Privacy Policy. If you
              do not agree with any part of this Privacy Policy, please do not
              use our Platform.
            </p>
            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">
                1. Collection of Information
              </h2>
              <div className="ml-4 mt-2">
                <h3 className="font-[400]">1.1 Personal Information</h3>
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  We may collect personal information that you voluntarily
                  provide to us when using our Platform. This includes
                  information such as your name, email address, contact details,
                  date of birth, gender, and any other information you choose to
                  provide. We may also collect personal information from
                  third-party sources, such as healthcare providers, insurance
                  companies, and other organizations
                </p>
              </div>
              <div className="ml-4 mt-2">
                <h3 className="font-[400]">1.2 Health Information</h3>
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  In order to provide you with the services offered through our
                  Platform, we may collect and process certain health-related
                  information, which may include medical history, health
                  conditions, medications, and other relevant health data. We
                  will only collect such information with your explicit consent
                  and in accordance with applicable laws and regulations.
                </p>
              </div>
              <div className="ml-4 mt-2">
                <h3 className="font-[400]">1.3 Usage Information</h3>
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  When you access or use our Platform, we may automatically
                  collect certain information about your usage patterns, device
                  information, IP address, browser type, and other technical
                  information.
                </p>
              </div>
            </div>

            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">
                2. Use of Information
              </h2>
              <div className="ml-4 mt-2">
                <h3 className="font-[400]">2.1 Provide Services</h3>
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  We may use the information we collect to provide you with our
                  services, personalize your experience, and improve the
                  functionality of the Platform. We may also use it to respond
                  to your inquiries and requests.
                </p>
              </div>
              <div className="ml-4 mt-2">
                <h3 className="font-[400]">2.2 Communication</h3>
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  We may use your contact information to communicate with you,
                  respond to your inquiries, provide customer support, and send
                  you important updates or promotional materials related to our
                  services. You can opt-out of receiving marketing
                  communications at any time.
                </p>
              </div>
              <div className="ml-4 mt-2">
                <h3 className="font-[400]">2.3 Aggregated Data</h3>
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  We may use aggregated and anonymized data for statistical and
                  analytical purposes to improve our services, conduct research,
                  and generate reports.
                </p>
              </div>
              <div className="ml-4 mt-2">
                <h3 className="font-[400]">2.4 Legal Compliance</h3>
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  We may use your information to comply with applicable laws,
                  regulations, or legal processes, or to enforce our rights or
                  defend against legal claims.
                </p>
              </div>
            </div>

            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">
                3. Data Sharing and Disclosure
              </h2>
              <div className="ml-4 mt-2">
                <h3 className="font-[400]">3.1 Service Providers</h3>
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  We may share your personal information with trusted
                  third-party service providers who assist us in operating our
                  Platform, conducting business activities, or providing
                  services on our behalf. These service providers are
                  contractually obligated to protect your information and use it
                  solely for the purposes specified by us.
                </p>
              </div>
              <div className="ml-4 mt-2">
                <h3 className="font-[400]">3.2 Legal Requirements</h3>
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  We may disclose your information if required to do so by law,
                  court order, or government request. We may also disclose your
                  information to protect our rights, property, or safety, or the
                  rights, property, or safety of others.
                </p>
              </div>
              <div className="ml-4 mt-2">
                <h3 className="font-[400]">3.3 Business Transfers</h3>
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  In the event of a merger, acquisition, or any form of sale or
                  transfer of some or all of our assets, we may disclose your
                  information to the acquiring party as part of the due
                  diligence process or the transferred assets.
                </p>
              </div>
              <div className="ml-4 mt-2">
                <h3 className="font-[400]">3.4 Research</h3>
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  With your consent, we may share de-identified and aggregated
                  data to researchers who are studying health-related topics.
                  This data, which does not contain personally identifiable
                  information, helps researchers gain valuable insights into
                  various health conditions, treatments, and outcomes. All
                  shared data is handled securely, and strict confidentiality
                  measures are in place to safeguard your information.
                </p>
              </div>
            </div>
            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">
                4.Data Security
              </h2>

              <div className="ml-4 mt-2">
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  We implement appropriate technical and organizational measures
                  to protect your personal information from unauthorized access,
                  disclosure, alteration, or destruction. However, no method of
                  transmission over the internet or electronic storage is 100%
                  secure, and we cannot guarantee the absolute security of your
                  information
                </p>
              </div>
            </div>
            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">
                5. Retention of Information
              </h2>

              <div className="ml-4 mt-2">
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  We retain personal information for as long as necessary to
                  fulfill the purposes for which it was collected, or as
                  required by law. When personal information is no longer
                  required, we securely destroy or delete it.
                </p>
              </div>
            </div>
            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">
                6. Changes to this Privacy Policy
              </h2>

              <div className="ml-4 mt-2">
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  We may update this privacy policy from time to time by posting
                  a new version on our website. We encourage you to check our
                  website periodically for updates. We will also share an email
                  alerting you to any update to our privacy policy.
                </p>
              </div>
            </div>
            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">
                7. Your Rights
              </h2>

              <div className="ml-4 mt-2">
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  You have the right to access, update, correct, or delete your
                  personal information held by us. You may also have the right
                  to restrict or object to the processing of your information,
                  and to request the transfer of your data to another party. To
                  exercise your rights or for any privacy-related inquiries,
                  please contact us.
                </p>
              </div>
            </div>
            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">
                8. Contact Us
              </h2>

              <div className="ml-4 mt-2">
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  If you have any questions or concerns about this privacy
                  policy or our handling of personal information, please contact
                  us at gbola@medinclude.com.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="mt-[35px]">
          <div className="flex gap-[50px] items-center">
            <h3 className="text-[20px]">Security</h3>
            <p className="text-[16px] font-[300] text-[#FFCFAA]">
              Click to read more in detail
            </p>
          </div>
          <p className="text-[16px] font-[300] mt-[20px]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
            fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
            vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor
            ornare leo, non suscipit magna interdum eu. Curabitur pellentesque
            nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo
            lacus at sodales sodales. Quisque sagittis orci ut diam condimentum,
            vel euismod erat placerat. In iaculis arcu eros, eget tempus orci
            facilisis id. Praesent lorem orci, mattis non efficitur id,
            ultricies vel nibh. Sed volutpat lacus vitae gravida viverra. Fusce
            vel tempor elit. Proin tempus, magna id scelerisque v
          </p>
        </div> */}
      </div>
      <div className="p-[20px] dark:text-[#fff] text-[#052721]">
        <div className="mt-[35px]">
          <div className="flex gap-[50px] items-center">
            <h3 className="text-[20px]">Terms of Use</h3>
            {/* <p className="text-[16px] font-[300] text-[#FFCFAA]">
              Click to read more in detail
            </p> */}
          </div>
          <div>
            <p className="text-[16px] font-[300] mt-[20px]">
              Please read these Terms of Use carefully before using our platform
              and services. By accessing or using our platform, you agree to be
              bound by these Terms of Use. If you do not agree with any part of
              these terms, please refrain from using our platform.
            </p>
            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">
                1. Introduction
              </h2>
              <div className="ml-4 mt-2">
                {/* <h3 className="font-[400]">1.1 Personal Information</h3> */}
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  1.1 MedInclude operates an online platform that helps patients
                  better understand medical information by making it available
                  in simple terms and multiple languages.
                </p>
              </div>
              <div className="ml-4 mt-2">
                {/* <h3 className="font-[400]">1.2 Health Information</h3> */}
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  1.2 By using our platform, you acknowledge that MedInclude is
                  not a healthcare provider and does not provide medical advice.
                  Our platform is intended to help transcribe medical
                  information from your healthcare provider and should not
                  substitute professional medical advice, diagnosis, or
                  treatment. Always consult a qualified healthcare professional
                  for any medical concerns or questions.
                </p>
              </div>
            </div>

            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">
                2. User Eligibility and Account
              </h2>
              <div className="ml-4 mt-2">
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  2.1 To use our platform, you must be at least 18 years old and
                  have the legal capacity to enter into a binding agreement. By
                  creating an account, you represent and warrant that you meet
                  these requirements.
                </p>
              </div>
              <div className="ml-4 mt-2">
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  2.2 You are responsible for maintaining the confidentiality of
                  your account credentials, including your username and
                  password. Any activity that occurs under your account is your
                  responsibility. If you become aware of any unauthorized use of
                  your account, please notify us immediately
                </p>
              </div>
            </div>

            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">
                3. User Obligations
              </h2>
              <div className="ml-4 mt-2">
                <h3 className="font-[400]">
                  3.1 You agree to use our platform for lawful purposes and in
                  accordance with these Terms of Use. You shall not:
                </h3>
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  a) Violate any applicable laws, regulations, or third-party
                  rights. <br />
                  b) Engage in any fraudulent, abusive, or deceptive behavior.{" "}
                  <br />
                  c) Use our platform to transmit any viruses, malware, or
                  harmful content. <br />
                  d) Interfere with the proper functioning of our platform or
                  circumvent any security measures. <br />
                  e) Collect or harvest any user data without our consent.
                </p>
              </div>
              <div className="ml-4 mt-2">
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  3.2 You are solely responsible for the accuracy and
                  completeness of any information you provide on our platform.
                  You agree to update your information promptly if there are any
                  changes.
                </p>
              </div>
            </div>
            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">
                4.Intellectual Property Rights
              </h2>

              <div className="ml-4 mt-2">
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  4.1 Our platform and its contents, including but not limited
                  to text, graphics, logos, images, videos, and software, are
                  owned or licensed by MedInclude and are protected by
                  intellectual property laws. You may not use, reproduce,
                  modify, or distribute any part of our platform without our
                  prior written consent.
                </p>
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  4.2 By using our platform, you grant MedInclude a worldwide,
                  non-exclusive, royalty-free license to use, reproduce,
                  distribute, and display any content you submit through the
                  platform for the purpose of providing our services. You
                  represent and warrant that you have the necessary rights to
                  grant this license.
                </p>
              </div>
            </div>
            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">
                5. Third-Party Links and Content
              </h2>

              <div className="ml-4 mt-2">
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  5.1 Our platform may contain links to third-party websites or
                  resources. These links are provided for your convenience only,
                  and we do not endorse or control the content of any linked
                  websites. Your interactions with any third-party websites are
                  at your own risk, and you should review their terms of use and
                  privacy policies.
                </p>
              </div>
            </div>
            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">6. Privacy</h2>

              <div className="ml-4 mt-2">
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  6.1 Our collection and use of your personal information are
                  governed by our Privacy Policy, which forms an integral part
                  of these Terms of Use. By using our platform, you consent to
                  the collection, use, and disclosure of your personal
                  information as described in our Privacy Policy.
                </p>
              </div>
            </div>
            <div className="ml-5 mt-5">
              <h2 className="font-[600] text-[17px] font-inter ">
                7. Disclaimer of Warranties
              </h2>

              <div className="ml-4 mt-2">
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  7.1 We strive to provide accurate and up-to-date information
                  on our platform. However, we do not warrant the completeness,
                  reliability, or accuracy of any information provided. Your use
                  of our platform is at your own risk.
                </p>
                <p className="text-[16px] ml-2 font-[300] mt-[5px]">
                  7.2 MedInclude disclaims all warranties, whether express,
                  implied, or statutory, including but not limited to
                  warranties.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="mt-[35px]">
          <div className="flex gap-[50px] items-center">
            <h3 className="text-[20px]">Security</h3>
            <p className="text-[16px] font-[300] text-[#FFCFAA]">
              Click to read more in detail
            </p>
          </div>
          <p className="text-[16px] font-[300] mt-[20px]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
            fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
            vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor
            ornare leo, non suscipit magna interdum eu. Curabitur pellentesque
            nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo
            lacus at sodales sodales. Quisque sagittis orci ut diam condimentum,
            vel euismod erat placerat. In iaculis arcu eros, eget tempus orci
            facilisis id. Praesent lorem orci, mattis non efficitur id,
            ultricies vel nibh. Sed volutpat lacus vitae gravida viverra. Fusce
            vel tempor elit. Proin tempus, magna id scelerisque v
          </p>
        </div> */}
      </div>
    </Hsidebar>
  );
};

export default Privacy;
