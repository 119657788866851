import React, { useContext } from "react";
import Customize from "../../Component/Hospital/Onboard/Customize";
import GlobalStorage from "../../Storage/ContextProvider";
import Migrate from "../../Component/Hospital/Onboard/Migrate";
import ImportAccount from "../../Component/Hospital/Onboard/ImportAccount";
import FinalOnboard from "../../Component/Hospital/Onboard/FinalOnboard";

const Onboarding = () => {
  const { onBoard, setOnBoard } = useContext(GlobalStorage);
  return (
    <>
      <div className="bg-[#207A69] min-h-[100vh] p-[20px]">
        {onBoard === 3 ? (
          <FinalOnboard />
        ) : (
          <>
            <div className="bg-[#CCE1DD] w-full h-[94.3vh] rounded-xl flex items-center justify-center flex-col">
              {onBoard === 0 && <Customize />}
              {onBoard === 1 && <Migrate />}
              {onBoard === 2 && <ImportAccount />}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Onboarding;
