import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { isAuth } from "./helper";
const PatientPrivateRoute = () => {
  return isAuth() && isAuth()?.role === "patient" ? (
    <Outlet />
  ) : (
    <Navigate to="/patient/signin" />
  );
};

export default PatientPrivateRoute;
