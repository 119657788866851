import React, { useContext, useRef } from "react";
import Logo from "../../../images/logo.png";
import { BiImport } from "react-icons/bi";
import { FaChevronLeft } from "react-icons/fa";
import GlobalStorage from "../../../Storage/ContextProvider";
import { useNavigate } from "react-router-dom";
// import { read, utils, writeFile } from "xlsx";

import * as XLSX from "xlsx";
const Migrate = () => {
  const { onBoard, setOnBoard } = useContext(GlobalStorage);
  const navigate = useNavigate();
  const { excelFileData, setExcelData } = useContext(GlobalStorage);
  // const handleFileImport = async (e) => {
  //   var files = e.target.files,
  //     f = files[0];
  //   var reader = new FileReader();
  //   reader.onload = function (e) {
  //     var data = e.target.result;
  //     let readedData = XLSX.read(data, { type: "binary" });
  //     const wsname = readedData.SheetNames[0];
  //     const ws = readedData.Sheets[wsname];

  //     /* Convert array to json*/
  //     const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
  //     const cleanedArray = dataParse.filter((subArray) => subArray.length > 0);
  //     console.log("Clraned Array", cleanedArray);
  //    cleanedArray.forEach(array => array.shift())

  //     setExcelData(cleanedArray);
  //   };
  //   reader.readAsBinaryString(f);
  // };
  const fileInputRef = useRef(null);

  const handleFileImport = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const cleanedArray = jsonData.filter((subArray) => subArray.length > 0);
      const finalArray = cleanedArray.shift();
      setExcelData(cleanedArray);
      setOnBoard(2)
    };

    reader.readAsArrayBuffer(file);
  };
  console.log("Data", excelFileData);
  const handleClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <div className="absolute top-[50px] left-[30px]">
        <div
          className="flex items-center font-[500] text-[18px] cursor-pointer gap-[1px] text-[var(--doctor-color)]"
          onClick={() => setOnBoard(0)}
        >
          <FaChevronLeft />
          Back
        </div>
      </div>
      <img src={Logo} className="w-[250px] h-[100px]" alt="" />
      <div className="my-[30px]">
        <h2 className="text-[40px] text-[var(--doctor-color)] text-center ">
          Migrate Staff Profiles
        </h2>
        <p className="text-[20px] text-[#000000] text-center">
          Let’s get you started on the go by uploading your hospital’s <br />
          medical staff profiles
        </p>
      </div>
      <div>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onInput={(e) => handleFileImport(e)}
        />
        <button
          className="flex my-[50px] text-[16px] text-[#26203B] gap-[6px] items-center justify-center w-[530px] h-[45px] rounded-[8px] border-[1px] border-[rgba(70,95,241,.4)] bg-[#fff]"
          // onClick={() => setOnBoard(2)}
          onClick={handleClick}
        >
          <BiImport size={20} /> Import
        </button>
      </div>

      <button
        className="bg-[#FFCFAA] w-[200px] h-[45px] rounded-[8px] text-[#000000] text-[15px] font-[500] my-[50px]"
        onClick={() => navigate("/hospital")}
      >
        Skip Import Process
      </button>

      <div className="flex gap-[20px] mt-[40px]">
        <div className="w-[13px] h-[13px] opacity-[.5] rounded-full bg-[var(--doctor-color)]"></div>

        <div className="w-[67px] h-[13px] rounded-full bg-[var(--doctor-color)]"></div>
      </div>
    </>
  );
};

export default Migrate;
