import React, { useEffect, useRef, useState } from "react";
import Hsidebar from "../../../Component/Hospital/Layout/Hsidebar";
import { IoIosMore } from "react-icons/io";
import { BiCopy } from "react-icons/bi";

import { BsArrowRight } from "react-icons/bs";

import MasterCard from "../../../images/hospital/card.png";
const ManageSubscription = () => {
  const [handleOpen, setHandleOpen] = useState(false);
  const [handleOpen2, setHandleOpen2] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [editAdd, setEditAdd] = useState();
  const [editAdd2, setEditAdd2] = useState();

  const wrapperRef = useRef(null);
  const wrapperRef2 = useRef(null);
  useOutsideAlerter(wrapperRef);
  useOutsideAlerter2(wrapperRef2);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setHandleOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  function useOutsideAlerter2(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setHandleOpen2(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleAddCard = () => {
    setAddCard(true);
    setShowPopup(true);
  };
  const handleCloseAddCard = () => {
    setShowPopup(false);
    setAddCard(false);
  };
  const handleEditAddress = () => {
    setEditAdd(true);
    setShowPopup(true);
  };
  const handleCloseEditADD = () => {
    setShowPopup(false);
    setEditAdd(false);
  };

  return (
    <Hsidebar activeLink="Subscriptions">
      <div
        className={`${
          showPopup
            ? "bg-[rgba(0,0,0,.1)] dark:opacity-[.4] overflow-hidden h-[85vh]"
            : ""
        } p-[20px] text-[#fff]`}
      >
        <div className="flex justify-between items-center">
          <h2 className="text-[25px] font-[500] text-[#000]">
            Manage Subscription
          </h2>
          <button className="w-[135px] h-[47px] rounded-full bg-[#FFCFAA] text-[var(--doctor-color)] text-[14px] font-inter font-[700]">
            Save
          </button>
        </div>
        <div className="w-full h-[300px] border-[1px] border-[#E4E7E9] rounded-[15px] mt-[20px]">
          <div className="h-[50px] bg-[#fff] rounded-t-[15px] flex items-center justify-between text-[#191C1F] px-[15px] font-[500]">
            <p>PAYMENT OPTION</p>
            <div
              className="flex items-center gap-[7px] cursor-pointer"
              onClick={handleAddCard}
            >
              <p>Add Card</p>
              <BsArrowRight />
            </div>
          </div>
          <div className="bg-[var(--dark-primary)] px-[20px] h-[248px] rounded-b-[15px] flex items-center flex-wrap gap-[20px]">
            <div
              className="h-[200px] w-[460px] p-[20px] rounded-[4px] relative"
              style={{
                background:
                  "radial-gradient(98.14% 214.97% at 0% 0%, #14486A 0%, #1B6290 100%)",
              }}
            >
              <div className="flex items-center justify-between">
                <p className="text-[16px] font-inter font-[500]">
                  $87, 583.00 USD
                </p>
                <IoIosMore
                  className="text-[#fff] opacity-[.5]"
                  size={21}
                  onClick={() => setHandleOpen(true)}
                />
              </div>
              <div className="mt-[20px] leading-[16px]">
                <p className="text-[11px] opacity-[.7] font-[500] font-inter">
                  CARD NUMBER
                </p>
                <div className="flex items-center gap-[10px] mt-[7px]">
                  <p>**** **** **** 1761</p>
                  <BiCopy />
                </div>
                <img
                  src={MasterCard}
                  className="w-[40px] h-[40px] mt-[15px]"
                  alt=""
                />
                <div className="flex justify-end">
                  <p className="font-inter font-[500] text-[14px]">
                    Kevin Gilbert
                  </p>
                </div>
              </div>

              {handleOpen && (
                <>
                  <div
                    ref={wrapperRef}
                    className="z-[101] p-[10px] text-sm absolute top-[20px] -right-[35px] w-[130px] h-[80px]v text-[#5F6C72] bg-[#fff] rounded-sm"
                  >
                    <p className="mb-[10px]">Edit Card</p>
                    <p>Delete Card</p>
                  </div>
                </>
              )}
            </div>
            <div
              className="h-[200px] w-[460px] p-[20px] rounded-[4px] relative"
              style={{
                background:
                  "radial-gradient(98.14% 214.97% at 0% 0%, #248E1D 0%, #2DB324 100%) ",
              }}
            >
              <div className="flex items-center justify-between">
                <p className="text-[16px] font-inter font-[500]">
                  $87, 583.00 USD
                </p>
                <IoIosMore
                  className="text-[#fff] opacity-[.5]"
                  size={21}
                  onClick={() => setHandleOpen2(true)}
                />
              </div>
              <div className="mt-[20px] leading-[16px]">
                <p className="text-[11px] opacity-[.7] font-[500] font-inter">
                  CARD NUMBER
                </p>
                <div className="flex items-center gap-[10px] mt-[7px]">
                  <p>**** **** **** 1761</p>
                  <BiCopy />
                </div>
                <img
                  src={MasterCard}
                  className="w-[40px] h-[40px] mt-[15px]"
                  alt=""
                />
                <div className="flex justify-end">
                  <p className="font-inter font-[500] text-[14px]">
                    Kevin Gilbert
                  </p>
                </div>
              </div>
              {handleOpen2 && (
                <>
                  <div
                    ref={wrapperRef2}
                    className="p-[10px] text-sm absolute top-[20px] -right-[35px] w-[130px] h-[80px]v text-[#5F6C72] bg-[#fff] rounded-sm"
                  >
                    <p className="mb-[10px]">Edit Card</p>
                    <p>Delete Card</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex w-full my-[25px] items-center gap-[20px]">
          <div className="w-[50%] border-[1px] border-[#E4E7E9] rounded-[15px]">
            <div className="w-full h-[50px] rounded-t-[15px] bg-[#CCE1DD] flex items-center px-[20px] text-[#191C1F] text-[14px] font-inter font-[500]">
              BILLING ADDRESS
            </div>
            <div className="w-full h-[250px] bg-[var(--dark-primary)] rounded-b-[15px] p-[20px] ">
              <div className="w-[70%]">
                <h3 className="text-[16px] font-inter font-[500]">
                  Kevin Gilbert
                </h3>
                <p className="text-[#C2C2C2] font-inter text-[14px] mt-[15px] ">
                  East Tejturi Bazar, Word No. 04, Road No. 13/x, House no.
                  1320/C, Flat No. 5D, Dhaka - 1200, Bangladesh
                </p>
                <div className="my-[10px]">
                  <p className="text-[14px] font-inter">
                    Phone Number:{" "}
                    <span className="text-[#C2C2C2]">+1-202-555-0118</span>
                  </p>
                </div>

                <div className="my-[10px]">
                  <p className="text-[14px] font-inter">
                    Email:{" "}
                    <span className="text-[#C2C2C2]">
                      kevin.gilbert@gmail.com
                    </span>
                  </p>
                </div>
                <button
                  className="mt-3 w-36 h-11 bg-[#FFCFAA] text-[var(--dark-primary)] text-sm font-inter font-[500] rounded-full"
                  onClick={handleEditAddress}
                >
                  EDIT ADDRESS
                </button>
              </div>
            </div>
          </div>
          <div className="w-[50%] border-[1px] border-[#E4E7E9] rounded-[15px]">
            <div className="w-full h-[50px] rounded-t-[15px] bg-[#CCE1DD] flex items-center px-[20px] text-[#191C1F] text-[14px] font-inter font-[500]">
              BILLING ADDRESS
            </div>
            <div className="w-full h-[250px] bg-[var(--dark-primary)] rounded-b-[15px] p-[20px] ">
              <div className="w-[70%]">
                <h3 className="text-[16px] font-inter font-[500]">
                  Kevin Gilbert
                </h3>
                <p className="text-[#C2C2C2] font-inter text-[14px] mt-[15px] ">
                  East Tejturi Bazar, Word No. 04, Road No. 13/x, House no.
                  1320/C, Flat No. 5D, Dhaka - 1200, Bangladesh
                </p>
                <div className="my-[10px]">
                  <p className="text-[14px] font-inter">
                    Phone Number:{" "}
                    <span className="text-[#C2C2C2]">+1-202-555-0118</span>
                  </p>
                </div>

                <div className="my-[10px]">
                  <p className="text-[14px] font-inter">
                    Email:{" "}
                    <span className="text-[#C2C2C2]">
                      kevin.gilbert@gmail.com
                    </span>
                  </p>
                </div>
                <button className="mt-3 w-36 h-11 bg-[#FFCFAA] text-[var(--dark-primary)] text-sm font-inter font-[500] rounded-full">
                  EDIT ADDRESS
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {addCard && (
        <>
          <div className="absolute top-[150px]  left-[36%] w-[45%]  ">
            <button className="w-[100%] bg-[var(--doctor-color)] h-[50px] rounded-[20px] font-[500] text-[20px]">
              Add New Card
            </button>
            <div className="mt-[15px] bg-[#CCE1DD] rounded-[20px]  px-[20px] py-[15px] ">
              <input
                type="text"
                className="w-full h-[45px] my-[20px] text-[16px] rounded-[11px] bg-transparent outline-none  border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                placeholder="Name on card"
              />

              <input
                type="text"
                className="w-full h-[45px] mb-[20px] text-[16px] rounded-[11px] bg-transparent outline-none  border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                placeholder="Card Number"
              />

              <div className="flex items-center w-full gap-[20px]">
                <div className="w-[50%]">
                  <input
                    type="text"
                    className="w-full h-[45px] mb-[20px] text-[16px] rounded-[11px] bg-transparent outline-none  border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                    placeholder="MM/YY"
                  />
                </div>
                <div className="w-[50%]">
                  <input
                    type="text"
                    className="w-full h-[45px] mb-[20px] text-[16px] rounded-[11px] bg-transparent outline-none  border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                    placeholder="CVC"
                  />
                </div>
              </div>

              <div className=" mt-[30px] flex items-center gap-[20px] font-[600] font-inter text-[21px]">
                <button
                  className="w-full h-[50px] rounded-[37px] bg-[#DDBEA6]"
                  onClick={handleCloseAddCard}
                >
                  Add Card
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {editAdd && (
        <>
          <div className="absolute top-[150px]  left-[36%] w-[45%]  ">
            <button className="w-[100%] bg-[var(--doctor-color)] h-[50px] rounded-[20px] font-[500] text-[20px]">
              Edit Billing Address
            </button>
            <div className="mt-[15px] bg-[#CCE1DD] rounded-[20px]  px-[20px] py-[15px] ">
              <input
                type="text"
                className="w-full h-[45px] my-[20px] text-[16px] rounded-[11px] bg-transparent outline-none  border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                placeholder="Full Name"
              />

              <input
                type="text"
                className="w-full h-[45px] mb-[20px] text-[16px] rounded-[11px] bg-transparent outline-none  border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                placeholder="Address Line"
              />

              <div className="flex items-center w-full gap-[20px]">
                <div className="w-[50%]">
                  <input
                    type="text"
                    className="w-full h-[45px] mb-[20px] text-[16px] rounded-[11px] bg-transparent outline-none  border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                    placeholder="Phone Number"
                  />
                </div>
                <div className="w-[50%]">
                  <input
                    type="text"
                    className="w-full h-[45px] mb-[20px] text-[16px] rounded-[11px] bg-transparent outline-none  border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                    placeholder="Email Address"
                  />
                </div>
              </div>

              <div className=" mt-[30px] flex items-center gap-[20px] font-[600] font-inter text-[21px]">
                <button
                  className="w-full h-[50px] rounded-[37px] bg-[#DDBEA6]"
                  onClick={handleCloseEditADD}
                >
                  Save Address
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Hsidebar>
  );
};

export default ManageSubscription;
