import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { isAuth } from "./helper";
const SuperAdminPrivateRoute = () => {
  return isAuth() && isAuth()?.role === "superAd" ? (
    <Outlet />
  ) : (
    <Navigate to="/superadmin/login" />
  );
};

export default SuperAdminPrivateRoute;
