import React, { useState } from "react";
import Patient from "../../images/patient/patient.png";
import NewLogo from "../../images/newLogo.png";
import GoogleButton from "react-google-button";
// import FacebookLogin from "react-facebook-login";
import { FaFacebook } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { authenticate } from "../../auth/helper";
import { ToastContainer, toast } from "react-toastify";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleSignIn = async () => {
    const data = {
      email,
      password: pwd,
    };

    const LOGINData = await axios({
      method: "POST",
      url: `${BASE_URL}/patients/login`,
      data: data,
    })
      .then((response) => {
        console.log("Login Success", response);
        const currentDate = new Date();
        const lastPopupDate = new Date(response?.data?.user?.time2fa);
        const diffTime = Math.abs(currentDate - lastPopupDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        console.log("Diff dAYS", diffDays);
        if (response?.data?.user?.is2Fa && diffDays >= 30) {
          const dataNew = {
            token: response?.data?.token,
            email: response?.data?.user?.email,
          };
          const updateProfile = axios({
            method: "POST",
            url: `${BASE_URL}/patient/generate-otp`,
            headers: {
              Authorization: `Bearer ${response?.data?.token}`,
            },
            data: { email: response?.data?.user?.email },
          })
            .then((response) => {
              console.log("Generate Otp", response);

              navigate("/patient/2fa-verify", { state: dataNew });
            })
            .catch((error) => {
              console.log("Generate Otp  Error", error.response.data);
              toast.error(error.response.data);
            });
        } else {
          toast.success("Login success");
          authenticate(response, () => {
            setEmail("");
            setPwd("");
          });
          navigate("/patient/dashboard");
        }

        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Login  Error", error.response.data);
        toast.error(error.response.data);
      });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <div
        className=" w-full 900px:h-screen h-[110vh] bg-[#CCE1DD] bg-no-repeat"
        style={{
          backgroundImage: `url(${Patient})`,
          backgroundPosition: "center",
          backgroundRepeat: "none",
        }}
      >
        <div className="900px:pl-[170px] flex 900px:justify-start justify-center items-center">
          <img src={NewLogo} alt="" />
        </div>
        <div className="flex justify-center 900px:flex-row flex-col ">
          <div className="900px:w-[50%] 900px:block hidden pl-[170px] h-[520px] -mt-[100px]  ">
            <div className="flex flex-col items-start justify-end  h-[520px] mt-6">
              {/* <p className="font-inter font-[300] text-[13px] text-[#000] w-[60%]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p> */}

              <div className="flex justify-start items-start">
                <Link
                  to="/"
                  className="bg-[#FFCFAA] flex items-center justify-center mt-[25px] w-[200px] h-[50px] rounded-[10px] text-[var(--doctor-color)] font-[600] text-[16px]"
                >
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
          <div className="900px:w-[50%] w-full flex justify-evenly mt-[50px] 900px:mt-[10px]">
            <div className="500px:w-[450px] w-[90%] min-h-[530px]  bg-[rgba(1,104,85,0.7)]  rounded-[40px] px-6 py-6 font-inter">
              <p className="text-[16px] ">
                Welcome to{" "}
                <span className="text-[#FFCFAA] font-[600]">MedInclude</span>
              </p>
              <h2 className="text-[50px] font-[500] text-white">Sign in</h2>
              <div className="flex justify-between my-4">
                {/* <GoogleButton
                  type="light" // can be light or dark
                  // onClick={() => {
                  //   console.log("Google button clicked");
                  // }}
                  className="rounded-md"
                  style={{
                    borderRadius: "10px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    color: "#4285F4",
                    width: "340px",
                  }}
                /> */}

                {/* <FacebookLogin
                  appId="YOUR_APP_ID"
                  autoLoad={true}
                  fields="name,email,picture"
                  textButton=""
                  icon={<FaFacebook  size={24} />}
                  cssClass="cutomFbBtn"

                  style={{
                   width:"100px"
                  }}
                /> */}
              </div>

              <div className="mt-4">
                <div className="mt-8">
                  <label className="font-inter text-[15px] ">
                    {" "}
                    Email Address
                  </label>
                  <br />
                  <input
                    type="text"
                    className="w-[100%] h-[45px] rounded-[9px] outline-none px-4 text-[#808080]"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="mt-8">
                  <label className="font-inter text-[15px] ">
                    {" "}
                    Enter Your Password
                  </label>
                  <br />

                  <div className="relative w-[100%] h-[50px] my-[15px] px-[20px] bg-white  rounded-[9px] outline-none text-[#808080]">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="w-[90%] h-[50px] outline-none rounded-[9px]"
                      placeholder="Password"
                      value={pwd}
                      onChange={(e) => setPwd(e.target.value)}
                    />
                    {showPassword ? (
                      <IoEyeOutline
                        // color="red"
                        className="absolute left-[90%] z-50 top-[18px] cursor-pointer"
                        onClick={handleTogglePassword}
                      />
                    ) : (
                      <IoEyeOffOutline
                        // color="red"
                        className="absolute left-[90%] z-50 top-[18px] cursor-pointer"
                        onClick={handleTogglePassword}
                      />
                    )}
                  </div>
                  {/* <input
                    type="text"
                    className="w-[100%] h-[45px] rounded-[9px] outline-none px-4 text-[#808080]"
                    placeholder="Password"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                  /> */}
                </div>

                <Link
                  to="/patient/forget-password"
                  className="text-end text-[13px] text-[#FFCFAA] mt-1"
                >
                  <p className="text-end text-[13px] text-[#FFCFAA] mt-1">
                    Forgot Password?
                  </p>
                </Link>

                <div className="mt-2 text-[15] font-inter">
                  By Logging in, you agree to the{" "}
                  <Link
                    to="/terms-of-use"
                    className="border-b-2 border-[#FFCFAA] text-[#FFCFAA] pb-1"
                  >
                    Terms of Use
                  </Link>{" "}
                  and{" "}
                  <Link
                    to="/privacy-policy"
                    className="border-b-2 border-[#FFCFAA] text-[#FFCFAA] pb-1"
                  >
                    Privacy Policy
                  </Link>{" "}
                </div>

                <div className="mt-[30px] flex items-center justify-between">
                  {/* <div>
                    <p className="text-[#fff] font-inter font-[500] text-[13px]">
                      No Account ?
                    </p>
                    <Link
                      to="/patient/signup"
                      className="text-[#fff] font-inter font-[500] text-[13px]"
                    >
                      Sign up{" "}
                    </Link>
                  </div> */}
                  <button
                    className="bg-[#FFCFAA] w-[200px] h-[50px] rounded-[10px] text-[var(--doctor-color)] font-[600] text-[16px]"
                    style={{ boxShadow: "0px 4px 19px 0px #7793414D" }}
                    onClick={handleSignIn}
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
