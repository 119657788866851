import React, { useContext, useEffect, useState } from "react";
import Header from "../../Component/Patient/Header";
import GlobalStorage from "../../Storage/ContextProvider";
import Switch from "react-switch";
import Card1 from "../../images/patient/card1.png";
import Card2 from "../../images/patient/card2.png";
import Card3 from "../../images/patient/card3.png";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";

const HospitalPricingPage = () => {
  const { isOpen } = useContext(GlobalStorage);
  const [checked, setChecked] = useState(false);
  const [yearly, setYearly] = useState(false);
  const [addPaymentlActive, setAddPaymentlActive] = useState(false);
  const [planDuration, setPlanDuration] = useState();
  const [planType, setPlanType] = useState("");
  const [planAmount, setPlanAmount] = useState(0);
  const [noOfAccount, setNoOfAccount] = useState(1);
  const params = useParams();
  const navigate = useNavigate();
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    setYearly(nextChecked);
  };
  const handleFreePlan = () => {
    setAddPaymentlActive(true);
    setPlanDuration(1);
    setPlanType("Free");
  };
  useEffect(() => {
    console.log("Plann Duration", planDuration);
    if (planType === "Provider Pro" && planDuration === 12) {
      setPlanAmount(540);
    } else if (planType === "Free") {
      setPlanAmount(0);
    } else if (planType === "Provider Pro" && planDuration == 1) {
      setPlanAmount(50);
    }
  }, [planType, planDuration]);
  const handleProviderPlan = () => {
    setAddPaymentlActive(true);
    setPlanType("Provider Pro");
    if (yearly) {
      setPlanDuration(12);
    } else {
      setPlanDuration(1);
    }
  };
  const handleClosePopup = () => {
    setAddPaymentlActive(false);
  };
  const handlePayment = async () => {
    if (planType === "Free") {
      const data = {
        subscriptionType: planType,
        noOfAccount: planDuration,
        token: params?.hid,
      };

      const LOGINData = await axios({
        method: "POST",
        url: `${BASE_URL}/hospital/choseplan`,
        data: data,
      })
        .then((response) => {
          console.log("Hospital Update] Success", response);
          setAddPaymentlActive(false);
          navigate("/payment/success");
          toast.success(response?.data?.msg);
        })
        .catch((error) => {
          console.log("Hospital Update  Error", error.response.data);
          toast.error(error.response.data);
        });
    } else if (planType === "Provider Pro") {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
      const data = {
        planType: planType,
        amount: planAmount * noOfAccount,
        noOfMonths: planDuration,
        // subscriptionType: planType,
        noOfAccount,
        token: params?.hid,
      };
      const handleStripe = await axios({
        method: "POST",
        url: `${BASE_URL}/create-checkout-session`,
        data: data,
      })
        .then((response) => {
          console.log("Payment Redirect", response?.data?.id);
          const { payment_url } = response.data;
          window.open(payment_url, "_blank");
          const redirectUrl = window.location.href;
          console.log("Redirtexd", redirectUrl);
          setAddPaymentlActive(false);
          // const result = stripe.redirectToCheckout({
          //   sessionId: response.data.id,
          // });
        })
        .catch((error) => {
          console.log("Payment Redirect Error  Error", error.response);
          // toast.error(error.response);
        });
    }
  };
  return (
    <>
      {/* <Header /> */}
      {!isOpen && (
        <div className="relative">
          <div
            className={`${
              addPaymentlActive ? "h-[100vh] overflow-hidden opacity-25" : ""
            }`}
          >
            <div className="px-[50px] bg-[#CCE1DD] py-[100px] text-[#052721]  font-inter">
              <p className="text-center text-[40px] font-[600]">Pricing</p>
              <div className="flex items-center justify-center">
                {/* <p className="text-center text-[#6F6C90] text-[18px] w-[650px]">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit dolor
                  posuere vel venenatis eu sit massa volutpat.
                </p> */}
              </div>

              {/* <div className="flex items-center justify-center mt-10 text-[#052721] text-[18px] pb-[70px] mb-11">
                <p>Monthly</p>
                <label className="mx-4">
                  <Switch
                    onChange={handleChange}
                    checked={checked}
                    className="reactToggleCss"
                    handleDiameter={28}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offColor="#016855"
                    onColor="#DCA071"
                    offHandleColor="#fff"
                    onHandleColor="#fff"
                    height={30}
                    width={60}
                    activeBoxShadow="0px 0px 1px 1px #01483B"
                  />
                </label>
                <p>
                  Annually <span className="text-[#67A599]">(Save 10%)</span>
                </p>
              </div> */}

              <div className="flex gap-7 justify-center mt-14">
                <div className=" bg-[#fff] text-[#052721] py-[30px] px-[40px] rounded-[24px] h-[570px] ">
                  <div>
                    <img src={Card1} alt="" className="w-[160px] h-[60px]" />
                  </div>
                  <p className="text-[#6F6C90] text-[16px] mt-[10px]">
                    14-days trial{" "}
                  </p>
                  <p className="text-[50px] font-[600] font-inter mt-[35px]">
                    FREE
                  </p>
                  <div>
                    <p className="text-[14px] font-[600]">What's included</p>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">
                        Simplification - up to 50 notes
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">
                        Translation - up to 50 notes
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">Email support</p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">Video tutorials</p>
                    </div>
                  </div>
                  <button
                    className="bg-[var(--doctor-color)] w-full mt-[30px] rounded-full text-white py-[15px]"
                    onClick={handleFreePlan}
                  >
                    Get Started
                  </button>
                </div>
                {/* <div className=" bg-[var(--doctor-color)] text-[#fff] py-[30px] px-[40px] rounded-[24px] h-[580px] -mt-[30px]">
                  <div>
                    <img src={Card2} alt="" className="w-[160px] h-[55px]" />
                  </div>
                  <p className="text-[#D9DBE9] text-[16px] mt-[10px] w-[270px]">
                    Best for providers’ use at point of care
                  </p>
                  {yearly ? (
                    <p className="text-[50px] font-[600] font-inter mt-[35px]">
                      $540{" "}
                      <span className="text-[18px] font-[400] text-[#D9DBE9]">
                        account/yearly
                      </span>
                    </p>
                  ) : (
                    <p className="text-[50px] font-[600] font-inter mt-[35px]">
                      $50{" "}
                      <span className="text-[18px] font-[400] text-[#D9DBE9]">
                        account/monthly
                      </span>
                    </p>
                  )}

                  <div>
                    <p className="text-[14px] font-[600]">What's included</p>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#fff] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[var(--doctor-color)]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px] text-[#D9DBE9]">
                        Unlimited simplification
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#fff] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[var(--doctor-color)]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px] text-[#D9DBE9]">
                        Unlimited translation
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#fff] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[var(--doctor-color)]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px] text-[#D9DBE9]">
                        10 free patient portal access
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#fff] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[var(--doctor-color)]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px] text-[#D9DBE9]">
                        Onboarding support
                      </p>
                    </div>
                  </div>
                  <button
                    className="text-[var(--doctor-color)] w-full mt-[30px] rounded-full bg-white py-[15px] font-[600]"
                    onClick={handleProviderPlan}
                  >
                    Get Started
                  </button>
                </div> */}
                <div className=" bg-[#fff] text-[#052721] py-[30px] px-[40px] rounded-[24px] h-[570px] ">
                  <div>
                    <img src={Card3} alt="" className="w-[160px] h-[55px]" />
                  </div>
                  <p className="text-[#6F6C90] text-[16px] mt-[10px] w-[300px]">
                    For health organizations with multiple departments/providers
                  </p>
                  <p className="text-[40px] font-[600] font-inter mt-[35px]">
                    Custom pricing
                  </p>
                  <div>
                    <p className="text-[14px] font-[600]">What's included</p>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">
                        Multiple departments/providers
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">
                        Unlimited patient-portal access
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">
                        Dedicated support and onboarding
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">
                        Ongoing 1:1 training and upgrades
                      </p>
                    </div>
                  </div>
                  <button
                    className="bg-[var(--doctor-color)] w-full mt-[30px] rounded-full text-white py-[15px]"
                    // onClick={() => setEnterpriseActive(true)}
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>
          {addPaymentlActive && (
            <>
              <div className="w-full">
                <div className="absolute top-[15%]  w-[60%]  bg-[#fff] left-[18%] rounded-[12px] p-5 font-inter">
                  <div className="flex justify-between items-start">
                    <button
                      className="text-[#101828] px-[22px] py-[10px] border-[#E4E7EC] border-[1px] rounded-lg text-[17px] font-inter font-[600]"
                      style={{ boxShadow: " 0px 1px 2px 0px #1018280D" }}
                    >
                      {planType}
                    </button>
                    <RxCross2
                      className="text-[#667085] cursor-pointer text-[24px]"
                      onClick={handleClosePopup}
                    />
                  </div>
                  {/* <p className="text-[#475467] text-sm mt-4">
                    Fill in the form to get started
                  </p> */}

                  <div className="flex items-center justify-between gap-2 my-4 font-inter font-[500] text-[#344054] text-sm ">
                    <div className="flex items-center  gap-2 my-4">
                      <label>Number Of Accounts:</label>
                      {planType === "Provider Pro" && (
                        <input
                          type="number"
                          className="outline-none border-[1px] border-[#D0D5DD] px-[14px] py-[10px] w-[110px] rounded-lg"
                          // placeholder="Phone Number "
                          min="1"
                          value={noOfAccount}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              e.target.value = 1;
                            }
                            setNoOfAccount(e.target.value);
                          }}
                          // onChange={(e) => setNoOfAccount(e.target.value)}
                        />
                      )}
                      {planType === "Free" && (
                        <div
                          type="number"
                          className="outline-none border-[1px] border-[#D0D5DD] px-[14px] py-[10px] w-[110px] rounded-lg"
                        >
                          1
                        </div>
                      )}
                    </div>
                    <div>
                      <p className="mb-4">Due Now:</p>
                      <p className="text-[50px] font-inter font-[700]">
                        ${planAmount * noOfAccount}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center gap-5 mt-8 mb-4">
                    <button
                      className="w-[50%] text-[#101828]  py-[10px] border-[#E4E7EC] border-[1px] rounded-lg text-[17px] font-inter font-[600]"
                      onClick={handleClosePopup}
                    >
                      Cancel
                    </button>

                    <button
                      className="w-[50%] text-[#fff] bg-[var(--doctor-color)]  py-[10px] border-[#E4E7EC] border-[1px] rounded-lg text-[17px] font-inter font-[600]"
                      onClick={handlePayment}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default HospitalPricingPage;
