import React, { useState } from "react";

const VerifyOtpInput = ({ length, onChange }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));

  const handleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, "");
    if (value.length > 1) return;

    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);
    onChange(newOtp.join(""));

    // Move focus to the next input box
    if (value && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleBackspace = (element, index) => {
    if (element.value === "" && element.previousSibling) {
      element.previousSibling.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData
      .getData("text")
      .slice(0, length)
      .replace(/[^0-9]/g, "");
    const newOtp = [...otp];

    for (let i = 0; i < length; i++) {
      newOtp[i] = pasteData[i] || "";
    }

    setOtp(newOtp);
    onChange(newOtp.join(""));

    // Focus on the next empty input box
    const nextInput = document.querySelector(`input[value='']`);
    if (nextInput) nextInput.focus();
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {otp.map((data, index) => (
          <input
            key={index}
            type="text"
            value={data}
            maxLength="1"
            onChange={(e) => handleChange(e.target, index)}
            onKeyUp={(e) =>
              e.key === "Backspace" ? handleBackspace(e.target, index) : null
            }
            onPaste={handlePaste}
            style={{
              width: "2rem",
              height: "2rem",
              color: "#fff",
              background: "transparent",
              margin: "0 0.5rem",
              fontSize: "2rem",
              textAlign: "center",
              border: "2px solid #ccc",
              borderRadius: "4px",
            }}
          />
        ))}
      </div>
    </>
  );
};

export default VerifyOtpInput;
