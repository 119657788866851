import React, { useContext, useEffect, useState } from "react";
import GlobalStorage from "../../../Storage/ContextProvider";
import Logo from "../../../images/logo.png";
import ShortLogo from "../../../images/shortLogo.png";
import ShortKight from "../../../images/shoetLightLogo.png";
import LightLogo from "../../../images/newLogo.png";
import { MdDashboard } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { TbReport } from "react-icons/tb";
import { GiHospitalCross } from "react-icons/gi";
import { IoSettingsOutline } from "react-icons/io5";
import { BsChatDots } from "react-icons/bs";
import { MdOutlineLightMode } from "react-icons/md";
import { MdOutlineReportGmailerrorred } from "react-icons/md";
import { IoMdArrowDropleft } from "react-icons/io";
import PatientTopBar from "./PatientTopBar";
import PatientSmallSidebar from "./PatientSmallSidebar";
import { CiSearch } from "react-icons/ci";
import { getCookie, signout } from "../../../auth/helper";
import cookie from "js-cookie";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import { jwtDecode } from "jwt-decode";

const PatientSidebar = ({ activeLink, children }) => {
  // const { darkMode } = useContext(GlobalStorage);
  const [fullSideBar, setFullSidebar] = useState(false);
  const {
    darkMode,
    setDarkMode,
    showSearachBar,
    setShowSearchBar,
    patientProfile,
    setPatientProfile,
    refreshPatient,
    setRefreshPatient,
  } = useContext(GlobalStorage);

  // const {  patientOpen, setPatientOpen,} = useContext(GlobalStorage);
  const [patientOpen, setPatientOpen] = useState(false);
  const navigate = useNavigate();
  const navItem = [
    {
      link: "/patient/dashboard",
      icon: <MdDashboard size={20} />,
      title: "Dashboard",
    },
    {
      link: "/dashboard/myreport",
      icon: <TbReport size={20} />,
      title: "My Reports",
    },
    // {
    //   link: "/dashboard/hospital",
    //   icon: <GiHospitalCross size={20} />,
    //   title: "My Hospital",
    // },

    {
      link: "/dashboard/setting",
      icon: <IoSettingsOutline size={20} />,
      title: "Setting",
    },

    // {
    //   link: "/dashboard/support",
    //   icon: <BsChatDots size={20} />,
    //   title: "Support",
    // },
  ];

  const handleToggleMode = () => {
    setDarkMode(!darkMode);
  };

  const handlesignout = () => {
    if (window !== "undefined") {
      cookie.remove("token", {
        expires: 1,
      });
    }
    if (window !== "undefined") {
      localStorage.removeItem("user");
    }
    navigate("/patient/signin");
  };
  const token = getCookie("token");

  useEffect(() => {
    loadPatientProfile();
  }, [refreshPatient]);

  const loadPatientProfile = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/patient/profile`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("Get Patient Profile", response);
        setPatientProfile(response.data);
      })
      .catch((error) => {
        console.log("Get Patient Profile ERROR", error.response);
      });
  };
  useEffect(() => {
    // Check token expiry when component mounts
    const token = getCookie("token");
    if (token) {
      // const decodedToken = decodeToken(token);
      const decodedToken = jwtDecode(token);
      console.log("decodedToken", decodedToken);
      if (decodedToken.exp < Date.now() / 1000) {
        // Token expired, clear token and navigate to login page
        if (window !== "undefined") {
          cookie.remove("token", {
            expires: 1,
          });
        }
        if (window !== "undefined") {
          localStorage.removeItem("user");
        }
        navigate("/patient/signin");
      } else {
        // Token valid, user is logged in
        // setIsLoggedIn(true);
        // Set up a timer to automatically log out when the token expires
        const timer = setTimeout(() => {
          if (window !== "undefined") {
            cookie.remove("token", {
              expires: 1,
            });
          }
          if (window !== "undefined") {
            localStorage.removeItem("user");
          }
          navigate("/patient/signin");
        }, (decodedToken.exp - Date.now() / 1000) * 1000);
        return () => clearTimeout(timer);
      }
    } else {
      // Token doesn't exist, navigate to login page
      navigate("/patient/signin");
    }
  }, [navigate]);
  return (
    <>
      <div className="flex ">
        {fullSideBar ? (
          <>
            <div className="900px:flex hidden flex-col p-3 min-h-screen bg-[#fff] dark:bg-[var(--doctor-color)] w-[205px] relative z-10">
              <div className="space-y-3">
                <div className="flex items-center justify-center pt-[10px] border-b border-[rgba(255,255,255,.2)] pb-3">
                  {darkMode ? (
                    <img src={Logo} className="w-[140px] h-[55px]" alt="" />
                  ) : (
                    <img
                      src={LightLogo}
                      className="w-[165px] h-[60px]"
                      alt=""
                    />
                  )}
                </div>
                {/* 
                <div className="flex justify-center pt-[40px]">
                  <button className="flex items-center justify-center bg-[var(--doctor-color)] text-[#fff] dark:bg-[#fff] w-[230px] h-[40px] rounded-full text-[16px] dark:text-[var(--doctor-color)] font-[500]">
                    <TiPlus /> New Transcript
                  </button>
                </div> */}

                <div className="flex-1">
                  <ul className=" pb-4  text-md ">
                    {navItem?.map((item) => {
                      return (
                        <>
                          {item?.link && !item?.subLinks && (
                            <li
                              className={`${
                                activeLink === item?.title
                                  ? "  text-[#000000] dark:text-[#fff]"
                                  : "text-[rgba(80,80,80,0.25)] dark:text-[#199A8E]"
                              } rounded-md  py-[3px] my-[12px] text-[16px] font-[400]`}
                            >
                              <Link
                                to={item?.link}
                                className="flex items-center p-2 space-x-3 rounded-md"
                              >
                                <div className="">{item?.icon}</div>
                                <span>{item?.title}</span>
                              </Link>
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>

                <div className="">
                  <div
                    className="absolute bottom-[50px] -right-4 cursor-pointer  "
                    onClick={() => setFullSidebar(false)}
                  >
                    <div className="w-[30px] h-[30px] bg-[var(--doctor-color)] rounded-full">
                      <IoMdArrowDropleft size={30} color="white" />
                    </div>
                  </div>
                  <div className="absolute bottom-[10px]  font-[500] text-[16px] text-[#fff]">
                    <div className="rounded-md  py-[3px] my-[12px] text-[16px] font-[400]">
                      <div
                        to=""
                        className="flex items-center p-2 space-x-3 rounded-md my-6 dark:text-white text-black"
                        onClick={handleToggleMode}
                      >
                        <div className="">
                          <MdOutlineLightMode size={20} />
                        </div>
                        <span>Light Mode</span>
                      </div>

                      <button
                        onClick={handlesignout}
                        className="flex items-center p-2 space-x-3 rounded-md dark:text-white text-black"
                      >
                        <div className="">
                          <MdOutlineReportGmailerrorred size={20} />
                        </div>
                        <span>Logout</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="900px:flex hidden flex-col py-3 pl-2 min-h-screen bg-[#fff] dark:bg-[var(--doctor-color)] w-[90px] relative z-10">
              <div className="space-y-3">
                <div className="flex items-center justify-center pt-[10px] border-b border-[rgba(255,255,255,.2)] pb-3">
                  {darkMode ? (
                    <img src={ShortLogo} className="w-[55px] h-[55px]" alt="" />
                  ) : (
                    <img
                      src={ShortKight}
                      className="w-[55px] h-[55px]"
                      alt=""
                    />
                  )}
                </div>
                {/* 
                <div className="flex justify-center pt-[40px]">
                  <button className="flex items-center justify-center bg-[var(--doctor-color)] text-[#fff] dark:bg-[#fff] w-[230px] h-[40px] rounded-full text-[16px] dark:text-[var(--doctor-color)] font-[500]">
                    <TiPlus /> New Transcript
                  </button>
                </div> */}

                <div className="flex-1 pl-4">
                  <ul className=" pb-4  text-md ">
                    {navItem?.map((item) => {
                      return (
                        <>
                          {item?.link && !item?.subLinks && (
                            <li
                              className={`${
                                activeLink === item?.title
                                  ? "  text-[#000000] dark:text-[#fff]"
                                  : "text-[rgba(80,80,80,0.25)] dark:text-[#199A8E]"
                              } rounded-md  py-[3px] my-[12px] text-[16px] font-[400]`}
                            >
                              <Link
                                to={item?.link}
                                className="flex items-center p-2 space-x-3 rounded-md"
                              >
                                <div className="">{item?.icon}</div>
                              </Link>
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>

                <div className="pl-4">
                  <div className="absolute bottom-[50px] -right-4 cursor-pointer  ">
                    <div
                      className="w-[30px] h-[30px] bg-[var(--doctor-color)] rounded-full"
                      onClick={() => setFullSidebar(true)}
                    >
                      <IoMdArrowDropleft
                        size={30}
                        color="white"
                        className="rotate-180"
                      />
                    </div>
                  </div>
                  <div className="absolute bottom-[10px]  font-[500] text-[16px] text-[#fff]">
                    <div className="rounded-md  py-[3px] my-[12px] text-[16px] font-[400]">
                      <div
                        to=""
                        className="flex items-center p-2 space-x-3 rounded-md my-6"
                        onClick={() => setDarkMode(!darkMode)}
                      >
                        <div className="dark:text-white text-black">
                          <MdOutlineLightMode size={20} />
                        </div>
                      </div>

                      <button
                        className="flex items-center p-2 -ml-4 rounded-md"
                        onClick={handlesignout}
                      >
                        <div className="text-black dark:text-white">
                          {/* <MdOutlineReportGmailerrorred size={20} /> */}
                          Logout
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="w-full ">
          <div className="600px:h-[80px] 900px:block hidden  w-full">
            <PatientTopBar />
          </div>
          <div className=" 900px:hidden  ">
            <div className="pb-[100px]">
              <PatientSmallSidebar
                patientOpen={patientOpen}
                setPatientOpen={setPatientOpen}
              />
            </div>
            {showSearachBar && !patientOpen && (
              <>
                <div className="flex items-center justify-center mb-6">
                  <div className="flex items-center  h-[45px] w-[85%] border dark:border-[rgba(255,255,255,.6)] border-[rgba(0,0,0,.5)]  rounded-md relative dark:text-white text-[#000]   ">
                    <CiSearch className="absolute left-[10px]" />
                    <input
                      className="bg-transparent w-full outline-none ml-[35px] mr-[10px] dark:placeholder:text-[rgba(255,255,255,.6)] placeholder:text-[rgba(0,0,0,.5)] "
                      type="text"
                      placeholder="Search Dashboard"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={`${patientOpen ? "hidden" : ""}`}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default PatientSidebar;
