import React from "react";
import Logo from "../../images/logo.png";
import SuccessImage from "../../images/hospital/success.png";
import { useNavigate } from "react-router-dom";
const PaymentSuccess = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex items-center justify-center flex-col dark:bg-[#207A69] h-[100vh]">
        <img src={Logo} className="w-[200px] h-[80px] my-[30px]" alt="" />
        <img
          src={SuccessImage}
          className="W-[180px] h-[180px] my-[30px]"
          alt=""
        />
        <h3 className="text-[30px] my-[10px]">
         Payment Done successfully!
        </h3>
        <p className="text-[22px] text-[#9C9AA5] text-center">
          Please follow Your Email for Password Setup
        </p>
        <button
          className="w-[200px] h-[45px] bg-[#FFCFAA] rounded-[8px] text-[#000000] my-[30px] text-[15px] font-[500]"
          onClick={() => navigate("/")}
        >
          Back To Home
        </button>
      </div>
    </>
  );
};

export default PaymentSuccess;
