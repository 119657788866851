import React, { useEffect, useState } from "react";
import SuperSidebar from "../../Component/SuperAdmin/Layout/SuperSidebar";
import { PiArrowLeftFill } from "react-icons/pi";
import PImage from "../../images/patientImage.png";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";

import { Line } from "react-chartjs-2";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../baseUrl";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Septemper",
  "October",
  "November",
  "December",
];
export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};
export const data = {
  labels,
  datasets: [
    {
      label: "Translations",
      data: [6, 20, 30, 20, 30, 50, 10, 13, 34, 23, 60, 9],
      borderColor: "#FE981E",
      backgroundColor: "#FE981E",
    },
    {
      label: "Transcriptions",
      data: [20, 35, 30, 20, 40, 50, 20, 40, 26, 37, 22, 8],
      borderColor: "#0F123F",
      backgroundColor: "#0F123F",
    },
  ],
};
const SuperHospitlProfile = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [hospitalDetail, setHospitalDetail] = useState();

  useEffect(() => {
    loadDepartmentDetail();
  }, [params?.id]);

  const loadDepartmentDetail = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/superadmin/hospital/${params?.id}`,
    })
      .then((response) => {
        console.log("Get Department Detail", response);
        setHospitalDetail(response?.data);
        // setPatients(response.data);
      })
      .catch((error) => {
        console.log("Get Department Detail  ERROR", error.response);
      });
  };

  return (
    <SuperSidebar activeLink="Hospitals">
      <div className="h-[290px] w-[100%] bg-[#67A599] dark:bg-[var(--dark-primary)] p-[20px] text-[#fff]">
        <div className="flex items-center justify-between">
          <Link to="/superadmin/hospitals" className="flex gap-[10px] items-center text-[25px] font-[500]">
            <PiArrowLeftFill className="mt-[5px]" />
            <p>Hospitals</p>
          </Link>
          <div className="flex items-center gap-4">
            <button
              className="w-[250px] h-[47px] bg-[#DCA071] dark:bg-[#67A599] rounded-full font-inter font-[700] text-[14px] mt-[15px] "
              onClick={() => navigate("/superadmin/manage-subscription")}
            >
              Manage Subscription
            </button>
            <button className="w-[151px] h-[47px] bg-[#FF0000] rounded-full font-inter font-[700] text-[14px] mt-[15px] ">
              Deactivate
            </button>
          </div>
        </div>
        <div className="flex mt-[40px] items-center">
          <div className="flex gap-[15px] items-center border-r-[1px] border-[#fff] pr-[50px] h-[100px]">
            <img
              src={PImage}
              className="w-[103px] h-[103px] rounded-full border-[5px] border-[#fff]"
              alt=""
            />
            <div>
              <p className="font-[600] text-[29px] leading-[40px]">
                {hospitalDetail?.hospitalName}
              </p>

              <p className="text-[17px] text-[#FFCFAA]">
                {hospitalDetail?.email}
              </p>
            </div>
          </div>
          <div className="px-[40px] border-r-[1px] border-[#fff] pt-[40px] h-[100px]">
            {/* <div className="flex gap-[20px] items-center my-[15px] ">
              <button className="w-[151px] h-[29px] bg-[#67A599] rounded-full">
                Speciality
              </button>
              <p className="text-[#FFCFAA] text-[17px]">General</p>
            </div> */}
            <div className="flex gap-[20px] items-center">
              <button className="w-[151px] h-[29px] bg-[#67A599] rounded-full">
                Date Joined
              </button>
              <p className="text-[#FFCFAA] text-[17px]">
                {moment(new Date(hospitalDetail?.createdAt)).format(
                  "MMMM Do YYYY, h:mm:ss a "
                )}
              </p>
            </div>
          </div>
          <div className="px-[40px]">
            <p className="font-inter font-[700] text-[70px] leading-[60px]">
              Pro
            </p>
            <p className="font-inter font-[700] text-[18px]">Subscription</p>
          </div>
        </div>
      </div>
      <div className="p-[20px]">
        <div className="p-[20px] bg-[#67A599] dark:bg-[var(--dark-primary)] w-[100%] h-[500px]  my-[20px]">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-[20px]">
              <p>Transcript Analytics</p>

              <select
                id="countries"
                className="w-[120px] px-[6px] h-[26px] outline-none bg-[#FFCFAA] rounded-[8px] text-[var(--doctor-color)] text-[12px]"
              >
                <option selected>All Doctors</option>
                <option value="US">United States</option>
                <option value="CA">Canada</option>
                <option value="FR">France</option>
                <option value="DE">Germany</option>
              </select>
            </div>

            <div className="flex items-center gap-[15px]  mb-[25px]">
              <div className="flex items-center gap-[10px]">
                <div className="bg-[#0F123F] w-[8px] h-[8px] rounded-full"></div>
                <div className="font-outfit text-[12px]">Transcriptions</div>
              </div>
              <div className="flex items-center gap-[10px]">
                <div className="bg-[#FE981E] w-[8px] h-[8px] rounded-full"></div>
                <div className="font-outfit text-[12px]">Translations</div>
              </div>
              <select
                id="countries"
                className="w-[100px] px-[6px] h-[26px] outline-none bg-[#FFCFAA] rounded-[8px] text-[var(--doctor-color)] text-[12px]"
              >
                <option selected>Monthly</option>
                <option value="US">Yearly</option>
                <option value="CA">Weekly</option>
                <option value="FR">Daily</option>
              </select>
            </div>
          </div>
          <Line
            className="w-[100%]"
            width={900}
            height={350}
            options={options}
            data={data}
          />
        </div>
      </div>
    </SuperSidebar>
  );
};

export default SuperHospitlProfile;
