import React from "react";
import PatientSidebar from "../../../Component/Patient/Layout/PatientSidebar";
import DImage from "../../../images/patient/dashboardImg.png";
import Hospital from '../../../images/patient/hospital.png'

const PatientHospital = () => {
  return (
    <>
      <PatientSidebar activeLink="My Hospital">
        <div className="px-5">
          <h2 className="font-inter font-[500] 500px:text-[40px] text-[25px]">
            Toronto General Hospital
          </h2>

          <div className="flex gap-4 w-full 900px:flex-row flex-col">
            <img src={DImage} className="900px:w-[230px] 900px:h-[230px] 500px:w-[80%] w-[100%] h-auto flex  justify-center items-center" alt="" />
            <div className="1200px:h-[230px] bg-white dark:bg-[var(--dark-primary)] w-full p-6 flex flex-col justify-between rounded-md">
              <div>
                <h3 className="font-inter font-[500] text-[18px] text-[#67A599] dark:text-white">
                  About Hospital
                </h3>
                <p className="font-inter dark:opacity-80 text-[15px] font-[300] mt-2">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
              <div className="flex flex-wrap font-inter mt-4 600px:gap-10 gap-5 ">
                <div>
                  <p className="text-[17] font-[500] text-[#67A599] dark:text-white">Email Address</p>
                  <p className="font-[300] text-[14px] dark:opacity-60 pt-1">
                    johnsmith@gmail.com
                  </p>
                </div>
                <div>
                  <p className="text-[17] font-[500] text-[#67A599] dark:text-white">Phone Number</p>
                  <p className="font-[300] text-[14px] dark:opacity-60 pt-1">
                  +91 8548521524
                  </p>
                </div>

                <div>
                  <p className="text-[17] font-[500] text-[#67A599] dark:text-white"> Address</p>
                  <p className="font-[300] text-[14px] dark:opacity-60 pt-1">
                  345, Sarju Appt., Mota Varacha, Surat Gujarat, India.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="my-8">
            <img src={Hospital} alt="" />
          </div>
        </div>
      </PatientSidebar>
    </>
  );
};

export default PatientHospital;
