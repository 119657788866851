import React, { useState } from "react";
import PatientSidebar from "../../../Component/Patient/Layout/PatientSidebar";
import PatientFaq from "../../../Component/Patient/Support/PatientFaq";

const PatientSupport = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <PatientSidebar activeLink="Support">
        <div className="px-5 font-inter">
          <h2 className="font-[500] text-[40px]">Support</h2>

          <div className="flex gap-5 mt-9">
            <div className="w-[55%]">
              <div className="flex border-b border-[#F4F5F7] ">
                <p
                  className={`${
                    activeTab === 0
                      ? "border-b-[2px] border-[#DCA071] text-[#DCA071] -mb-[2px]"
                      : ""
                  } px-12 text-[16px]  pb-2 cursor-pointer`}
                  onClick={() => setActiveTab(0)}
                >
                  FAQ
                </p>
                <p
                  className={`${
                    activeTab === 1
                      ? "border-b-[2px] border-[#DCA071] text-[#DCA071] -mb-[2px]"
                      : ""
                  } px-12  pb-2 cursor-pointer`}
                  onClick={() => setActiveTab(1)}
                >
                  Demo Videos
                </p>
              </div>

              <div>
                {/* <PatientFaq /> */}
              </div>
            </div>
          </div>
        </div>
      </PatientSidebar>
    </>
  );
};

export default PatientSupport;
