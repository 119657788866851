import React, { useContext } from "react";
import Logo from "../../../images/logo.png";
import { PiShoppingBagOpenBold } from "react-icons/pi";
import GlobalStorage from "../../../Storage/ContextProvider";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

const Customize = () => {
  const { onBoard, setOnBoard } = useContext(GlobalStorage);
  return (
    <>
      <img src={Logo} className="w-[250px] h-[100px]" alt="" />
      <div className="absolute top-[50px] left-[30px]">
        <Link to="/hospital/password-success"
          className="flex items-center font-[500] text-[18px] cursor-pointer gap-[1px] text-[var(--doctor-color)]"
          onClick={() => setOnBoard(0)}
        >
          <FaChevronLeft />
          Back
        </Link>
      </div>
      <div>
        <h2 className="text-[40px] text-[var(--doctor-color)]">
          Customize your Organization
        </h2>
        <p className="text-[20px] text-[#000000]">
          Setup your organization for members that may join later.
        </p>
      </div>
      <div className="flex items-center justify-center flex-col">
        <div className="border-[4px] border-[#67A599] text-[43px] text-[#67A599] w-[105px] h-[105px] rounded-full bg-[#fff] flex items-center justify-center">
          <PiShoppingBagOpenBold />
        </div>
        <p className="text-[15px] text-[var(--doctor-color)] pl-[8px] pt-[10px]">
          Upload Logo
        </p>
      </div>

      <div className="">
        <div className="flex mt-[10px]">
          <p className="text-[15px] text-[var(--doctor-color)]">
            Hospital Name
          </p>{" "}
          <span className="text-[#E45270]">*</span>
        </div>
        <input
          type="text"
          className="px-[15px] outline-none w-[440px] h-[45px] rounded-[8px] border-[1px] border-[rgba(70,95,241,0.4)] text-[var(--doctor-color)] text-[14px]"
          style={{ boxShadow: "0px 4px 8px 0px rgba(70, 95, 241, 0.1)" }}
        />

        <div className="flex mt-[20px]">
          <p className="text-[15px] text-[var(--doctor-color)]">
            Hospital Type
          </p>{" "}
          <span className="text-[#E45270]">*</span>
        </div>
        <input
          type="text"
          className="px-[15px] outline-none w-[440px] h-[45px] rounded-[8px] border-[1px] border-[rgba(70,95,241,0.4)] text-[var(--doctor-color)] text-[14px]"
          style={{ boxShadow: "0px 4px 8px 0px rgba(70, 95, 241, 0.1)" }}
          placeholder="Select the Hospital Type"
        />
      </div>
      <button
        className="bg-[#FFCFAA] w-[200px] h-[45px] rounded-[8px] text-[#000000] text-[15px] font-[500] my-[20px]"
        onClick={() => setOnBoard(1)}
      >
        Continue
      </button>

      <div className="flex gap-[20px] mt-[20px]">
        <div className="w-[67px] h-[13px] rounded-full bg-[var(--doctor-color)]"></div>
        <div className="w-[13px] h-[13px] opacity-[.5] rounded-full bg-[var(--doctor-color)]"></div>
      </div>
    </>
  );
};

export default Customize;
