import React, { useContext, useEffect, useState } from "react";
import Switch from "react-switch";
import GlobalStorage from "../../../Storage/ContextProvider";
import { RxCross2 } from "react-icons/rx";
import OTPInput from "./OTPInput";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import { toast } from "react-toastify";
import { getCookie } from "../../../auth/helper";

const Security = () => {
  const [checked, setChecked] = useState(false);
  const [is2FaPopup, setIs2FaPopup] = useState(false);
  const [is2Faremove, setIs2FaRemove] = useState(false);
  const token = getCookie("token");
  const [otp, setOtp] = useState("");
  const {
    darkMode,
    patientProfile,
    is2FaConfirm,
    set2FaConfirm,
    refreshPatient,
    setRefreshPatient,
  } = useContext(GlobalStorage);

  useEffect(() => {
    if (patientProfile?.is2Fa === true) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [patientProfile]);

  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  const handleChange = (nextChecked) => {
    // setChecked(nextChecked);
    if (checked === false) {
      setIs2FaRemove(false);
      set2FaConfirm(true);
    } else if (checked === true) {
      set2FaConfirm(false);
      setIs2FaRemove(true);
    }
  };

  // useEffect(() => {
  //   if (checked === true) {
  //     setIs2FaRemove(false);
  //     set2FaConfirm(true);
  //   } else if (checked === false) {
  //     set2FaConfirm(false);
  //     setIs2FaRemove(true);
  //   }
  // }, [checked]);

  const handleDisableYes = async () => {
    const updateProfile = await axios({
      method: "POST",
      url: `${BASE_URL}/patient/disable2Fa`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("Generate Otp", response);
        toast.success(response?.data);
        setChecked(false);
        setIs2FaRemove(false);
        setRefreshPatient(!refreshPatient);
      })
      .catch((error) => {
        console.log("Generate Otp  Error", error.response.data);
        toast.error(error.response.data);
      });
  };
  const handleEnableYes = async () => {
    const updateProfile = await axios({
      method: "POST",
      url: `${BASE_URL}/patient/generate-otp`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { email: patientProfile?.email },
    })
      .then((response) => {
        console.log("Generate Otp", response);
        toast.success("Otp is sent to the registered gmail");
        set2FaConfirm(false);
        // setChecked(true);
        setIs2FaPopup(true);
      })
      .catch((error) => {
        console.log("Generate Otp  Error", error.response.data);
        toast.error(error.response.data);
      });
  };
  const handleVerifyOtp = async () => {
    const updateProfile = await axios({
      method: "POST",
      url: `${BASE_URL}/patient/verify-otp`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { email: patientProfile?.email, otp: otp },
    })
      .then((response) => {
        console.log("Verify Otp", response);
        toast.success(response?.data);
        setIs2FaPopup(false);
        setRefreshPatient(!refreshPatient);
        setChecked(true)
      })
      .catch((error) => {
        console.log("verify Otp  Error", error.response.data);
        toast.error(error.response.data);
      });
  };
  return (
    <>
      <div className="mt-6">
        <h2 className="font-[500] text-lg">Two-factor Authentication</h2>

        <div className="flex gap-3 mt-5">
          <label className="">
            <Switch
              onChange={handleChange}
              checked={checked}
              className="reactToggleCss"
              handleDiameter={28}
              uncheckedIcon={false}
              checkedIcon={false}
              offColor={darkMode ? "#fff" : "#D9D9D9"}
              onColor="#DCA071"
              offHandleColor={darkMode ? "#01483B" : "#fff"}
              onHandleColor={darkMode ? "#01483B" : "#fff"}
              height={30}
              width={60}
              activeBoxShadow="0px 0px 1px 1px #01483B"
            />
          </label>
          <p className="text-[15px] opacity-55 mt-1">
            Enable or disable two factor authentication
          </p>
        </div>

        <div className="mt-8">
          <h2 className="font-[500] text-[18px]">Change Password</h2>
          <div className="mt-5">
            <div className="flex flex-col mb-6">
              <label className="text-[16px] mb-2 opacity-50">
                Current Password
              </label>
              <input
                type="text"
                className="h-[50px] 900px:w-[50%] w-full text-[15px] dark:text-[rgba(255,255,255,.5)] text-[rgba(0,0,0,0.5)] border-[1px] border-[#DFEAF2] rounded-[15px] outline-none bg-transparent px-3"
                placeholder="******"
              />
            </div>

            <div className="flex flex-col mb-6">
              <label className="text-[16px] mb-2 opacity-50">
                New Password
              </label>
              <input
                type="text"
                className="h-[50px] 900px:w-[50%] w-full text-[15px] dark:text-[rgba(255,255,255,.5)] text-[rgba(0,0,0,0.5)] border-[1px] border-[#DFEAF2] rounded-[15px] outline-none bg-transparent px-3"
                placeholder="*******"
              />
            </div>

            <div className="flex flex-col mb-6">
              <label className="text-[16px] mb-2 opacity-50">
                Confirm Password
              </label>
              <input
                type="text"
                className="h-[50px] 900px:w-[50%] w-full text-[15px] text-[rgba(0,0,0,0.5)] dark:text-[rgba(255,255,255,.5)] border-[1px] border-[#DFEAF2] rounded-[15px] outline-none bg-transparent px-3"
                placeholder="*******"
              />
            </div>
          </div>
        </div>
      </div>

      {is2FaPopup && (
        <>
          <div className="absolute text-[#475467] 900px:top-[35%] top-[35%] bg-white w-[75%] min-h-[200px] left-[17%] px-[20px] py-[10px] rounded-lg">
            <div className="w-full">
              <div className="flex justify-end">
                <RxCross2
                  className="cursor-pointer"
                  size={22}
                  onClick={() => setIs2FaPopup(false)}
                />
              </div>
              <p className="text-center  text-[32px] font-inter font-[700] ">
                2 Factor Authentication
              </p>
              <div className=" flex justify-center items-center">
                <p className="text-center  w-[50%] my-3">
                  A code was sent to **************@gmail.com, input the code
                  below and click verify to finish setting up your 2-Factor
                  Authentication
                </p>
              </div>

              <OTPInput length={6} onChange={handleOtpChange} />

              <div className="flex justify-center items-center w-full gap-5 pb-4 pt-3 mt-5">
                <button
                  className="w-[30%] bg-[var(--doctor-color)] text-white h-[45px] font-inter font-[600] text-[17px] rounded-lg"
                  onClick={handleVerifyOtp}
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {is2FaConfirm && (
        <>
          <div className="absolute text-[#475467] 900px:top-[35%] top-[35%] bg-white w-[75%] min-h-[200px] left-[17%] px-[20px] py-[10px] rounded-lg">
            <div className="w-full">
              <div className="flex justify-end">
                <RxCross2
                  className="cursor-pointer"
                  size={22}
                  onClick={() => set2FaConfirm(false)}
                />
              </div>
              <p className="text-center  text-[32px] font-inter font-[700] ">
                Enable 2 Factor Authentication
              </p>
              <div className=" flex justify-center items-center">
                <p className="text-center  w-[50%] my-3 text-[20px]">
                  Are You Sure You want to Enable 2 Factor Authentication
                </p>
              </div>

              <div className="flex  w-full gap-5 pb-4 pt-3 mt-5">
                <button
                  className="w-[60%] bg-[var(--doctor-color)] text-white h-[45px] font-inter font-[600] text-[17px] rounded-lg"
                  onClick={handleEnableYes}
                >
                  YES
                </button>
                <button
                  className="w-[30%] bg-[#FF5252] text-white h-[45px] font-inter font-[600] text-[17px] rounded-lg"
                  onClick={() => set2FaConfirm(false)}
                >
                  NO
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {is2Faremove && (
        <>
          <div className="absolute text-[#475467] 900px:top-[35%] top-[35%] bg-white w-[75%] min-h-[200px] left-[17%] px-[20px] py-[10px] rounded-lg">
            <div className="w-full">
              <div className="flex justify-end">
                <RxCross2
                  className="cursor-pointer"
                  size={22}
                  onClick={() => setIs2FaRemove(false)}
                />
              </div>
              <p className="text-center  text-[32px] font-inter font-[700] ">
                Disable 2 Factor Authentication
              </p>
              <div className=" flex justify-center items-center">
                <p className="text-center  w-[50%] my-3 text-[20px]">
                  Are You Sure You want to Disable 2 Factor Authentication
                </p>
              </div>

              <div className="flex  w-full gap-5 pb-4 pt-3 mt-5">
                <button
                  className="w-[60%] bg-[var(--doctor-color)] text-white h-[45px] font-inter font-[600] text-[17px] rounded-lg"
                  onClick={handleDisableYes}
                >
                  YES
                </button>
                <button
                  className="w-[30%] bg-[#FF5252] text-white h-[45px] font-inter font-[600] text-[17px] rounded-lg"
                  onClick={() => setIs2FaRemove(false)}
                >
                  NO
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Security;
