import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { TiPlus } from "react-icons/ti";
import { MdDashboard } from "react-icons/md";
import { IoSettings } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { FaCaretSquareLeft } from "react-icons/fa";
import { PiStethoscope } from "react-icons/pi";
import { IoAnalyticsOutline } from "react-icons/io5";
import { TbBrandStackshare } from "react-icons/tb";
import { useState } from "react";
import Logo from "../../../images/logo.png";
import LightLogo from "../../../images/newLogo.png";
import Topbar from "./Topbar";
import { useContext } from "react";
import GlobalStorage from "../../../Storage/ContextProvider";
import cookie from "js-cookie";

const SuperSidebar = ({ children, activeLink }) => {
  const [activeBar, setActiveBar] = useState(false);
  const { settingActive, setSettingActive, supportActive, setSupportActive } =
    useContext(GlobalStorage);
  const { darkMode } = useContext(GlobalStorage);
  const navItem = [
    {
      link: "/superadmin",
      icon: <MdDashboard size={20} />,
      title: "Dashboard",
    },
    {
      link: "/superadmin/hospitals",
      icon: <PiStethoscope size={22} />,
      title: "Hospitals",
    },
    // {
    //   link: "/superadmin/subscriptions",
    //   icon: <TbBrandStackshare className="rotate-90" size={22} />,
    //   title: "Subscriptions",
    // },
    {
      link: "/superadmin/analytics",
      icon: <IoAnalyticsOutline size={22} />,
      title: "Analytics",
    },
    {
      icon: <IoSettings size={22} />,
      title: "Settings",
      subLinks: [
        {
          link: "/superadmin/setting/subscription",
          title: "Subscriptions",
        },
        {
          link: "/superadmin/setting/notifications",
          title: "Notifications",
        },
        {
          link: "/superadmin/setting/appearances",
          title: "Appearance",
        },
      ],
    },
  ];
  const navigate = useNavigate();
  const handlesignout = () => {
    if (window !== "undefined") {
      cookie.remove("token", {
        expires: 1,
      });
    }
    if (window !== "undefined") {
      localStorage.removeItem("user");
    }
    navigate("/superadmin/login");
  };
  return (
    <>
      <div className="flex">
        {!activeBar && (
          <>
            <div className="flex flex-col p-3 min-h-screen bg-[#fff] dark:bg-[var(--doctor-color)] w-[285px] relative z-10">
              <div className="space-y-3">
                <div className="flex items-center justify-center pt-[10px]">
                  {darkMode ? (
                    <img src={Logo} className="w-[165px] h-[60px]" alt="" />
                  ) : (
                    <img
                      src={LightLogo}
                      className="w-[165px] h-[60px]"
                      alt=""
                    />
                  )}
                </div>
                {/* 
                <div className="flex justify-center pt-[40px]">
                  <button className="flex items-center justify-center bg-[var(--doctor-color)] text-[#fff] dark:bg-[#fff] w-[230px] h-[40px] rounded-full text-[16px] dark:text-[var(--doctor-color)] font-[500]">
                    <TiPlus /> New Transcript
                  </button>
                </div> */}

                <div className="flex-1">
                  <ul className="pt-2 pb-4  text-md ">
                    {navItem?.map((item) => {
                      return (
                        <>
                          {item?.link && !item?.subLinks && (
                            <li
                              className={`${
                                activeLink === item?.title
                                  ? "dark:bg-[rgba(255,207,170,1)] bg-[#DCA071] text-[#fff] dark:text-[var(--doctor-color)]"
                                  : "dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)]"
                              } rounded-md  hover:dark:bg-[rgba(255,207,170,1)] hover:bg-[#DCA071] hover:dark:text-[var(--doctor-color)] hover:text-[#fff] py-[3px] my-[12px] text-[16px] font-[400]`}
                            >
                              <Link
                                to={item?.link}
                                className="flex items-center p-2 space-x-3 rounded-md"
                              >
                                <div className="">{item?.icon}</div>
                                <span>{item?.title}</span>
                              </Link>
                            </li>
                          )}
                          {!item?.link && item?.subLinks && (
                            <>
                              <div className="mt-[10px]">
                                <li className=" cursor-pointer rounded-md dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)] py-[3px] my-[12px] text-[16px] font-[400]">
                                  <div
                                    to="/doctor/setting/general"
                                    className="flex items-center p-2 space-x-3 rounded-md"
                                    onClick={() =>
                                      setSettingActive(!settingActive)
                                    }
                                  >
                                    <div className="">{item?.icon}</div>
                                    <span>{item?.title}</span>
                                  </div>
                                </li>
                                {settingActive && (
                                  <>
                                    {item?.subLinks.map((sub) => {
                                      return (
                                        <div
                                          className={`${
                                            activeLink === sub?.title
                                              ? "dark:bg-[rgba(255,207,170,1)] bg-[#DCA071] dark:text-[var(--doctor-color)] text-[#fff]"
                                              : "dark:text-[rgba(255,255,255,.75)] text-[rgba(0,0,0,.75)]"
                                          } cursor-pointer rounded-md  pl-[50px]  hover:dark:bg-[rgba(255,207,170,1)] hover:bg-[#DCA071]  hover:text-[#fff] `}
                                        >
                                          <li className=" rounded-md  hover:dark:bg-[rgba(255,207,170,1)] hover:bg-[#DCA071] hover:dark:text-[var(--doctor-color)] hover:text-[#fff] py-[3px] my-[12px] text-[16px] font-[500]">
                                            <Link
                                              to={sub?.link}
                                              className="flex items-center p-2 space-x-3 rounded-md"
                                            >
                                              {/* <div className="">{item?.icon}</div> */}
                                              <span>{sub?.title}</span>
                                            </Link>
                                          </li>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
                <div
                  className="absolute bottom-[40px] -right-[18px] cursor-pointer font-[500] text-[16px] text-[#fff] z-[101]"
                  // onClick={() => setActiveBar(!activeBar)}
                >
                  <FaCaretSquareLeft
                    size={25}
                    className="text-[var(--doctor-color)]"
                  />
                </div>
                {/* <div className="flex justify-center items-center">
                  <div className="absolute bottom-[20px]  font-[500] text-[16px] text-[#fff]">
                    <p>© Medinclude</p>
                  </div>
                </div> */}

<div className="flex justify-center items-center mt-5">
                  <div className="absolute bottom-[20px]  font-[500] text-[16px] text-[#fff]">
                    <div
                      className="flex justify-center items-center cursor-pointer "
                      onClick={handlesignout}
                    >
                      <div className=" pt-[20px] font-[500] text-[16px] text-[rgba(0,0,0,.75)] dark:text-[#fff] my-3">
                        <p>Signout</p>
                      </div>
                    </div>
                    <p className="text-[rgba(0,0,0,.75)] dark:text-[#fff]">© Medinclude</p>
                    {/* <p>Signout</p> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="w-full">
          <div className="h-[86px] dark:bg-[var(--doctor-color)] bg-[#fff] w-full">
            <Topbar />
          </div>
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

export default SuperSidebar;
