import React, { useContext, useEffect, useRef, useState } from "react";
import PatientSidebar from "../../../Component/Patient/Layout/PatientSidebar";
import Profile from "../../../images/topProfile.png";
import { PiDownloadSimpleBold } from "react-icons/pi";
import DImage from "../../../images/patient/dashboardImg.png";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import { getCookie, isAuth } from "../../../auth/helper";
import moment from "moment";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import LightLogo from "../../../images/newLogo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import GlobalStorage from "../../../Storage/ContextProvider";

const HomeDashboardP = () => {
  const [patientReport, setPatientReport] = useState();
  const [saveReport, setSaveReport] = useState(false);
  const [currentData, setCurrentData] = useState();
  const [email, setEmail] = useState("");
  const [twoFaPopup, settwoFaPopup] = useState(false);
  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();
  const ref = useRef();
  const neRef = useRef();
  const navigate = useNavigate();
  const token = getCookie("token");
  const [refreshFa, setRefreshFa] = useState(false);

  const {
    patientProfile,
    refreshPatient,
    setRefreshPatient,
    activeTab,
    setActiveTab,
    is2FaConfirm,
    set2FaConfirm,
  } = useContext(GlobalStorage);
  function useOutsideAlerter(neRef) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSaveReport(false);
          // alert("You clicked outside of me!");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [neRef]);
  }
  useOutsideAlerter(ref);

  useEffect(() => {
    loadReport();
  }, []);
  const loadReport = () => {
    axios({
      method: "POST",
      url: `${BASE_URL}/reports/patient`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { patientId: isAuth()?._id },
    })
      .then((response) => {
        console.log("Get Patient Report", response.data);
        setPatientReport(response.data);
      })
      .catch((error) => {
        console.log("Get Patient Report Error ERROR", error.response);
      });
  };

  const handleDownloadReport = (data) => {
    setCurrentData(data);
    setSaveReport(true);
  };
  const handleNewsletter = () => {
    if (!email) {
      return toast.error("Please Enter ur email");
    }
    axios({
      method: "POST",
      url: `${BASE_URL}/patient/newsletter`,
      data: { email },
    })
      .then((response) => {
        console.log("Add NewsLetter", response.data);
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        console.log("Get Patient Report Error ERROR", error.response);
        toast.success(error.response?.data);
      });
  };
  useEffect(() => {
    if (patientProfile?.firstTime2Fa === false) {
      settwoFaPopup(true);
    } else {
      settwoFaPopup(false);
    }
  }, [patientProfile]);
  const fData = (data) => {
    return data?.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  const handleSetUpPassword = async () => {
    settwoFaPopup(false);
    const updateProfile = await axios({
      method: "GET",
      url: `${BASE_URL}/patient/updatefirstLogin`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("Hospital register Success", response);
        setRefreshPatient(!refreshPatient);
        navigate("/dashboard/setting")
        setActiveTab(2);
        set2FaConfirm(true)
      }) 
      .catch((error) => {
        console.log("Hospital Register  Error", error.response.data);
        toast.error("Something Went Wrong");
      });
  };

  const handleUpdateUpdate2Fa = async () => {
    settwoFaPopup(false);
    const updateProfile = await axios({
      method: "GET",
      url: `${BASE_URL}/patient/updatefirstLogin`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("Hospital register Success", response);
        setRefreshPatient(!refreshPatient);

        // toast.success(response?.data?.msg);
        // setRefreshPatient(!refreshPatient);
        // navigate("/superadmin");
        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Hospital Register  Error", error.response.data);
        toast.error(error.response.data);
      });
  };

  // const handleDownloadReport = async (data) => {
  //   // setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${BASE_URL}/report/downloadmedicalreport`,
  //       {
  //         originalReport: data?.originalReport,
  //         transcribedText: data?.transcribedText,
  //         nativeLanguageTranscribedText: data?.nativeLanguageTranscribedText,
  //       },
  //       {
  //         responseType: "arraybuffer",
  //       }
  //     );

  //     const pdfBuffer = response.data;
  //     const blob = new Blob([pdfBuffer], { type: "application/pdf" });
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "report.pdf");
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error("Error downloading PDF:", error);
  //   } finally {
  //     // setIsLoading(false);
  //   }
  // };
  return (
    <div>
      <PatientSidebar activeLink="Dashboard">
        <div
          className={` ${
            saveReport ? "opacity-10 cursor-not-allowed h-[200px]" : ""
          } px-5 `}
        >
          <h2 className="font-inter font-[500] 600px:text-[35px] text-[25px]  text-[#052721] dark:text-white">
            Dashboard
          </h2>

          <div className="flex justify-between items-center">
            <p className="font-[300] text-[16px]">Latest Report</p>
            <button
              className=" rounded-3xl 600px:w-[220px] w-[150px] h-[45px] dark:bg-[#FFCFAA] bg-[#DCA071] text-white dark:text-[var(--doctor-color)] font-inter font-[700] text-xs 600px:text-sm"
              onClick={() => navigate("/dashboard/myreport")}
            >
              View All Reports
            </button>
          </div>

          <div className="flex items-center 900px:flex-row flex-col gap-4 mt-3">
            {patientReport?.slice(0, 3)?.map((data, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="900px:w-[33%] w-full min-h-[190px] dark:bg-[var(--dark-primary)] bg-white rounded-md p-5"
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <div className="flex items-center 600px:gap-3 600px:flex-row flex-col gap-1 ">
                          <img
                            src={Profile}
                            className="500px:w-[40px] 500px:h-[40px] w-[30px] h-[30px]"
                            alt=""
                          />
                          <Link
                            to={`/dashboard/myreport/${data?._id}`}
                            className="dark:text-[#FFCFAA] text-[#67A599] font-inter font-[500] 500px:text-sm text-xs"
                          >
                            {data?.doctor?.fullName}
                          </Link>
                        </div>
                      </div>

                      <div
                        className="dark:bg-[#FFCFAA] bg-[#DCA071] cursor-pointer  500px:w-[40px] 500px:h-[40px] w-[30px] h-[30px] text-white dark:text-[var(--doctor-color)] rounded-full flex items-center justify-center"
                        onClick={() => handleDownloadReport(data)}
                      >
                        <PiDownloadSimpleBold className="500px:text-[25px] text-[18px]" />
                      </div>
                    </div>
                    <div>
                      {/* <h2 className="font-inter font-[500] text-[22px] pt-1 dark:text-white text-[#67A599]">
                        Migraine
                      </h2> */}
                      <button className="px-5 py-1 my-2 font-inter  text-[12px] text-[#67A599] dark:text-white dark:bg-[#67A599] bg-[#CCE1DD] rounded-md ">
                        {moment(new Date(data?.createdAt)).format(
                          "MMMM Do YYYY, h:mm:ss a "
                        )}
                      </button>
                      <p className="font-inter text-[14px] font-[400] text-[#052721] dark:text-white dark:opacity-40 pt-1 break-words	">
                        {data?.originalReport?.substring(0, 200)}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}

            {/* <div className="900px:w-[33%] w-full min-h-[190px] dark:bg-[var(--dark-primary)] bg-white rounded-md p-5">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="flex items-center 600px:gap-3 600px:flex-row flex-col gap-1 ">
                    <img
                      src={Profile}
                      className="500px:w-[40px] 500px:h-[40px] w-[30px] h-[30px]"
                      alt=""
                    />
                    <p className="dark:text-[#FFCFAA] text-[#67A599] font-inter font-[500] 500px:text-sm text-xs">
                      Dr. Raj
                    </p>
                  </div>
                  <button className="w-[100px] h-[22px] font-inter 500px:text-[10px] text-[9px] text-[#67A599] dark:text-white dark:bg-[#67A599] bg-[#CCE1DD] rounded-md ">
                    February 12, 2024
                  </button>
                </div>

                <div className="dark:bg-[#FFCFAA] bg-[#DCA071] 500px:w-[40px] 500px:h-[40px] w-[30px] h-[30px] text-white dark:text-[var(--doctor-color)] rounded-full flex items-center justify-center">
                  <PiDownloadSimpleBold className="500px:text-[25px] text-[18px]" />
                </div>
              </div>
              <div>
                <h2 className="font-inter font-[500] text-[22px] pt-1 dark:text-white text-[#67A599]">
                  Migraine
                </h2>
                <p className="font-inter text-[14px] font-[400] text-[#052721] dark:text-white dark:opacity-40 pt-1">
                  So what are these strange lights in the sky? Are they aliens
                  invading from Mars? Are the comets coming to start the next
                  ice age?
                </p>
              </div>
            </div>

            <div className="900px:w-[33%] w-full min-h-[190px] dark:bg-[var(--dark-primary)] bg-white rounded-md p-5">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="flex items-center 600px:gap-3 600px:flex-row flex-col gap-1 ">
                    <img
                      src={Profile}
                      className="500px:w-[40px] 500px:h-[40px] w-[30px] h-[30px]"
                      alt=""
                    />
                    <p className="dark:text-[#FFCFAA] text-[#67A599] font-inter font-[500] 500px:text-sm text-xs">
                      Dr. Raj
                    </p>
                  </div>
                  <button className="w-[100px] h-[22px] font-inter 500px:text-[10px] text-[9px] text-[#67A599] dark:text-white dark:bg-[#67A599] bg-[#CCE1DD] rounded-md ">
                    February 12, 2024
                  </button>
                </div>

                <div className="dark:bg-[#FFCFAA] bg-[#DCA071] 500px:w-[40px] 500px:h-[40px] w-[30px] h-[30px] text-white dark:text-[var(--doctor-color)] rounded-full flex items-center justify-center">
                  <PiDownloadSimpleBold className="500px:text-[25px] text-[18px]" />
                </div>
              </div>
              <div>
                <h2 className="font-inter font-[500] text-[22px] pt-1 dark:text-white text-[#67A599]">
                  Migraine
                </h2>
                <p className="font-inter text-[14px] font-[400] text-[#052721] dark:text-white dark:opacity-40 pt-1">
                  So what are these strange lights in the sky? Are they aliens
                  invading from Mars? Are the comets coming to start the next
                  ice age?
                </p>
              </div>
            </div> */}
          </div>

          <div className="flex gap-4 items-center 900px:flex-row  flex-col mb-10 mt-5">
            <div className="900px:w-[25%] w-full">
              <img
                src={DImage}
                className="900px:w-[100%] 900px:h-[300px] w-[100%] h-[400px] object-contain"
                alt=""
              />
            </div>
            <div className="900px:w-[75%] w-full bg-[#67A599] dark:bg-[var(--dark-primary)] text-white h-[300px]  rounded-md flex items-center  justify-center flex-col font-inter">
              <h2 className="font-[500] 900px:text-[40px] 600px:text-[30px] text-[25px] 1100px:w-[60%] w-[90%] text-center">
                Want to get the latest updates on our products?
              </h2>

              <p className="text-sm opacity-40 py-3 w-[90%] text-center">
                Subscribe to our Newsletter to be the first on the scene
              </p>

              <div className="900px:w-[430px] w-[80%] bg-white h-[50px] flex items-center px-2 rounded-md ">
                <input
                  type="text"
                  className="outline-none bg-transparent w-full text-[var(--doctor-color)] opacity-50 text-sm"
                  placeholder="Enter Your Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  className="dark:bg-[#FFCFAA] bg-[#DCA071]  dark:text-[var(--doctor-color)] w-[110px]  h-[36px] rounded-md 500px:text-sm text-xs"
                  onClick={handleNewsletter}
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>

        {saveReport && (
          <>
            <div className="absolute 900px:top-[10%] top-[15%] bg-white w-[85%] min-h-[200px] max-h-[550px]  overflow-y-scroll  left-[10%]  rounded-lg">
              <div>
                <div
                  className="text-black 600px:p-[25px] relative  rounded-2xl"
                  ref={ref}
                >
                  <div
                    id="contentPad"
                    className=" 600px:p-12 px-5 py-11 text-lg"
                  >
                    <div className="flex items-center justify-center">
                      <img src={LightLogo} alt="" />
                    </div>
                    <div className="flex justify-end my-2">
                      <p className="text-sm font-[600]">
                        Date - {currDate} {currTime}
                      </p>
                    </div>
                    <h2 className="text-lg mt-6 font-[600] border-b-[1px] border-gray-500 pb-2">
                      Medical Report
                    </h2>
                    <p className="text-[15px] mt-3 break-words">
                      {fData(currentData?.originalReport)}
                    </p>
                    <h2 className="text-xl mt-6 font-[600]  border-b-[1px] border-gray-500 pb-2">
                      Simplified Report
                    </h2>
                    <p className="text-[15px] pt-2 break-words">
                      {fData(currentData?.transcribedText)}

                      {/* {currentData?.transcribedText} */}
                    </p>
                    <h2 className="text-xl mt-6 font-[600]  border-b-[1px] border-gray-500 pb-2">
                      Translated Report:
                    </h2>
                    <p className="text-[15px] mt-3 break-words">
                      {fData(currentData?.nativeLanguageTranscribedText)}

                      {/* {currentData?.nativeLanguageTranscribedText} */}
                    </p>
                  </div>
                  <div className="w-full flex items-center justify-center gap-6 mt-6 mb-6">
                    {/* <button
                    className="flex items-center justify-center bg-[var(--doctor-color)] text-white py-3 px-12 rounded-lg"
                    onClick={() => toPDF()}
                  >
                    Save
                  </button> */}

                    <ReactToPrint
                      pageStyle={{ margin: "15px" }}
                      content={() => ref.current}
                      trigger={() => (
                        <button
                          className="flex items-center justify-center bg-[#FF5252] text-white py-3 px-12 rounded-lg"
                          // onClick={() => setSavePopup(false)}
                        >
                          Save
                        </button>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {twoFaPopup && (
          <>
            <div className="absolute text-[#475467] 900px:top-[35%] top-[35%] bg-white w-[75%] min-h-[200px] left-[17%] px-[20px] py-[10px] rounded-lg">
              <div className="w-full">
                <div className="flex justify-end">
                  <RxCross2
                    className="cursor-pointer"
                    size={22}
                    onClick={handleUpdateUpdate2Fa}
                  />
                </div>
                <p className="text-center  text-[32px] font-inter font-[700] ">
                  2 Factor Authentication
                </p>
                <div className=" flex justify-center items-center">
                  <p className="text-center  w-[50%] my-3">
                    2-Factor Authentication provides an added layer of security
                    to your account. Once it's turned on, we will ask for a
                    verification code when you log in to your MedInclude account
                  </p>
                </div>

                <div className="flex w-full gap-5 pb-4 pt-3">
                  <button
                    className="w-[70%] bg-[var(--doctor-color)] text-white h-[45px] font-inter font-[600] text-[17px] rounded-lg"
                    onClick={handleSetUpPassword}
                  >
                    Set Up Now{" "}
                  </button>
                  <button
                    className="w-[30%] bg-[#FF5252] text-white h-[45px] font-inter font-[600] text-[17px] rounded-lg"
                    onClick={handleUpdateUpdate2Fa}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </PatientSidebar>
    </div>
  );
};

export default HomeDashboardP;
