import React from "react";
import ExpireToken from "../../Component/ExpireToken";
import { useNavigate } from "react-router-dom";

const ExpireHospitalToken = () => {
  const navigate = useNavigate();
  return (
    <>
      <ExpireToken handleClick={() => navigate("/hospital/login")} />
    </>
  );
};

export default ExpireHospitalToken;
