import React, { useContext, useState } from "react";
import Hsidebar from "../../../Component/Hospital/Layout/Hsidebar";
import Card from "../../../Component/Hospital/Cards/Card";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import GlobalStorage from "../../../Storage/ContextProvider";
import { toast } from "react-toastify";

const Subscriptions = () => {
  const [togglePlan, setTogglePlan] = useState(0);
  const [isCancel, setIsCancel] = useState(false);
  const { hospitalProfile } = useContext(GlobalStorage);
  const [selectedOption, setSelectedOption] = useState("");

  // Handle change event for radio buttons
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    console.log("Options", selectedOption);
  };
  const handleCancelSubscription = () => {
    if(!selectedOption) {
      return toast.error("Please Select the reason")
    }
    const data= {
       hospitalId:hospitalProfile?._id,
       reasonForUnsubscribe:selectedOption
    }
    axios({
      method: "POST",
      url: `${BASE_URL}/cancel-subscription`,
      data:data
    })
      .then((response) => {
        // setPatietnNumber(response?.data);
       toast.success(response?.data);
       setIsCancel(false)
      })
      .catch((error) => {
        console.log("Get Patient Number  ERROR", error.response);
        toast.error(error.response?.data)
      });
  };

  return (
    <Hsidebar activeLink="Subscriptions">
      <div className="relative">
        <div
          className={` ${
            isCancel ? "h-[90vh] opacity-40 overflow-hidden" : ""
          } p-[20px]`}
        >
          <div className="flex items-center justify-between">
            <p className="text-[25px]">Manage Subscription</p>
            <div className="flex items-center gap6">
              <div
                className="w-[190px] h-[45px] bg-[#fff] rounded-full p-[7.5px] flex"
                style={{ boxShadow: "0px 10px 15px 0px #183B5633" }}
              >
                <button
                  className={`${
                    togglePlan === 0
                      ? "bg-[#349C4B] text-[#fff] "
                      : "text-[#627065]"
                  } w-[95px] h-[30px]  rounded-full`}
                  onClick={() => setTogglePlan(0)}
                >
                  Monthly
                </button>
                <button
                  className={`${
                    togglePlan === 1
                      ? "bg-[#349C4B] text-[#fff] "
                      : "text-[#627065]"
                  } w-[95px] h-[30px]  rounded-full`}
                  onClick={() => setTogglePlan(1)}
                >
                  Yearly
                </button>
              </div>
              <p className="ml-3 text-[rgba(255,255,255,.7)]">
                (10 % discount)
              </p>
            </div>

            <button className="w-[216px] h-[35px] bg-[#FFCFAA] rounded-full text-[var(--doctor-color)] font-[500] text-[14px]">
              Update Billing Information
            </button>
          </div>

          <div className="my-[30px] flex items-center justify-center gap-[15px] border-b-[1px] dark:border-[rgba(255,255,255,0.5)] border-[rgba(0,0,0,.4)] pb-[25px] ">
            <Card togglePlan={togglePlan} />
          </div>
          {/* 
        <div>
          <h3 className="text-[25px]">Pause or unsubscribe anytime</h3>
       

          <div className="mt-[15px] flex gap-[25px] items-center w-full ">
            <div className="w-[50%] h-[300px] text-[#fff] bg-[#67A599] dark:bg-[var(--dark-primary)] rounded-[15px] border-[1px] border-[#E6EDFF] p-[15px]">
              <h3 className="font-[500] text-[22px]">
                Pause your account for up to 3 months for free
              </h3>
              <div className="mt-[25px] ml-[10px]">
                <div className="flex items-center gap-[10px] my-[20px]">
                  <div className=" w-[20px] h-[20px] bg-[#FFCFAA] text-[13px] rounded-[5px] text-[var(--doctor-color)] flex items-center justify-center">
                    <FaCheck />
                  </div>
                  <p className="text-[18px]">
                    You wont be charged while your account is paused
                  </p>
                </div>
                <div className="flex items-center gap-[10px] my-[20px]">
                  <div className=" w-[20px] h-[20px] bg-[#FFCFAA] text-[13px] rounded-[5px] text-[var(--doctor-color)] flex items-center justify-center">
                    <FaCheck />
                  </div>
                  <p className="text-[18px]">
                    We will keep all your hospital staff and patient records
                  </p>
                </div>
                <div className="flex items-center gap-[10px] my-[20px]">
                  <div className=" w-[20px] h-[20px] bg-[#FFCFAA] text-[13px] rounded-[5px] text-[var(--doctor-color)] flex items-center justify-center">
                    <FaCheck />
                  </div>
                  <p className="text-[18px]">
                    Pick up right where you left off at any time
                  </p>
                </div>
              </div>

              <div className="flex items-center justify-center mt-[40px]">
                <button className="dark:bg-[#67A599] bg-[#DCA071] w-[85%] h-[35px] rounded-full text-[16px] font-[500] ">
                  Pause your account
                </button>
              </div>
            </div>

            <div className="w-[50%] h-[300px] bg-[#DCA071] dark:bg-[#FFCFAA] text-[#fff] dark:text-[#000000] rounded-[15px] border-[1px] border-[#E6EDFF] p-[15px]">
              <h3 className="font-[500] text-[22px]">
              Unsubscribe
              </h3>
              <div className="mt-[20px] ml-[10px]">
                <div className="flex items-center gap-[10px] my-[12px]">
                  <div className=" w-[20px] h-[20px] bg-[#67A599] dark:bg-[var(--doctor-color)] text-[13px] rounded-[5px] text-[var(--doctor-color)] flex items-center justify-center">
                    <FaCheck color="white" />
                  </div>
                  <p className="text-[18px]">
                    You’ll never get charged from Medinclude again
                  </p>
                </div>
                <div className="flex gap-[10px] my-[12px]">
                  <div className="w-[20px] h-[20px] bg-[#67A599] dark:bg-[var(--doctor-color)] text-[13px] rounded-[5px] text-[var(--doctor-color)] flex items-center justify-center">
                    <FaCheck color="white" />
                  </div>
                  <p className="text-[18px]">
                    We will erase all of your hospital staff and patient records
                    permanently
                  </p>
                </div>
                <div className="flex items-center gap-[10px] my-[12px]">
                  <div className=" w-[20px] h-[20px] bg-[#67A599] dark:bg-[var(--doctor-color)] text-[13px] rounded-[5px] text-[var(--doctor-color)] flex items-center justify-center">
                    <FaCheck color="white" />
                  </div>
                  <p className="text-[18px]">
                    You will need to start all over again to enjoy our services
                  </p>
                </div>
              </div>

              <div className="flex items-center justify-center mt-[30px]">
                <button className="bg-[#FF5252] w-[85%] h-[35px] rounded-full text-[16px] font-[500] text-[#fff] ">
                  Cancel your account
                </button>
              </div>
            </div>
          </div>
        </div> */}

          <div className="w-full  border rounded-[15px]  border-[#E6EDFF] bg-[var(--doctor-color)] p-6">
            <h2 className="text-center text-[24px] font-[500]">
              Cancel Your Subscription
            </h2>

            <div className="px-9 my-4 ">
              <p className=" text-center text-[18px]">
                We will keep your account and details for three months. After
                three months, your account and details will be deleted. You can
                reactivate your account within three months of cancellation or
                open a new account after three months.
              </p>
            </div>
            <div className="flex justify-center">
              <button
                className="bg-[#67A599] w-[500px] py-2 rounded-full text-[16px] font-inter font-[500]"
                onClick={() => setIsCancel(true)}
              >
                Cancel your subscription
              </button>
            </div>
          </div>
        </div>

        {isCancel && (
          <>
            <div className="absolute top-[5%] w-[60%]  pt-[25px] left-[20%] ">
              <div className=" bg-[#fff] p-6  text-[#475467] rounded-[23px] border-[1px] border-[#E6EDFF] ">
                <div className="w-[98%] flex justify-end text-[25px]  ">
                  <RxCross2
                    className="cursor-pointer"
                    onClick={() => setIsCancel(false)}
                  />
                </div>
                <h3 className="text-[20px] font-[500] text-center mb-4">
                  Why are you Cancelling?
                </h3>
                <p className="text-center">
                  We are sorry to see you go. Please share your reason for
                  cancellation.
                </p>

                <div className="mt-9 mb-3 flex flex-col gap-5">
                  <label>
                    <input
                      type="radio"
                      className="mr-3 mt-1"
                      value="Not currently using the platform"
                      checked={
                        selectedOption === "Not currently using the platform"
                      }
                      onChange={handleChange}
                    />
                    Not currently using the platform
                  </label>
                  <label>
                    <input
                      type="radio"
                      className="mr-3 mt-1"
                      value="Doesn't fit my budget"
                      checked={selectedOption === "Doesn't fit my budget"}
                      onChange={handleChange}
                    />
                    Doesn't fit my budget
                  </label>
                  <label>
                    <input
                      type="radio"
                      className="mr-3 mt-1"
                      value="Switching to alternative"
                      checked={selectedOption === "Switching to alternative"}
                      onChange={handleChange}
                    />
                    Switching to alternative
                  </label>

                  <label>
                    <input
                      type="radio"
                      className="mr-3 mt-1"
                      value="Not satisfied with the product or outputs"
                      checked={
                        selectedOption ===
                        "Not satisfied with the product or outputs"
                      }
                      onChange={handleChange}
                    />
                    Not satisfied with the product or outputs
                  </label>

                  <label>
                    <input
                      type="radio"
                      className="mr-3 mt-1"
                      value="The platform does not address my organization's needs"
                      checked={
                        selectedOption ===
                        "The platform does not address my organization's needs"
                      }
                      onChange={handleChange}
                    />
                    The platform does not address my organization's needs
                  </label>

                  <label>
                    <input
                      type="radio"
                      className="mr-3 mt-1"
                      value="Other reason"
                      checked={selectedOption === "Other reason"}
                      onChange={handleChange}
                    />
                    Other reason
                  </label>
                </div>

                <div className="mt-7 flex items-center gap-3 mb-3">
                  <button
                    className="w-[50%] border bg-[#fff] border-[#D0D5DD] py-[10px] font-inter font-[600] text-[#344054] rounded-lg"
                    onClick={() => setIsCancel(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-[50%] border bg-[var(--doctor-color)] border-[#D0D5DD] py-[10px] font-inter font-[600] text-[#fff] rounded-lg"
                    onClick={handleCancelSubscription}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Hsidebar>
  );
};

export default Subscriptions;
