import React from "react";
import SuperSidebar from "../../../Component/SuperAdmin/Layout/SuperSidebar";

const SuperNotifications = () => {
  return (
    <SuperSidebar activeLink="Notifications">
      <div className="p-[20px]">
        <h1 className="text-[25px] font-[500]">Notifications</h1>
        <p className="text-[16px] font-[300] mt-[10px] border-b-[1px] border-[var(--doctor-color)] pb-[10px]">
          Indicate how you’ll like to be notified
        </p>

        <div className="flex gap-[20px] w-full">
          <div className="w-[100%]">
            <div className="ml-[30px] mt-[20px]">
              <h2 className="text-[20px] font-[500]">Internal</h2>
              <p className="text-[16px] font-[300]">
                Set how you’ll like to receive notification from users of the
                platform within the organization
              </p>
              <div className="flex items-center py-[15px]">
                <input type="checkbox" name="business" className="notyInput" />
                <label className="text-[19px]  ml-[20px]">
                  Help and Assistance
                </label>
              </div>
              <div className="flex items-center py-[15px]">
                <input type="checkbox" name="business" className="notyInput" />
                <label className="text-[19px]  ml-[20px]">Issues Report</label>
              </div>
              <div className="flex items-center py-[15px]">
                <input type="checkbox" name="business" className="notyInput" />
                <label className="text-[19px]  ml-[20px]">
                  Support Request
                </label>
              </div>
            </div>
            <div className="ml-[30px] mt-[20px]">
              <h2 className="text-[20px] font-[500]">External</h2>
              <p className="text-[16px] font-[300]">
                Set how you’ll like to receive notification from Medinclude
              </p>
              <div className="flex items-center py-[15px]">
                <input type="checkbox" name="business" className="notyInput" />
                <label className="text-[19px]  ml-[20px]">Newsletter</label>
              </div>
              <div className="flex items-center py-[15px]">
                <input type="checkbox" name="business" className="notyInput" />
                <label className="text-[19px]  ml-[20px]">Updates</label>
              </div>
              <div className="flex items-center py-[15px]">
                <input type="checkbox" name="business" className="notyInput" />
                <label className="text-[19px]  ml-[20px]">
                  Tips & Tutorials
                </label>
              </div>
              <div className="flex items-center py-[15px]">
                <input type="checkbox" name="business" className="notyInput" />
                <label className="text-[19px]  ml-[20px]">
                  Offers & Promotions
                </label>
              </div>
            </div>
          </div>
          {/* <div className="w-[40%] h-[500px] bg-[#67A599] dark:bg-[var(--dark-primary)] mt-[30px] text-[#fff] rounded-[23px] border-[1px] border-[#E6EDFF] flex items-center justify-center flex-col">
            <h3 className="text-[20px] font-[500]">Generate Report</h3>
            <p className="text-[16px] font-[300] my-[20px]">
              Generate a report for your assessments
            </p>

            <select
              id="countries"
              className=" my-[10px] w-[70%] h-[45px] outline-none font-inter text-[14px] bg-[#49615C] rounded-full border-[1px]  border-[#fff] text-[#fff] font-[600]  px-[10px]"
            >
              <option selected>Monthly</option>
              <option value="US">Yearly</option>
              <option value="CA">Weekly</option>
              <option value="FR">Daily</option>
            </select>

            <select
              id="countries"
              className=" my-[10px] w-[70%] h-[45px] outline-none font-inter text-[14px] bg-[#49615C] rounded-full border-[1px]  border-[#fff] text-[#fff] font-[600]  px-[10px]"
            >
              <option selected>Email</option>
              <option value="US">Yearly</option>
              <option value="CA">Weekly</option>
              <option value="FR">Daily</option>
            </select>

            <button className="bg-[#67A599] w-[70%] h-[45px] rounded-full my-[10px] text-[16px]">
              admin@hospital.com
            </button>

            <button className=" mt-[60px] bg-[#DCA071] dark:bg-[#FFCFAA] w-[50%] h-[45px] rounded-full text-white dark:text-[var(--doctor-color)] text-[14px] font-inter font-[700] ">
              Generate & Export
            </button>
          </div> */}
        </div>
      </div>
    </SuperSidebar>
  );
};

export default SuperNotifications;
