import React, { useContext, useState } from "react";
import Switch from "react-switch";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import GlobalStorage from "../../../Storage/ContextProvider";
const Preferences = () => {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const { darkMode } = useContext(GlobalStorage);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const handleChange2 = (nextChecked) => {
    setChecked2(nextChecked);
  };
  const handleChange3 = (nextChecked) => {
    setChecked3(nextChecked);
  };

  const marks = [
    {
      value: 0,
    },
    {
      value: 33,
    },
    {
      value: 67,
    },
    {
      value: 100,
    },
  ];

  function valuetext(value) {
    return `${value}`;
  }

  return (
    <>
      <div className="mt-9">
        <div className="flex 900px:flex-row flex-col items-center gap-7">
          <div className="900px:w-[50%] w-full font-inter">
            <div className="flex flex-col">
              <label className="text-[16px] mb-2">Language</label>
              <input
                type="text"
                value="English"
                className="h-[50px] text-[15px] dark:text-[rgba(255,255,255,.5)] text-[rgba(0,0,0,0.5)] border-[1px] border-[#DFEAF2] rounded-[15px] outline-none bg-transparent px-3"
              />
            </div>
          </div>

          <div className="900px:w-[50%] w-full font-inter">
            <div className="flex flex-col">
              <label className="text-[16px] mb-2">Time Zone</label>
              <input
                type="text"
                value="(GMT-12:00) International Date Line West"
                className="h-[50px] text-[15px] text-[rgba(0,0,0,0.5)] dark:text-[rgba(255,255,255,.5)] border-[1px] border-[#DFEAF2] rounded-[15px] outline-none bg-transparent px-3"
              />
            </div>
          </div>
        </div>

        <div className="mt-14 flex 900px:flex-row flex-col gap-6">
          <div className="900px:w-[50%] w-full">
            <h3 className="font-[500] text-[18px]">Notification</h3>

            <div className="mt-4 flex flex-col gap-7">
              {/* <div className="flex gap-3">
                <label className="">
                  <Switch
                    onChange={handleChange}
                    checked={checked}
                    className="reactToggleCss"
                    handleDiameter={28}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offColor={darkMode ? "#fff" : "#D9D9D9"}
                    onColor="#DCA071"
                    offHandleColor={darkMode ? "#01483B" : "#fff"}
                    onHandleColor={darkMode ? "#01483B" : "#fff"}
                    height={30}
                    width={60}
                    activeBoxShadow="0px 0px 1px 1px #01483B"
                  />
                </label>
                <p className="text-[15px]">I send or receive digita currency</p>
              </div> */}
              <div className="flex gap-3">
                <label className="">
                  <Switch
                    onChange={handleChange2}
                    checked={checked2}
                    className="reactToggleCss"
                    handleDiameter={28}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offColor={darkMode ? "#fff" : "#D9D9D9"}
                    onColor="#DCA071"
                    offHandleColor={darkMode ? "#01483B" : "#fff"}
                    onHandleColor={darkMode ? "#01483B" : "#fff"}
                    height={30}
                    width={60}
                    activeBoxShadow="0px 0px 1px 1px #01483B"
                  />
                </label>
                <p className="text-[15px]">New Medical Report</p>
              </div>

              <div className="flex gap-3">
                <label className="">
                  <Switch
                    onChange={handleChange3}
                    checked={checked3}
                    className="reactToggleCss"
                    handleDiameter={28}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offColor={darkMode ? "#fff" : "#D9D9D9"}
                    onColor="#DCA071"
                    offHandleColor={darkMode ? "#01483B" : "#fff"}
                    onHandleColor={darkMode ? "#01483B" : "#fff"}
                    height={30}
                    width={60}
                    activeBoxShadow="0px 0px 1px 1px #01483B"
                  />
                </label>
                <p className="text-[15px]">
                 Newsletter
                </p>
              </div>
            </div>
          </div>

          <div className="900px:w-[50%] w-full">
            <h3 className="font-[500] text-[18px]">Font and Display</h3>
            <div className="flex 500px:flex-row flex-col 500px:gap-[55px] gap-10 mt-[30px] mb-[20px] 500px:items-center">
              <p className="text-[16px] ">Heading Font Size</p>
              <div className="500px:w-[50%] w-full mt-[10px] relative 500px:ml-6">
                <Box className="relative">
                  <Slider
                    aria-label="Restricted values"
                    defaultValue={0}
                    sx={{
                      color: darkMode ? "#fff" : "#016855",
                    }}
                    // valueLabelFormat={valueLabelFormat}
                    getAriaValueText={valuetext}
                    step={null}
                    valueLabelDisplay="off"
                    marks={marks}
                  />
                  <p className="absolute -top-[30px] -left-[10px] text-[13px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                    Aa
                  </p>
                  <p className="absolute -top-[30px] left-[31%] text-[15px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                    Aa
                  </p>
                  <p className="absolute -top-[30px] left-[65%] text-[17px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                    Aa
                  </p>
                  <p className="absolute -top-[30px] left-[98%] text-[20px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                    Aa
                  </p>
                </Box>
              </div>
            </div>
            
            <div className="flex 500px:gap-[108px] gap-10 500px:flex-row flex-col  my-[50px] 500px:items-center">
              <p className="text-[16px] ">Text Font Size</p>
              <div className="500px:w-[50%] w-full mt-[10px] relative">
                <Box className="relative">
                  <Slider
                    aria-label="Restricted values"
                    defaultValue={0}
                    sx={{
                      color: darkMode ? "#fff" : "#016855",
                    }}
                    // valueLabelFormat={valueLabelFormat}
                    getAriaValueText={valuetext}
                    step={null}
                    valueLabelDisplay="off"
                    marks={marks}
                  />
                  <p className="absolute -top-[30px] -left-[10px] text-[13px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                    Aa
                  </p>
                  <p className="absolute -top-[30px] left-[31%] text-[15px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                    Aa
                  </p>
                  <p className="absolute -top-[30px] left-[65%] text-[17px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                    Aa
                  </p>
                  <p className="absolute -top-[30px] left-[98%] text-[20px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                    Aa
                  </p>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preferences;
