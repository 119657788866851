import React, { useContext, useEffect, useState } from "react";
import Profile from "../../../images/patient/profile.png";
import { MdModeEdit } from "react-icons/md";
import GlobalStorage from "../../../Storage/ContextProvider";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import { toast } from "react-toastify";
import { getCookie } from "../../../auth/helper";
import { RxCross2 } from "react-icons/rx";
import { FaAngleDown } from "react-icons/fa6";
import { CgProfile } from "react-icons/cg";

const ProfilePatient = ({
  name,
  email,
  dob,
  handleChange,
  postalCode,
  handleCountryFocus,
  selectedCountry,
  setIsCountryOpen,
  setCountryName,
  isCountryOpen,
  countries,
  handleSelectCountry,
  handleStateFocus,
  selectedState,
  setStateName,
  isStateOpen,
  setIsStateOpen,
  states,
  handleSelectState,
  permanentAddress,
  presentAddress,
  


}) => {

  return (
    <>
      <div className="mt-9 flex 900px:flex-row flex-col gap-8">
        <div className="relative 900px:w-[10%] w-[100%]">
          {/* <img src={Profile} className="rounded-full " alt="" />
          <div className="w-[30px] h-[30px] dark:bg-[#CCE1DD] bg-[var(--doctor-color)] rounded-full flex justify-center items-center absolute top-[85px] 900px:left-[80%] left-[100px]">
            <MdModeEdit color="white" size={22} />
          </div> */}
          <div className="text-[100px]">
          <CgProfile className="" />

          </div>
        </div>
        <div className="900px:w-[45%] w-full font-inter dark:text-white text-[#67A599]">
          <div className="flex flex-col my-5">
            <label className="text-[16px] mb-2  ">Your Name </label>
            <input
              type="text"
              className="w-full h-[45px] rounded-[15px] px-4 outline-none text-[15px] dark:bg-white bg-[#CCE1DD] text-[rgba(0,0,0,.7)] placeholder:text-[rgba(0,0,0,.4)] "
              placeholder="name"
              readOnly
              value={name}
            />
          </div>

          <div className="flex flex-col my-5">
            <label className="text-[16px] mb-2 font-[400] ">Email </label>
            <input
              type="text"
              className="w-full h-[45px] rounded-[15px] px-4 outline-none text-[15px] dark:bg-white bg-[#CCE1DD] text-[rgba(0,0,0,.7)] placeholder:text-[rgba(0,0,0,.4)] "
              placeholder="email"
              readOnly
              value={email}
            />
          </div>
          <div className="flex flex-col my-5">
            <label className="text-[16px] mb-2 font-[400] ">
              Date Of Birth{" "}
            </label>
            <input
              type="date"
              className="w-full h-[45px] rounded-[15px] px-4 outline-none text-[15px] dark:bg-white bg-[#CCE1DD] text-[rgba(0,0,0,.7)] placeholder:text-[rgba(0,0,0,.4)] "
              placeholder=""
              value={dob}
              onChange={handleChange("dob")}
            />
          </div>
          {/* <div className="flex flex-col my-5">
            <label className="text-[16px] mb-2 font-[400] ">
              Permanent Address{" "}
            </label>
            <input
              type="text"
              className="w-full h-[45px] rounded-[15px] px-4 outline-none text-[15px] dark:bg-white bg-[#CCE1DD] text-[rgba(0,0,0,.7)] placeholder:text-[rgba(0,0,0,.4)] "
              placeholder="tripathi@gmail.com"
            />
          </div> */}
          <div className="flex flex-col my-5">
            <label className="text-[16px] mb-2 font-[400] ">Postal Code </label>
            <input
              type="text"
              className="w-full h-[45px] rounded-[15px] px-4 outline-none text-[15px] dark:bg-white bg-[#CCE1DD] text-[rgba(0,0,0,.7)] placeholder:text-[rgba(0,0,0,.4)] "
              placeholder="123437"
              value={postalCode}
              onChange={handleChange("postalCode")}
            />
          </div>
        </div>

        <div className="900px:w-[45%] w-full font-inter dark:text-white text-[#67A599]">
          {/* <div className="flex flex-col my-5">
            <label className="text-[16px] mb-2 ">Parent ID </label>
            <input
              type="text"
              className="w-full h-[45px] rounded-[15px] px-4 outline-none text-[15px] dark:bg-white bg-[#CCE1DD] text-[rgba(0,0,0,.4)] "
              placeholder="123e12321"
            />
          </div>

          <div className="flex flex-col my-5">
            <label className="text-[16px] mb-2 font-[400] ">Password </label>
            <input
              type="text"
              className="w-full h-[45px] rounded-[15px] px-4 outline-none text-[15px] dark:bg-white bg-[#CCE1DD] text-[rgba(0,0,0,.4)] "
              placeholder="*****"
            />
          </div> */}
          <div className="flex flex-col my-5">
            <label className="text-[16px] mb-2 font-[400] ">Country </label>
            <div className="flex flex-col gap-2 w-full relative">
              <div className="">
                <div className="py-[10px] outline-none dark:bg-white bg-[#CCE1DD] text-[rgba(0,0,0,.7)] placeholder:text-[rgba(0,0,0,.4)] rounded-[11px] border-[1px]    px-[10px] w-full  relative">
                  <input
                    type="text"
                    className="outline-none w-[80%] bg-transparent text-[#052721] placeholder-[#052721]"
                    placeholder="Country"
                    onFocus={handleCountryFocus}
                    value={selectedCountry}
                    onChange={(e) => setCountryName(e.target.value)}
                  />
                  {isCountryOpen ? (
                    <RxCross2
                      className="absolute  top-4 left-[93%] text-[#667085] cursor-pointer"
                      onClick={() => setIsCountryOpen(false)}
                    />
                  ) : (
                    <FaAngleDown
                      className="absolute  top-4 left-[93%] text-[#667085] cursor-pointer"
                      onClick={() => setIsCountryOpen(true)}
                    />
                  )}
                </div>
              </div>
              {isCountryOpen && (
                <>
                  <div className=" absolute top-8  overflow-y-scroll z-[101] left-5 max-h-[200px] min-h-[100px] bg-[white] text-black w-[85%]">
                    {countries?.map((data, index) => {
                      return (
                        <>
                          <p
                            className="ml-6 mt-3 cursor-pointer"
                            onClick={() => handleSelectCountry(data)}
                          >
                            {data}
                          </p>
                        </>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
            {/* <input
              type="text"
              className="w-full h-[45px] rounded-[15px] px-4 outline-none text-[15px] dark:bg-white bg-[#CCE1DD] text-[rgba(0,0,0,.7)] placeholder:text-[rgba(0,0,0,.4)] "
              placeholder="USA"
              value={country}
              onChange={handleChange("country")}
            /> */}
          </div>

          <div className="flex flex-col my-5">
            <label className="text-[16px] mb-2 font-[400] ">
              Region/State/Province{" "}
            </label>
            <div className="flex flex-col gap-2 w-full relative">
              <div className="">
                <div className="py-[10px] outline-none dark:bg-white bg-[#CCE1DD] text-[rgba(0,0,0,.7)] placeholder:text-[rgba(0,0,0,.4)] rounded-[11px] border-[1px]    px-[10px] w-full  relative">
                  <input
                    type="text"
                    className="outline-none w-[80%] bg-transparent text-[#052721] placeholder-[#052721]"
                    placeholder="States"
                    onFocus={handleStateFocus}
                    value={selectedState}
                    onChange={(e) => setStateName(e.target.value)}
                  />
                  {isStateOpen ? (
                    <RxCross2
                      className="absolute  top-4 left-[93%] text-[#667085] cursor-pointer"
                      onClick={() => setIsStateOpen(false)}
                    />
                  ) : (
                    <FaAngleDown
                      className="absolute  top-4 left-[93%] text-[#667085] cursor-pointer"
                      onClick={() => setIsStateOpen(true)}
                    />
                  )}
                </div>
              </div>
              {isStateOpen && (
                <>
                  <div className=" absolute top-[51%]  overflow-y-scroll left-5 max-h-[200px] min-h-[60px] bg-[white] text-black w-[85%]">
                    {states == "" && (
                      <>
                        <p className="text-center h-[70px] flex justify-center items-center">
                          No States Found
                        </p>
                      </>
                    )}
                    {states &&
                      states?.map((data, index) => {
                        return (
                          <>
                            <p
                              className="ml-6 mt-3 cursor-pointer"
                              onClick={() => handleSelectState(data)}
                            >
                              {data}
                            </p>
                          </>
                        );
                      })}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="flex flex-col my-5">
            <label className="text-[16px] mb-2 font-[400] ">
              Permanent Address{" "}
            </label>
            <input
              type="text"
              className="w-full h-[45px] rounded-[15px] px-4 outline-none text-[15px] dark:bg-white bg-[#CCE1DD] text-[rgba(0,0,0,.7)] placeholder:text-[rgba(0,0,0,.4)] "
              placeholder=""
              value={permanentAddress}
              onChange={handleChange("permanentAddress")}
            />
          </div>
          <div className="flex flex-col my-5">
            <label className="text-[16px] mb-2 font-[400] ">
              Present Address{" "}
            </label>
            <input
              type="text"
              className="w-full h-[45px] rounded-[15px] px-4 outline-none text-[15px] dark:bg-white bg-[#CCE1DD] text-[rgba(0,0,0,.7)] placeholder:text-[rgba(0,0,0,.4)] "
              placeholder=""
              value={presentAddress}
              onChange={handleChange("presentAddress")}
            />
          </div>
        </div>
      </div>
      {/* <button
        className="w-[160px] h-[45px] rounded-full text-white bg-[#DCA071]"
        onClick={handleUpdateProfile}
      >
        Update
      </button> */}
    </>
  );
};

export default ProfilePatient;
