import React from "react";
import DoctorSidebar from "../../../Component/Doctor/Layout/DoctorSidebar";
import ResourceImage from "../../../images/resource1.png";

const Resources = () => {
  return (
    <DoctorSidebar activeLink="Resources">
      <div className="p-[20px] dark:text-[#fff] text-[#052721]">
        <div className=" border-b border-[var(--doctor-color)] dark:text-[#fff] pb-[15px]">
          <h2 className="font-[500] text-[25px]">Resources</h2>
        </div>
        <div className="py-[20px]">
          <h3 className="text-[20px]">Tutorial Videos</h3>

          <div className="mt-[25px] flex flex-wrap gap-[20px]">
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="py-[20px]">
          <h3 className="text-[20px]">Resources</h3>
          <div className="mt-[25px] flex flex-wrap gap-[20px]">
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
            <div className=" w-[227px] h-[227px] resourceBackground p-[10px]">
              <div className="w-full h-full flex justify-end  flex-col">
                <h3 className="text-[#FFFFFF] font-inter font-[600] text-[16px]">
                  How to get started with Ignite
                </h3>
                <p className="text-[12px] font-inter font-[500] text-[#888888]">
                  4 hours
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DoctorSidebar>
  );
};

export default Resources;
