import React from "react";
import DoctorSidebar from "../../../Component/Doctor/Layout/DoctorSidebar";
import Hsidebar from "../../../Component/Hospital/Layout/Hsidebar";

const ContactAdmin = () => {
  return (
    <Hsidebar activeLink="Contact Medinclude">
      <div className="p-[20px] text-[#052721] dark:text-[#fff]">
        <div className=" border-b border-[var(--doctor-color)] dark:text-[#fff] pb-[15px]">
          <div>
            <h2 className="font-[500] text-[25px]">Contact Us</h2>
            <p className="font-[300] text-[16px] text-[var(--doctor-color)] dark:text-[#fff]">
              Customization settings
            </p>
          </div>
        </div>
        <div className="mt-[35px]">
          <h3 className="text-[20px]">Contact Form</h3>
          <div className="flex my-[30px] items-center justify-start gap-[70px]">
            <div className="flex gap-[30px] items-center">
              <label className="text-[20px]">Hospital Name</label>
              <input
                type="text"
                value="Hospital"
                className="w-[276px] h-[42px] bg-[#CCE1DD] dark:bg-[var(--dark-primary)] outline-none rounded-lg px-[15px] readonly text-[16px]"
              />
            </div>
            <div className="flex gap-[30px] items-center">
              <label className="text-[20px]">Hospital Code</label>
              <input
                type="text"
                value="KSA123"
                className="w-[276px] h-[42px] bg-[#CCE1DD] dark:bg-[var(--dark-primary)] outline-none rounded-lg px-[15px] readonly text-[16px]"
              />
            </div>
          </div>
          <div className="flex gap-[45px] items-center">
            <label className="text-[20px]">Admin Email</label>
            <input
              type="text"
              value="Admin@ksa.ca"
              className="w-[73%] h-[42px] bg-[#CCE1DD] dark:bg-[var(--dark-primary)] outline-none rounded-lg px-[15px] readonly text-[16px]"
            />
          </div>
          <div className="flex items-center my-[30px] gap-[90px]">
            <label className="text-[20px]"> Subject</label>
            <input
              type="text"
              className="w-[80%] h-[42px] outline-none border-[#CCE1DD] border-[2px] bg-transparent rounded-lg px-[15px] text-[16px]"
              placeholder="Data Recovery"
            />
          </div>
          <div className="flex items-start my-[30px] gap-[90px]">
            <label className="text-[20px]"> Details</label>

            <textarea
              name=""
              id=""
              cols="30"
              rows="20"
              className="w-[100%] h-[200px] outline-none border-[#CCE1DD] border-[2px] bg-transparent rounded-lg px-[15px] text-[16px]"
            ></textarea>
          </div>
          <div className="flex justify-end">
            <button className="w-[216px] h-[53px] bg-[#016855] text-[#E7F4F2] dark:bg-[#FFCFAA] dark:text-[var(--dark-primary)] text-[23px] rounded-lg">
              Submit
            </button>
          </div>
        </div>
      </div>
    </Hsidebar>
  );
};

export default ContactAdmin;
