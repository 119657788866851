import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ExpireToken from "../../Component/ExpireToken";

const ExpireDoctorToken = () => {
  const navigate = useNavigate();

  return (
    <>
      <ExpireToken handleClick={() => navigate("/doctor/login")} />
    </>
  );
};

export default ExpireDoctorToken;
