import React from "react";
import DoctorSidebar from "../../../Component/Doctor/Layout/DoctorSidebar";
import Appearances from "./Appearances";

const Prefrences = () => {
  return (
    <DoctorSidebar activeLink="Prefecences">
      <div className="p-[20px] text-[#052721] dark:text-[#fff]">
        <div className=" border-b border-[var(--doctor-color)] pb-[15px]">
          <div>
            <h2 className="font-[500] text-[25px]">Preferences</h2>
            <p className="font-[300] text-[16px] text-[var(--doctor-color)] dark:text-[#fff]">
              Customization settings
            </p>
          </div>
        </div>
        <div className="py-[10px]">
          <h2 className=" text-[20px] font-[500]">Raw Data</h2>

          <div className="flex gap-[30px] item-center justify-between w-[100%] my-[20px]">
            <label for="time" className=" text-[20px]  ">
              Default Languages
            </label>
            <select
              id="time"
              className=" caret-slate-700 outline-none bg-[#CCE1DD] dark:bg-[var(--dark-primary)] h-[42px]  px-[20px]  text-[16px] w-[85%] rounded-md"
            >
              <option selected>English(CA)</option>
              <option value="US">United States</option>
              <option value="CA">Canada</option>
              <option value="FR">France</option>
              <option value="DE">Germany</option>
            </select>
          </div>

          <h2 className=" mt-[45px] text-[20px] font-[500]">Transcribed Data</h2>

          <div className="flex gap-[30px] item-center justify-between w-[100%] my-[20px]">
            <label for="time" className=" text-[20px] ">
              Default Languages
            </label>
            <select
              id="time"
              className=" caret-slate-700 outline-none bg-[#CCE1DD] dark:bg-[var(--dark-primary)] h-[42px]  px-[20px]  text-[16px] w-[85%] rounded-md"
            >
              <option selected>English(CA)</option>
              <option value="US">United States</option>
              <option value="CA">Canada</option>
              <option value="FR">France</option>
              <option value="DE">Germany</option>
            </select>
          </div>
        </div>
      </div>
      <Appearances />
    </DoctorSidebar>
  );
};

export default Prefrences;
