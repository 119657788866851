import React, { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { FaFilter } from "react-icons/fa6";
import PatientImage from "../../images/patientImage.png";
import { useNavigate } from "react-router-dom";
import SuperSidebar from "../../Component/SuperAdmin/Layout/SuperSidebar";
import GlobalStorage from "../../Storage/ContextProvider";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { ToastContainer, toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import { FaAngleDown } from "react-icons/fa6";
const SuperHospital = () => {
  // const [createHospital, setCreateHospital] = useState(false);
  const { createHospital, setCreateHospital } = useContext(GlobalStorage);
  const [refreshHospital, setRefreshHospital] = useState(false);
  const [hospitals, setHospitals] = useState();
  const [countries, setCoutries] = useState();
  const [states, setState] = useState([]);
  const [countryName, setCountryName] = useState("default");
  const [stateName, setStateName] = useState("default");
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [isStateOpen, setIsStateOpen] = useState(false);
  const [updatedHospitals, setUpdatedHospitals] = useState();
  const [isResendLink, setIsResetLink] = useState(false);
  const [resendData, setResendData] = useState();
  const [resendEmail, setResendEmail] = useState();

  const [values, setValues] = useState({
    email: "",
    phone: "",
    hospitalName: "",
    address: "",
    state: "",
    country: "",
    subscriptionType: "",
  });

  const { email, phone, hospitalName, subscriptionType } = values;

  useEffect(() => {
    loadHospitals();
  }, [refreshHospital]);

  const loadHospitals = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/superadmin/hospitals`,
    })
      .then((response) => {
        console.log("Get Hospitals", response);
        setHospitals(response.data);
      })
      .catch((error) => {
        console.log("Get Hospitals ERROR", error.response);
      });
  };
  const handleChange = (name) => (event) => {
    // console.log(event.target.value);
    setValues({ ...values, [name]: event.target.value });
  };

  useEffect(() => {
    const updatedObjects = hospitals?.map((object) => {
      const createdAtMilliseconds = new Date(object?.updatedAt).getTime();
      const fiveMinutesLater = new Date(
        // createdAtMilliseconds + 3 * 60000 * 60 * 24
        createdAtMilliseconds + 3 * 60000 * 60 * 24
      );
      const currentTime = new Date();
      if (object?.status === "Pending" && currentTime >= fiveMinutesLater) {
        return {
          ...object,
          status: "Expired",
        };
      }
      return object;
    });
    setUpdatedHospitals(updatedObjects);
  }, [hospitals]);

  const tableData = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
  ];

  useEffect(() => {
    if (countryName == "") {
      setCountryName("default");
    }
    loadCountry();
  }, [countryName]);

  const loadCountry = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/search/cs?country=${countryName}`,
    })
      .then((response) => {
        console.log("Get Coutries", response);
        // setHospitals(response.data);
        setCoutries(response.data);
      })
      .catch((error) => {
        console.log("Get Hospitals ERROR", error.response);
      });
  };

  useEffect(() => {
    if (stateName == "") {
      setStateName("default");
    }
    loadState();
  }, [stateName, selectedCountry]);

  const loadState = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/search/cs?country=${selectedCountry}&state=${stateName}`,
    })
      .then((response) => {
        console.log("Get States", response);
        // setHospitals(response.data);
        setState(response.data);
      })
      .catch((error) => {
        console.log("Get State ERROR", error.response);
      });
  };
  const handleSelectCountry = (data) => {
    setSelectedCountry(data);
    // setCountryName(data)
    setIsCountryOpen(false);
  };
  const handleSelectState = (data) => {
    setSelectedState(data);
    // setCountryName(data)
    setIsStateOpen(false);
  };
  const handleCountryFocus = () => {
    setIsCountryOpen(true);
    setSelectedCountry();
  };
  const handleStateFocus = () => {
    setIsStateOpen(true);
    setSelectedState();
  };

  const handleAddHospital = async () => {
    const data = {
      email,
      phone,
      hospitalName,
      state: selectedState,
      country: selectedCountry,
      // subscriptionType,
    };

    const LOGINData = await axios({
      method: "POST",
      url: `${BASE_URL}/hospital/register`,
      data: data,
    })
      .then((response) => {
        console.log("Hospital register Success", response);
        toast.success(response?.data?.msg);
        setValues({
          ...values,
          email: "",
          phone: "",
          hospitalName: "",
          address: "",
          state: "",
          country: "",
          // subscriptionType: "",
        });
        setCreateHospital(false);
        setRefreshHospital(!refreshHospital);
        // navigate("/superadmin");
        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Hospital Register  Error", error.response.data);
        toast.error(error.response.data);
      });
  };
  const navigate = useNavigate();

  const handleCloseHospital = () => {
    setCreateHospital(false);
    setIsCountryOpen(false);
    setIsStateOpen(false);
  };

  const handleResend = (data) => {
    setIsResetLink(true);
    setResendData(data);
    setResendEmail(data?.email);
  };

  const handleResendLink = async () => {

    if(!resendEmail) {
      return toast.error("Please Enter the email")
    }
    const data = {
     
      hospitalId:resendData?._id,
      email:resendEmail
    };

    const activateAccount = await axios({
      method: "POST",
      url: `${BASE_URL}/hospital/resend-link`,
      data: data,
    })
      .then((response) => {
        console.log("Token Resend Success", response);
        toast.success(response?.data.msg);
        setIsResetLink(false);
        setRefreshHospital(!refreshHospital)
        // setRefreshDepartment(!refreshDepartment);
        //   navigate("/doctor/login");
        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Token Resend Error", error.response.data);
        toast.error(error.response.data);
      });
  };
  return (
    <SuperSidebar activeLink="Hospitals">
      <div
        className={` blur ${
          createHospital || isResendLink
            ? "bg-[rgba(0,0,0,.5)] dark:opacity-[.2] opacity-70 overflow-y-hidden max-h-[88vh] cursor-not-allowed block w-[80%] overflow-x-hidden"
            : "hidden"
        }
        absolute z-10
          `}
      ></div>
      <div
        className={` ${
          createHospital || isResendLink
            ? "bg-[rgba(0,0,0,.5)] dark:opacity-[.2] opacity-70 overflow-y-hidden h-[88vh] cursor-not-allowed "
            : ""
        } p-[20px]`}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-[25px] font-[500]">Hospitals</h1>
          <button
            className="rounded-full w-[162px] h-[40px] font-[500] bg-[#DCA071] dark:bg-[#FFCFAA] flex items-center justify-center gap-2 text-[#fff] dark:text-[var(--doctor-color)]"
            onClick={() => setCreateHospital(true)}
          >
            <FaPlus /> New Hospital
          </button>
        </div>
        <div className="flex w-[100%] gap-[25px] mt-[35px] mb-[15px]">
          <div className="flex items-center h-[45px] w-[85%]  bg-[#67A599] rounded-full relative text-[#fff]  ">
            <CiSearch className="absolute left-[10px]" />
            <input
              className="bg-transparent w-full outline-none ml-[35px] mr-[10px] placeholder-white"
              type="text"
              placeholder="Search Hospitals"
            />
          </div>
          <div className="w-[15%]">
            <button className=" rounded-full flex items-center justify-center text-[#fff] bg-[var(--dark-secondary)] h-[46px] w-full">
              <FaFilter /> Filter
            </button>
          </div>
        </div>

        {/* <div className="relative overflow-x-auto rounded-full"> */}

        <table className="w-full text-left rtl:text-right ">
          <thead className="dark:bg-[var(--dark-primary)] bg-[#67A599] text-[#CCE1DD] dark:text-[#CCE1DD] text-[18px]  rounded-full">
            <tr className="">
              <th scope="col" className="p-4 rounded-l-full">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="tableInput"
                  />
                </div>
              </th>
              <th scope="col" className="w-[350px] font-[500] ">
                Hospital Name
              </th>
              {/* <th scope="col" className="px-4 py-3 font-[500]">
                Hospital Code
              </th> */}

              <th scope="col" className="px-4 py-3 font-[500]">
                Email
              </th>
              <th scope="col" className="px-4 py-3 font-[500]">
                Subscription
              </th>
              <th scope="col" className="px-4 py-3 rounded-r-full font-[500]">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="mt-[60px]">
            {updatedHospitals?.map((data, index) => {
              return (
                <>
                  <tr
                    key={index}
                    className="border-b-[1px] border-[rgba(119,119,119,.2)] h-[95px] text-[17px]"
                  >
                    <td className="w-4 p-4">
                      <div className="flex items-center">
                        <input
                          id="checkbox-all-search"
                          type="checkbox"
                          className="tableInput"
                        />
                      </div>
                    </td>
                    <td className="w-[350px]">
                      <div className="flex items-center gap-[15px]">
                        <img
                          src={PatientImage}
                          className="w-[65px] h-[65px] rounded-full"
                          alt=""
                        />
                        <div className="">
                          <p className="text-[17px]">{data?.hospitalName}</p>
                          <span className="text-[14px] text-[#DCA071] dark:text-[#FFCFAA]">
                            Last Login: 1 hour ago
                          </span>
                        </div>
                      </div>
                    </td>
                    {/* <td className="px-4 ">onoLogy</td> */}

                    <td className="px-4 ">{data?.email}</td>
                    <td className="px-4 ">{data?.subscriptionType}</td>
                    <td className="flex items-center h-full justify-center px-6 py-4 gap-[20px] mt-[12px]">
                      {data?.status == "Expired" && (
                        <button
                          className="bg-yellow-400 text-[#FFFFF] w-[122px] h-[39px] text-[18px] rounded-full"
                          onClick={() => handleResend(data)}
                        >
                          {data?.status}
                        </button>
                      )}

                      {data?.status == "Pending" && (
                        <button
                          className="bg-yellow-400 text-[#FFFFF] w-[122px] h-[39px] text-[18px] rounded-full"
                          onClick={() => handleResend(data)}
                        >
                          {data?.status}
                        </button>
                      )}
                      {data?.status == "Active" && (
                        <button className="w-[122px] h-[39px] bg-[#1CB8EA] rounded-full text-[18px] font-[500]">
                          Active
                        </button>
                      )}
                      {data?.status == "InActive" && (
                        <button
                          className="bg-[#FF0000] dark:text-[#FFFFF] w-[122px] h-[39px] text-[18px] rounded-full"
                          // onClick={() => handleViewPatient(data)}
                        >
                          Inactive
                        </button>
                      )}
                      <button
                        className="w-[122px]  h-[39px] bg-[#DCA071] dark:bg-[#FFCFAA] text-[#fff] dark:text-[var(--doctor-color)] rounded-full text-[18px] font-[500]"
                        onClick={() =>
                          navigate(`/superadmin/hospital/${data?._id}`)
                        }
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* </div> */}

      {createHospital && (
        <>
          <div className="absolute top-[120px]  left-[36%] w-[45%] z-20 ">
            <button className="w-[100%] bg-[#67A599] dark:bg-[var(--doctor-color)] text-[#fff] h-[50px] rounded-[20px] font-[500] text-[20px]">
              Activate New Hospital
            </button>
            <div className="mt-[15px] bg-[#CCE1DD] rounded-[20px]  px-[20px] py-[35px] ">
              <input
                type="text"
                className="w-full h-[45px]  text-[16px] rounded-[11px] outline-none bg-transparent border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                placeholder=" Hospital Name "
                onChange={handleChange("hospitalName")}
                value={hospitalName}
              />
              {/* <input
                type="text"
                className="w-full h-[45px] my-[20px] text-[16px] rounded-[11px] outline-none bg-transparent border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                placeholder=" Address"
                value={address}
                onChange={handleChange("address")}
              /> */}
              <div className="flex items-center gap-[15px] my-[20px] w-full">
                <div className="flex flex-col gap-2 w-[50%]">
                  <div className="relative">
                    <div className="py-[10px] outline-none bg-transparent rounded-[11px] border-[1px]  border-[rgba(0,0,0,0.25)]  px-[10px] w-full  relative">
                      <input
                        type="text"
                        className="outline-none w-[80%] bg-transparent text-[#052721] placeholder-[#052721]"
                        placeholder="Country"
                        onFocus={handleCountryFocus}
                        value={selectedCountry}
                        onChange={(e) => setCountryName(e.target.value)}
                      />
                      {isCountryOpen ? (
                        <RxCross2
                          className="absolute  top-4 left-[93%] text-[#667085] cursor-pointer"
                          onClick={() => setIsCountryOpen(false)}
                        />
                      ) : (
                        <FaAngleDown
                          className="absolute  top-4 left-[93%] text-[#667085] cursor-pointer"
                          onClick={() => setIsCountryOpen(true)}
                        />
                      )}
                    </div>
                  </div>
                  {isCountryOpen && (
                    <>
                      <div className=" absolute top-[51%]  overflow-y-scroll left-5 max-h-[200px] min-h-[100px] bg-[white] text-black w-[45%]">
                        {countries?.map((data, index) => {
                          return (
                            <>
                              <p
                                className="ml-6 mt-3 cursor-pointer"
                                onClick={() => handleSelectCountry(data)}
                              >
                                {data}
                              </p>
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
                {/* <select
                  id="state"
                  className="w-full  h-[45px] outline-none bg-transparent rounded-[11px] border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                  // value={state}
                  // onChange={handleChange("state")}
                >
                  <option selected>region/ State/ Province</option>
                  <option value="US">United States</option>
                  <option value="CA">Canada</option>
                  <option value="FR">France</option>
                  <option value="DE">Germany</option>
                </select> */}

                <div className="flex flex-col gap-2 w-[50%]">
                  <div className="relative">
                    <div className="py-[10px] outline-none bg-transparent rounded-[11px] border-[1px]  border-[rgba(0,0,0,0.25)]  px-[10px] w-full  relative">
                      <input
                        type="text"
                        className="outline-none w-[80%] bg-transparent text-[#052721] placeholder-[#052721]"
                        placeholder="Region/State/province"
                        onFocus={handleStateFocus}
                        value={selectedState}
                        onChange={(e) => setStateName(e.target.value)}
                      />
                      {isStateOpen ? (
                        <RxCross2
                          className="absolute  top-4 left-[93%] text-[#667085] cursor-pointer"
                          onClick={() => setIsStateOpen(false)}
                        />
                      ) : (
                        <FaAngleDown
                          className="absolute  top-4 left-[93%] text-[#667085] cursor-pointer"
                          onClick={() => setIsStateOpen(true)}
                        />
                      )}
                    </div>
                  </div>

                  {isStateOpen && (
                    <>
                      <div className=" absolute top-[51%]  overflow-y-scroll left-[52%] max-h-[200px] min-h-[60px] bg-[white] text-black w-[45%]">
                        {states == "" && (
                          <>
                            <p className="text-center h-[70px] flex justify-center items-center">
                              No States Found
                            </p>
                          </>
                        )}
                        {states &&
                          states?.map((data, index) => {
                            return (
                              <>
                                <p
                                  className="ml-6 mt-3 cursor-pointer"
                                  onClick={() => handleSelectState(data)}
                                >
                                  {data}
                                </p>
                              </>
                            );
                          })}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="flex items-center gap-[15px] ">
                <input
                  type="text"
                  className="w-full h-[45px] mb-[20px] text-[16px] rounded-[11px] outline-none bg-transparent border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                  placeholder=" Email Address"
                  value={email}
                  onChange={handleChange("email")}
                />
                <input
                  type="text"
                  className="w-full h-[45px] mb-[20px] text-[16px] rounded-[11px] outline-none bg-transparent border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={handleChange("phone")}
                />
              </div>
              {/* <div className="flex items-center gap-[15px] mb-[20px]">
                <select
                  id="subscription"
                  className="w-full  h-[45px] outline-none bg-transparent rounded-[11px] border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                  value={subscriptionType}
                  onChange={handleChange("subscriptionType")}
                >
                  <option selected value="">
                    Subscription Type
                  </option>
                  <option value="Free">FREE</option>
                  <option value="Provider Pro">Provider Pro</option>
                  
                </select>
              </div> */}

              <div className=" mt-[30px] flex items-center gap-[20px] font-[600] font-inter text-[21px] text-[#fff]">
                <button
                  className="w-[70%] h-[50px] rounded-[37px] bg-[#DDBEA6]"
                  onClick={handleAddHospital}
                >
                  Create Profile
                </button>
                <button
                  className="w-[30%] h-[50px] rounded-[37px] bg-[#FF0000]"
                  onClick={handleCloseHospital}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}

{isResendLink && (
          <>
            <div className="absolute top-[150px]  left-[36%] w-[45%] z-20  ">
              <button className="w-[100%] bg-[#67A599] dark:bg-[var(--doctor-color)] text-[#fff] h-[50px] rounded-[20px] font-[500] text-[20px]">
                Resend Link
              </button>

              <div className="mt-[15px] bg-[#CCE1DD] rounded-[20px]  px-[20px] py-[35px] ">
                <input
                  type="text"
                  className="w-full h-[45px] my-[20px] text-[16px] rounded-[11px] outline-none bg-transparent border-[1px] placeholder-[#052721] border-[rgba(0,0,0,0.25)] text-[#052721] px-[10px]"
                  placeholder="Email Address"
                  value={resendEmail}
                  onChange={(e) => setResendEmail(e.target.value)}
                />
                <div className=" mt-[30px] flex items-center gap-[20px] font-[600] font-inter text-[21px] text-[#fff]">
                  <button
                    className="w-[70%] h-[50px] rounded-[37px] bg-[#DDBEA6] "
                    onClick={handleResendLink}
                  >
                    Resend Link
                  </button>
                  <button
                    className="w-[30%] h-[50px] rounded-[37px] bg-[#FF0000]"
                    onClick={() => setIsResetLink(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
    </SuperSidebar>
  );
};

export default SuperHospital;
