import React, { useContext, useEffect, useState } from "react";
import Hsidebar from "../../Component/Hospital/Layout/Hsidebar";
import { PiStethoscope } from "react-icons/pi";
import { TbBrandStackshare } from "react-icons/tb";
import { FaArrowUp } from "react-icons/fa6";
import { FaHospitalUser } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import GlobalStorage from "../../Storage/ContextProvider";
import { toast } from "react-toastify";
import axios from "axios";
import BASE_URL from "../../baseUrl";

const HospitalDashboardHome = () => {
  const navigate = useNavigate();
  const {
    createDoctor,
    setCreateDoctor,
    createDepartment,
    setCreateDepartment,
    hospitalProfile,
    hospitalDepartment,
    hospitalDoctors,
  } = useContext(GlobalStorage);
  const [assignedAccounts, setAssignedAccounts] = useState();
  const [pendingAccounts, setPendingAccounts] = useState(0);
  const [totalPatients, setTotalPatients] = useState(0);
  const [totalDoctors, setTotalDoctors] = useState(0);
  const [totalDepartments, setTotalDepartments] = useState(0);
  const [hospitalAnalytics, setHospitalAnalytics] = useState();
  const [transcriptionData, setTranscriptionData] = useState();

  const handleNewDoctor = () => {
    if (hospitalDepartment.length <= 0) {
      return toast.error("Add Department First");
    }
    navigate("/hospital/doctors");
    setCreateDoctor(true);
  };
  const handleNewDepartment = () => {
    navigate("/hospital/departments");
    setCreateDepartment(true);
  };
  useEffect(() => {
    const sum = hospitalDepartment?.reduce(
      (acc, curr) => acc + curr?.assignedAccount,
      0
    );
    setAssignedAccounts(sum);
  }, [hospitalDepartment]);
  useEffect(() => {
    const filteredData = hospitalDoctors?.filter(
      (item) => item.status != "Active"
    );
    setPendingAccounts(filteredData?.length);
  }, []);
  useEffect(() => {
    loadPatientNumber();
  }, [hospitalProfile]);
  const loadPatientNumber = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/analytics/${hospitalProfile?._id}`,
    })
      .then((response) => {
        // setPatietnNumber(response?.data);
        console.log("Hospital Analytics", response?.data);
        setTotalPatients(response?.data?.totalPatients);
        setTotalDoctors(response?.data?.totalDoctors);
        setTotalDepartments(response?.data?.totalDepartments);
        setHospitalAnalytics(response?.data);
      })
      .catch((error) => {
        console.log("Get Patient Number  ERROR", error.response);
      });
  };
  useEffect(() => {
    loadTranscriptionData();
  }, [hospitalProfile]);
  const loadTranscriptionData = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/transcription-analytics/${hospitalProfile?._id}`,
    })
      .then((response) => {
        // setPatietnNumber(response?.data);
        setTranscriptionData(response.data);
        console.log(
          "Hospital Analytics",
          response?.data[0].transAndTokensList[0]
        );
      })
      .catch((error) => {
        console.log("Get Patient Number  ERROR", error.response);
      });
  };
  return (
    <Hsidebar activeLink="Dashboard">
      <div className="p-[20px] dark:text-[#fff]">
        <h1 className="font-[500] text-[25px]">Dashboard</h1>
        <div className="w-[100%] text-[#fff] h-[210px] rounded-[28px] border-[1px] border-[#E6EDFF] my-[15px] bg-[#67A599] dark:bg-[var(--dark-primary)] flex ">
          <div className="w-[25%] border-r-[1px] border-[#E6EDF] my-[25px] px-[55px] flex  justify-center flex-col">
            <div className=" w-full flex items-center justify-between ">
              <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                {totalDepartments}
              </p>
              <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                <TbBrandStackshare
                  className="rotate-90 text-[#67A599]"
                  size={20}
                />
              </div>
            </div>
            <p className="font-outfit font-[400] text-[16px]">
              Total Departments
            </p>
            <div className="flex gap-[10px] items-center font-outfit">
              {hospitalAnalytics?.departmentIncrease < 0 ? (
                <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
              ) : (
                <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
              )}

              {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                
              </p> */}
              <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                {Math.round(Math.abs(hospitalAnalytics?.departmentIncrease))}%
                &nbsp; this Month
              </p>
            </div>
          </div>
          <div className="w-[25%] border-r-[1px] border-[#E6EDF] my-[25px] px-[55px] flex  justify-center flex-col">
            <div className=" w-full flex items-center justify-between ">
              <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                {totalDoctors}
              </p>
              <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                <PiStethoscope className=" text-[#67A599]" size={20} />
              </div>
            </div>
            <p className="font-outfit font-[400] text-[16px]">Total Doctors</p>
            <div className="flex gap-[10px] items-center font-outfit">
              {hospitalAnalytics?.doctorIncrease < 0 ? (
                <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
              ) : (
                <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
              )}
              {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                -
              </p> */}
              <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                {Math.round(Math.abs(hospitalAnalytics?.doctorIncrease))}%
                &nbsp; this Month
              </p>
            </div>
          </div>
          <div className="w-[25%] border-r-[1px] border-[#E6EDF] my-[25px] px-[55px] flex  justify-center flex-col">
            <div className=" w-full flex items-center justify-between ">
              <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                {totalPatients}
              </p>
              <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                <FaHospitalUser className=" text-[#67A599]" size={20} />
              </div>
            </div>
            <p className="font-outfit font-[400] text-[16px]">Total Patients</p>
            <div className="flex gap-[10px] items-center font-outfit">
              {hospitalAnalytics?.patientIncrease < 0 ? (
                <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
              ) : (
                <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
              )}

              {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                -
              </p> */}
              <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                {/* +3.01% this week */}
                {Math.round(Math.abs(hospitalAnalytics?.patientIncrease))}%
                &nbsp; this Month
              </p>
            </div>
          </div>
          <div className="w-[25%] my-[25px] px-[55px] flex  justify-center flex-col">
            <div className=" w-full flex items-center justify-between ">
              <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                {transcriptionData?.totalTranscriptions}
              </p>
              <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                <PiStethoscope className=" text-[#67A599]" size={20} />
              </div>
            </div>
            <p className="font-outfit font-[400] text-[16px]">
              Total Transcript
            </p>
            <div className="flex gap-[10px] items-center font-outfit">
            {transcriptionData?.transcriptionChange < 0 ? (
                <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
              ) : (
                <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
              )}
              {/* <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" /> */}
              {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                -
              </p> */}
              <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                {/* -0.91% this week */}
                {Math.round(Math.abs(transcriptionData?.transcriptionChange))}%
                &nbsp; this Month
              </p>
            </div>
          </div>
        </div>

        <div className="w-[55%] flex justify-between items-center my-[25px]">
          <h2>Analytics</h2>
          <button
            className="h-[48px] w-[210px] bg-[#DCA071] dark:bg-[#FFCFAA] text-[#fff] dark:text-[var(--doctor-color)] rounded-full font-[500] text-[14px] "
            onClick={() => navigate("/hospital/setting/subscription")}
          >
            Manage Subscription Plan
          </button>
        </div>
        <div className="w-[100%] flex gap-[55px]">
          <div className="w-[58%] h-[460px] flex border-[1px] border-[#E6EDFF] rounded-[25px] text-[#fff]">
            <div className="w-[50%] bg-[#67A599] dark:bg-[var(--dark-primary)] rounded-l-[25px]">
              <div className="h-[153px] border-b-[1px] border-[#E6EDFF] flex items-center justify-end px-[15px]">
                <p className="text-[28px] font-[600]">TOTAL ACCOUNTS</p>
              </div>
              <div className="h-[153px] border-b-[1px] border-[#E6EDFF] flex items-center justify-end px-[15px]">
                <p className="text-[28px] font-[600]">ACCOUNT USED</p>
              </div>
              <div className="h-[153px]  flex items-center justify-end px-[15px]">
                <p className="text-[28px] font-[600]">ACCOUNT UNUSED</p>
              </div>
            </div>
            <div className="w-[50%] bg-[#DCA071] dark:bg-[#FFCFAA] rounded-r-[25px]">
              <div className="h-[153px] border-b-[1px] border-[#E6EDFF] flex items-center justify-center px-[15px]">
                <p className="text-[31px] font-[600] dark:text-[var(--dark-primary)]">
                  {hospitalProfile?.noOfAccount}
                </p>
              </div>
              <div className="h-[153px] border-b-[1px] border-[#E6EDFF] flex items-center justify-center px-[15px]">
                <p className="text-[31px] font-[600] dark:text-[var(--dark-primary)]">
                  {assignedAccounts}
                </p>
              </div>

              <div className="h-[153px]  flex items-center justify-center px-[15px]">
                <p className="text-[31px] font-[600] dark:text-[var(--dark-primary)]">
                  {hospitalProfile?.noOfAccount - assignedAccounts}
                </p>
              </div>
            </div>
          </div>
          <div className="w-[42%]">
            <div className="flex items-center gap-[40px]">
              <div
                className="w-[233px] h-[210px] cursor-pointer bg-[#DCA071] dark:bg-[#FFCFAA] gap-4 rounded-[18px] flex-col flex items-center justify-center text-[#fff] dark:text-[var(--dark-primary)]"
                onClick={handleNewDoctor}
              >
                <FaPlus size={83} />
                <p className="font-[500]">New Doctor</p>
              </div>
              <div
                className="w-[233px] h-[210px] cursor-pointer bg-[#67A599] dark:bg-[var(--dark-primary)] gap-4 flex-col rounded-[18px] flex items-center justify-center text-[#fff]"
                onClick={handleNewDepartment}
              >
                <FaPlus size={83} />
                <p className="font-[500]">New Department</p>
              </div>
            </div>
            {/* <div className="mt-[20px]">
              <h3 className="text-[18px] mb-[15px]">Pending Authorisations</h3>
              <div className="w-[100%] h-[188px] bg-[#67A599] dark:bg-[var(--dark-primary)] rounded-[18px] flex items-center justify-center flex-col">
                <p className="text-[18px] font-[500] text-[#fff]">
                  You have {pendingAccounts} pending authorisation
                </p>
                <button className=" rounded-full mt-[20px] w-[162px] h-[33px] bg-[#DCA071] text-[#fff] dark:bg-[#FFCFAA] dark:text-[var(--dark-primary)] font-[500] text-[14px]">
                  View
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Hsidebar>
  );
};

export default HospitalDashboardHome;
