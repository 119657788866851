import React, { useState } from "react";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { toast } from "react-toastify";
const LatestUpdate = () => {
  const [email, setEmail] = useState("");

  const handleSubscribe = () => {
    const data = { email };
    axios({
      method: "POST",
      url: `${BASE_URL}/patient/newsletter`,
      data: data,
    })
      .then((response) => {
        console.log("NewsLetter Success", response);
        toast.success("Submitted Successfully")
        setEmail("")
      })
      .catch((error) => {
        console.log("Newsletter ERROR", error.response);
      });
  };
  return (
    <>
      <div className="bg-[#CCE1DD] py-14 flex items-center justify-center flex-col text-[#161C2D] font-inter border-b ">
        <h3 className="font-[700] 600px:text-[48px]  text-[35px]">
          Get latest updates
        </h3>
        <p className="600px:w-[550px] px-4 text-center opacity-70">
          Stay connected and get the latest updates from us
        </p>
        <input
          type="text"
          placeholder=" Enter Your email"
          className="outline-none bg-white px-3 h-[50px] rounded-md 600px:w-[420px] w-[80%] mt-[40px]"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="bg-[#FFCFAA] text-[var(--doctor-color)] 600px:w-[420px] h-[50px] w-[80%] font-[600] rounded-md mt-4"
          onClick={handleSubscribe}
        >
          Subscribe
        </button>

        <p className="600px:w-[400px] w-[80%] text-center opacity-70 mt-4">
          We’ll never share your details with third parties. View our Privacy
          Policy for more info.
        </p>
      </div>
    </>
  );
};

export default LatestUpdate;
