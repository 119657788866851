import React from 'react'
import { useNavigate } from 'react-router-dom';
import ExpireToken from '../../Component/ExpireToken';

const PatientExpireToken = () => {
  const navigate = useNavigate();

  return (
    <>
      <ExpireToken handleClick={() => navigate("/patient/signin")} />

    </>
  )
}

export default PatientExpireToken;