import React, { useEffect, useState } from "react";
import SuperSidebar from "../../Component/SuperAdmin/Layout/SuperSidebar";
import { PiStethoscope } from "react-icons/pi";
import { TbBrandStackshare } from "react-icons/tb";
import { FaArrowUp } from "react-icons/fa6";
import { FaHospitalUser } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { IoIosMore } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

import { Line, Doughnut } from "react-chartjs-2";
import axios from "axios";
import BASE_URL from "../../baseUrl";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);
const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Septemper",
  "October",
  "November",
  "December",
];
export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};
export const data = {
  labels,
  datasets: [
    {
      label: "Translations",
      data: [5, 20, 30, 20, 30, 50, 10, 13, 34, 23, 60, 9],
      borderColor: "#FE981E",
      backgroundColor: "#FE981E",
    },
    {
      label: "Transcriptions",
      data: [20, 35, 30, 20, 40, 50, 20, 40, 26, 37, 22, 8],
      borderColor: "#0F123F",
      backgroundColor: "#0F123F",
    },
  ],
};
export const doData = {
  labels: ["Paid Accounts", "Free Accounts"],
  datasets: [
    {
      data: [72, 28],
      backgroundColor: ["#1F2253", "#FE981C"],
      borderWidth: 1,
    },
  ],
};
export const doOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
};
const SuperAnalytics = () => {
  const [isGenerateReport, setIsGenerateReport] = useState(false);
  const [superAnalyticsData, setSuperAnalyticsData] = useState();
  const [superTranscriptionData, setSuperTranscriptionData] = useState();
  useEffect(() => {
    loadSuperAnalytics();
  }, []);
  const loadSuperAnalytics = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/superadmin/analytics`,
    })
      .then((response) => {
        // setPatietnNumber(response?.data);
        console.log("Super Analytics", response?.data);
        setSuperAnalyticsData(response?.data);
      })
      .catch((error) => {
        console.log("Super Analytics Error  ERROR", error.response);
      });
  };

  useEffect(() => {
    loadSuperTranscriptionData();
  }, []);
  const loadSuperTranscriptionData = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/superadmin/transcription-data`,
    })
      .then((response) => {
        // setPatietnNumber(response?.data);
        console.log("Super Transcription", response?.data);
        setSuperTranscriptionData(response?.data);
      })
      .catch((error) => {
        console.log("Super Transcription Error ", error.response);
      });
  };

  return (
    <SuperSidebar activeLink="Analytics">
      <div className="relative">
        <div
          className={` ${
            isGenerateReport ? "h-[90vh] opacity-40 overflow-hidden" : ""
          } p-[20px]  `}
        >
          <div className="flex justify-between items-center">
            <h1 className="font-[500] text-2xl">Analytics</h1>
            <button
              className="rounded-full w-[162px] h-[33px] bg-[#FFCFAA] flex items-center justify-center text-[var(--doctor-color)]"
              onClick={() => setIsGenerateReport(true)}
            >
              Export Analytics
            </button>
          </div>
          <div className="w-[100%] text-[#fff] h-[210px] rounded-[28px] border-[1px] border-[#E6EDFF] my-[15px] bg-[#67A599] dark:bg-[var(--dark-primary)] flex ">
            <div className="w-[25%] border-r-[1px] border-[#E6EDF] my-[25px] px-[55px] flex  justify-center flex-col">
              <div className=" w-full flex items-center justify-between ">
                <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                {superAnalyticsData?.totalHospital}
                </p>
                <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                  <TbBrandStackshare
                    className="rotate-90 text-[#67A599]"
                    size={20}
                  />
                </div>
              </div>
              <p className="font-outfit font-[400] text-[16px]">
                Total Hospitals
              </p>
              <div className="flex gap-[10px] items-center font-outfit">
              {superAnalyticsData?.hospitalIncrease < 0 ? (
                <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
              ) : (
                <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
              )}
                {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                  10.2
                </p> */}
                <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                {Math.round(Math.abs(superAnalyticsData?.hospitalIncrease))}%
                &nbsp; this Month
                </p>
              </div>
              <button className="text-[12px] bg-[#FFCFAA] w-[130px] h-[25px] rounded-[13px] mt-[15px] text-[var(--doctor-color)] font-[500]">
                View Hospitals
              </button>
            </div>
            <div className="w-[25%] border-r-[1px] border-[#E6EDF] my-[25px] px-[55px] flex  justify-center flex-col">
              <div className=" w-full flex items-center justify-between ">
                <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                {superAnalyticsData?.totalDoctor}
                </p>
                <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                  <PiStethoscope className=" text-[#67A599]" size={20} />
                </div>
              </div>
              <p className="font-outfit font-[400] text-[16px]">
                Total Doctors
              </p>
              <div className="flex gap-[10px] items-center font-outfit">
              {superAnalyticsData?.doctorIncrease < 0 ? (
                <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
              ) : (
                <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
              )}
                {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                  10.2
                </p> */}
                <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                {Math.round(Math.abs(superAnalyticsData?.doctorIncrease))}%
                &nbsp; this Month
                </p>
              </div>
              <button className="text-[12px] bg-[#FFCFAA] w-[100px] h-[25px] rounded-[13px] mt-[15px] text-[var(--doctor-color)] font-[500]">
                View Doctors
              </button>
            </div>
            <div className="w-[25%] border-r-[1px] border-[#E6EDF] my-[25px] px-[55px] flex  justify-center flex-col">
              <div className=" w-full flex items-center justify-between ">
                <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                {superAnalyticsData?.totalPatient}
                </p>
                <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                  <FaHospitalUser className=" text-[#67A599]" size={20} />
                </div>
              </div>
              <p className="font-outfit font-[400] text-[16px]">
                Total Patients
              </p>
              <div className="flex gap-[10px] items-center font-outfit">
              {superAnalyticsData?.patientIncrease < 0 ? (
                  <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
                ) : (
                  <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
                )}
                <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                  3.2
                </p>
                <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                {Math.round(Math.abs(superAnalyticsData?.patientIncrease))}%
                &nbsp; this Month
                </p>
              </div>
              <button className="text-[12px] bg-[#FFCFAA] w-[100px] h-[25px] rounded-[13px] mt-[15px] text-[var(--doctor-color)] font-[500]">
                View Doctors
              </button>
            </div>
            <div className="w-[25%] my-[25px] px-[55px] flex  justify-center flex-col">
              <div className=" w-full flex items-center justify-between ">
                <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                {superTranscriptionData?.totalTranscriptions}
                </p>
                <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                  <PiStethoscope className=" text-[#67A599]" size={20} />
                </div>
              </div>
              <p className="font-outfit font-[400] text-[16px]">
                Total Transcript
              </p>
              <div className="flex gap-[10px] items-center font-outfit">
              {superTranscriptionData?.transcriptionChange < 0 ? (
                <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
              ) : (
                <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
              )}
                {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                  10.2
                </p> */}
                <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                {Math.round(
                  Math.abs(superTranscriptionData?.transcriptionChange)
                )}
                % &nbsp; this Month
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-4 w-full">
            <div className="w-[70%] ">
              <div className="p-[20px] bg-[#67A599] dark:bg-[var(--dark-primary)] w-[100%] h-[480px]  my-[20px] border-[1px] border-[#E6EDFF] rounded-[12px]">
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-[20px]">
                    <p>Transcript Analytics</p>

                    <select
                      id="countries"
                      className="w-[120px] px-[6px] h-[26px] outline-none bg-[#FFCFAA] rounded-[8px] text-[var(--doctor-color)] text-[12px]"
                    >
                      <option selected>All Doctors</option>
                      <option value="US">United States</option>
                      <option value="CA">Canada</option>
                      <option value="FR">France</option>
                      <option value="DE">Germany</option>
                    </select>
                  </div>

                  <div className="flex items-center gap-[15px]  mb-[25px]">
                    <div className="flex items-center gap-[10px]">
                      <div className="bg-[#0F123F] w-[8px] h-[8px] rounded-full"></div>
                      <div className="font-outfit text-[12px]">
                        Transcriptions
                      </div>
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <div className="bg-[#FE981E] w-[8px] h-[8px] rounded-full"></div>
                      <div className="font-outfit text-[12px]">
                        Translations
                      </div>
                    </div>
                    <select
                      id="countries"
                      className="w-[100px] px-[6px] h-[26px] outline-none bg-[#FFCFAA] rounded-[8px] text-[var(--doctor-color)] text-[12px]"
                    >
                      <option selected>Monthly</option>
                      <option value="US">Yearly</option>
                      <option value="CA">Weekly</option>
                      <option value="FR">Daily</option>
                    </select>
                  </div>
                </div>
                <div className="w-[99%] h-[430px]">
                  <Line className="" options={options} data={data} />
                </div>
              </div>
            </div>

            <div className=" relative w-[30%] h-[480px] bg-[#FFCFAA] border-[1px] border-[#E6EDFF] rounded-xl p-5">
              <div className="flex justify-between items-center mb-7">
                <p className="font-[500] text-white dark:text-black text-xl">
                  Accounts
                </p>
                <IoIosMore size={24} className="dark:text-black text-white" />
              </div>
              <Doughnut className="" data={doData} options={doOptions} />
              <div className="absolute top-[46%] left-[38%] dark:text-black text-white">
                <div className="flex flex-col justify-center items-center text-[12px] font-inter font-[500]">
                  <p>Total Accounts</p>
                  <span className="text-[25px]">100</span>
                </div>
              </div>

              <div className="flex items-center justify-center gap-5 mt-8">
                <div className="flex items-center gap-3">
                  <div className="w-2 h-2 bg-[#1B1F50] rounded-full"></div>
                  <p className="text-[12px] dark:text-black text-white font-inter font-[500]">
                    Paid Account
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <div className="w-2 h-2 bg-[#FE971B] rounded-full"></div>
                  <p className="text-[12px] dark:text-black text-white font-inter font-[500]">
                    Free Account
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isGenerateReport && (
          <>
            <div className="absolute top-[5%] w-[60%] h-[550px] pt-[25px] left-[20%] ">
              <div className=" bg-[#67A599]  dark:bg-[var(--dark-primary)] h-[500px] mt-[30px] text-[#fff] rounded-[23px] border-[1px] border-[#E6EDFF] flex items-center justify-center flex-col">
                <div className="w-[98%] flex justify-end text-[25px] -mt-[55px] ">
                  <RxCross2
                    className="cursor-pointer"
                    onClick={() => setIsGenerateReport(false)}
                  />
                </div>
                <h3 className="text-[20px] font-[500]">Generate Report</h3>
                <p className="text-[16px] font-[300] my-[20px]">
                  Generate a report for your assessments
                </p>

                <select
                  id="countries"
                  className=" my-[10px] w-[70%] h-[45px] outline-none font-inter text-[14px] bg-[#49615C] rounded-full border-[1px]  border-[#fff] text-[#fff] font-[600]  px-[10px]"
                >
                  <option selected>Monthly</option>
                  <option value="FR">Quarterly</option>
                  <option value="US">Yearly</option>
                
                </select>
                <input   
                  type="text"
                  placeholder="Email"
                  className="my-[10px] w-[70%] h-[45px] outline-none font-inter text-[14px] bg-[#49615C] rounded-full border-[1px]  border-[#fff] text-[#fff] font-[600]  px-[10px]"
                />

                {/* <select
                  id="countries"
                  className=" "
                >
                  <option selected>Email</option>
                  <option value="US">Yearly</option>
                  <option value="CA">Weekly</option>
                  <option value="FR">Daily</option>
                </select> */}

                <button className="bg-[#67A599] w-[70%] h-[45px] rounded-full my-[10px] text-[16px]">
                  admin@hospital.com
                </button>

                <button className=" mt-[60px] bg-[#FFCFAA] w-[50%] h-[45px] rounded-full text-[var(--doctor-color)] text-[14px] font-inter font-[700] ">
                  Generate & Export
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </SuperSidebar>
  );
};

export default SuperAnalytics;
