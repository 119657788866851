import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Patient from "../../images/patient/patient.png";
import NewLogo from "../../images/newLogo.png";
import VerifyOtpInput from "./VerifyOtpInput";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const TwofaVerify = () => {
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const dataNew = location.state;

  const handleOtpChange = (otp) => {
    setOtp(otp);
  };
  console.log(dataNew);
  const navigate = useNavigate()

  const handleVerifyOtp = async () => {
    const updateProfile = await axios({
      method: "POST",
      url: `${BASE_URL}/patient/verify-otp`,
      headers: {
        Authorization: `Bearer ${dataNew?.token}`,
      },
      data: { email: dataNew?.email, otp: otp },
    })
      .then((response) => {
        console.log("Verify Otp", response);
        toast.success(response?.data);
        navigate("/patient/dashboard")
      })
      .catch((error) => {
        console.log("verify Otp  Error", error.response.data);
        toast.error(error.response.data);
      });
  };
  return (
    <>
      <div
        className=" w-full 900px:h-screen h-[110vh] bg-[#CCE1DD] bg-no-repeat"
        style={{
          backgroundImage: `url(${Patient})`,
          backgroundPosition: "center",
          backgroundRepeat: "none",
        }}
      >
        <div className="900px:pl-[170px] flex 900px:justify-start justify-center items-center">
          <img src={NewLogo} alt="" />
        </div>
        <div className="flex justify-center 900px:flex-row flex-col ">
          <div className="900px:w-[50%] 900px:block hidden pl-[170px] h-[520px] -mt-[100px]  ">
            <div className="flex flex-col items-start justify-end  h-[520px] mt-6">
              <div className="flex justify-start items-start">
                <Link
                  to="/"
                  className="bg-[#FFCFAA] flex items-center justify-center mt-[25px] w-[200px] h-[50px] rounded-[10px] text-[var(--doctor-color)] font-[600] text-[16px]"
                >
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
          <div className="900px:w-[50%] w-full flex justify-evenly mt-[50px] 900px:mt-[10px]">
            <div className="500px:w-[450px] w-[90%] min-h-[430px]  bg-[rgba(1,104,85,0.7)]  rounded-[40px] px-6 py-6 font-inter">
              <h2 className="text-[30px] font-[500] text-white text-center mt-10">
                Confirm New Device
              </h2>
              <div className="flex justify-between my-4 text-center">
                <p className="text-[15px]">
                  We noticed that you’ve signed in with a new device, enter the
                  otp sent to your mail below for confirmation
                </p>
              </div>

              <div className="mt-4">
                <div className="mt-[30px] flex items-center justify-center w-full flex-col gap-20">
                  <VerifyOtpInput length={6} onChange={handleOtpChange} />
                  <button
                    className="bg-[#FFCFAA] w-[200px] h-[50px] rounded-[10px] text-[var(--doctor-color)] font-[600] text-[16px]"
                    style={{ boxShadow: "0px 4px 19px 0px #7793414D" }}
                    onClick={handleVerifyOtp}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwofaVerify;
