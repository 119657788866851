import React, { useContext, useEffect, useState } from "react";
import SuperSidebar from "../../Component/SuperAdmin/Layout/SuperSidebar";
import { PiStethoscope } from "react-icons/pi";
import { TbBrandStackshare } from "react-icons/tb";
import { FaArrowUp } from "react-icons/fa6";
import { FaHospitalUser } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useNavigate } from "react-router-dom";

import { Line, Pie } from "react-chartjs-2";
import GlobalStorage from "../../Storage/ContextProvider";
import axios from "axios";
import BASE_URL from "../../baseUrl";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);
const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Septemper",
  "October",
  "November",
  "December",
];
export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};
export const data = {
  labels,
  datasets: [
    {
      label: "Translations",
      data: [5, 20, 30, 20, 30, 50, 10, 13, 34, 23, 60, 9],
      borderColor: "#FE981E",
      backgroundColor: "#FE981E",
    },
    {
      label: "Transcriptions",
      data: [20, 35, 30, 20, 40, 50, 20, 40, 26, 37, 22, 8],
      borderColor: "#0F123F",
      backgroundColor: "#0F123F",
    },
  ],
};
export const pieOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
};
export const pieData = {
  labels: ["Closed Accounts", "Transitioned to Paid Account"],
  datasets: [
    {
      label: "Transition Statistics",
      data: [20, 80],
      backgroundColor: ["#EAF6ED", "#67A599"],
      borderColor: ["#fff", "#fff"],
      borderWidth: 1,
    },
  ],
};

const SuperDashboard = () => {
  const navigate = useNavigate();
  const { createHospital, setCreateHospital } = useContext(GlobalStorage);
  const [superAnalyticsData, setSuperAnalyticsData] = useState();
  const [superTranscriptionData, setSuperTranscriptionData] = useState();

  const handleCreteHos = () => {
    navigate("/superadmin/hospitals");
    setCreateHospital(true);
  };

  useEffect(() => {
    loadSuperAnalytics();
  }, []);
  const loadSuperAnalytics = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/superadmin/analytics`,
    })
      .then((response) => {
        // setPatietnNumber(response?.data);
        console.log("Super Analytics", response?.data);
        setSuperAnalyticsData(response?.data);
      })
      .catch((error) => {
        console.log("Super Analytics Error  ERROR", error.response);
      });
  };

  useEffect(() => {
    loadSuperTranscriptionData();
  }, []);
  const loadSuperTranscriptionData = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/superadmin/transcription-data`,
    })
      .then((response) => {
        // setPatietnNumber(response?.data);
        console.log("Super Transcription", response?.data);
        setSuperTranscriptionData(response?.data);
      })
      .catch((error) => {
        console.log("Super Transcription Error ", error.response);
      });
  };
  return (
    <SuperSidebar activeLink="Dashboard">
      <div className="p-5">
        <h1 className="font-[500] text-2xl">Dashboard</h1>
        <div className="w-[100%] text-[#fff] h-[210px] rounded-[28px] border-[1px] border-[#E6EDFF] my-[15px] bg-[#67A599] dark:bg-[var(--dark-primary)] flex ">
          <div className="w-[25%] border-r-[1px] border-[#E6EDF] my-[25px] px-[55px] flex  justify-center flex-col">
            <div className=" w-full flex items-center justify-between ">
              <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                {superAnalyticsData?.totalHospital}
              </p>
              <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                <TbBrandStackshare
                  className="rotate-90 text-[#67A599]"
                  size={20}
                />
              </div>
            </div>
            <p className="font-outfit font-[400] text-[16px]">
              Total Hospitals
            </p>
            <div className="flex gap-[10px] items-center font-outfit">
              {superAnalyticsData?.hospitalIncrease < 0 ? (
                <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
              ) : (
                <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
              )}

              {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                10.2
              </p> */}
              <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                {Math.round(Math.abs(superAnalyticsData?.hospitalIncrease))}%
                &nbsp; this Month
              </p>
            </div>
          </div>
          <div className="w-[25%] border-r-[1px] border-[#E6EDF] my-[25px] px-[55px] flex  justify-center flex-col">
            <div className=" w-full flex items-center justify-between ">
              <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                {superAnalyticsData?.totalDoctor}
              </p>
              <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                <PiStethoscope className=" text-[#67A599]" size={20} />
              </div>
            </div>
            <p className="font-outfit font-[400] text-[16px]">Total Doctors</p>
            <div className="flex gap-[10px] items-center font-outfit">
              {superAnalyticsData?.doctorIncrease < 0 ? (
                <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
              ) : (
                <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
              )}
              {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                10.2
              </p> */}
              <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                {Math.round(Math.abs(superAnalyticsData?.doctorIncrease))}%
                &nbsp; this Month
              </p>
            </div>
          </div>
          <div className="w-[25%] border-r-[1px] border-[#E6EDF] my-[25px] px-[55px] flex  justify-center flex-col">
            <div className=" w-full flex items-center justify-between ">
              <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                {superAnalyticsData?.totalPatient}
              </p>
              <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                {/* {superAnalyticsData?.patientIncrease < 0 ? (
                  <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
                ) : (
                  <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
                )} */}

                <FaHospitalUser
                  className=" text-[#67A599]"
                  size={20}
                />
              </div>
            </div>
            <p className="font-outfit font-[400] text-[16px]">Total Patients</p>
            <div className="flex gap-[10px] items-center font-outfit">
              {superAnalyticsData?.patientIncrease < 0 ? (
                <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
              ) : (
                <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
              )}
              {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                3.2
              </p> */}
              <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                {Math.round(Math.abs(superAnalyticsData?.patientIncrease))}%
                &nbsp; this Month
              </p>
            </div>
          </div>
          <div className="w-[25%] my-[25px] px-[55px] flex  justify-center flex-col">
            <div className=" w-full flex items-center justify-between ">
              <p className="text-[28px]  leading-[42px] font-outfit font-[600]">
                {superTranscriptionData?.totalTranscriptions}
              </p>
              <div className="w-[40px] h-[40px] bg-[#fff] rounded-[16px] flex items-center justify-center">
                <PiStethoscope className=" text-[#67A599]" size={20} />
              </div>
            </div>
            <p className="font-outfit font-[400] text-[16px]">
              Total Transcript
            </p>
            <div className="flex gap-[10px] items-center font-outfit">
              {superTranscriptionData?.transcriptionChange < 0 ? (
                <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" />
              ) : (
                <FaArrowUp className="rotate-45 text-[#00FF40] dark:text-[#34C759]" />
              )}
              {/* <FaArrowUp className="-rotate-[135deg] text-[#FF3B30]" /> */}
              {/* <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                10.2
              </p> */}
              <p className="dark:text-[#7C8DB5] text-[#032065] text-[14px]">
                {Math.round(
                  Math.abs(superTranscriptionData?.transcriptionChange)
                )}
                % &nbsp; this Month
              </p>
            </div>
          </div>
        </div>

        <div className="">
          <h3 className="font-[500] text-lg mt-6">Analytics</h3>
          <div className="flex items-center gap-6">
            <div className="w-[40%] flex flex-col gap-5">
              <div
                className="h-[110px] w-full bg-[#67A599] cursor-pointer text-white dark:bg-[var(--dark-primary)] rounded-2xl flex items-center justify-center flex-col"
                onClick={handleCreteHos}
              >
                <FaPlus size={42} />
                <p className="font-[500] text-lg">New Hospital</p>
              </div>
              <div className="w-full h-[270px] bg-[#DCA071]  dark:bg-[#FFCFFA]  rounded-[18px] px-[20px]">
                <div className="flex justify-between items-center">
                  <p className="font-[500] text-white dark:text-[var(--doctor-color)] text-[20px] my-[10px] ">
                    Transition Statistics
                  </p>
                  <select
                    id="countries"
                    className="w-[100px] px-[6px] h-[26px] outline-none bg-[#67A599] dark:bg-[var(--doctor-color)] rounded-[8px] text-[#fff] text-[12px]"
                  >
                    <option selected>Monthly</option>
                    <option value="US">Yearly</option>
                    <option value="CA">Weekly</option>
                    <option value="FR">Daily</option>
                  </select>
                </div>
                <div className="flex  justify-between items-center">
                  <div className="flex justify-between h-[180px] gap-5   items-center  ">
                    <Pie className="" data={pieData} options={pieOptions} />
                    <div className="dark:text-[#000] text-white text-[15px] flex flex-col gap-5">
                      <div className="flex gap-3 items-center">
                        <div className="w-[11px] h-[11px] rounded-full bg-[#fff]"></div>
                        <p>Closed Accounts</p>
                      </div>
                      <div className="flex gap-3 items-center">
                        <div className="w-[11px] h-[11px] rounded-full bg-[#67A599]"></div>
                        <p>Transitioned to Paid Account</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className=" dark:text-[#000] text-white text-[15px] font-[500] ">
                  Free Trial Issued :{" "}
                  <span className="text-white dark:text-[var(--doctor-color)]">
                    20
                  </span>
                </p>
              </div>
            </div>
            <div className="w-[60%]">
              <div className="p-[20px] bg-[#67A599] dark:bg-[var(--dark-primary)] w-[100%] h-[400px]  my-[20px] border-[1px] border-[#E6EDFF] rounded-[12px]">
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-[20px]">
                    <p>Transcript Analytics</p>

                    <select
                      id="countries"
                      className="w-[120px] px-[6px] h-[26px] outline-none bg-[#FFCFAA] rounded-[8px] text-[var(--doctor-color)] text-[12px]"
                    >
                      <option selected>All Doctors</option>
                      <option value="US">United States</option>
                      <option value="CA">Canada</option>
                      <option value="FR">France</option>
                      <option value="DE">Germany</option>
                    </select>
                  </div>

                  <div className="flex items-center gap-[15px]  mb-[25px]">
                    <div className="flex items-center gap-[10px]">
                      <div className="bg-[#0F123F] w-[8px] h-[8px] rounded-full"></div>
                      <div className="font-outfit text-[12px]">
                        Transcriptions
                      </div>
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <div className="bg-[#FE981E] w-[8px] h-[8px] rounded-full"></div>
                      <div className="font-outfit text-[12px]">
                        Translations
                      </div>
                    </div>
                    <select
                      id="countries"
                      className="w-[100px] px-[6px] h-[26px] outline-none bg-[#FFCFAA] rounded-[8px] text-[var(--doctor-color)] text-[12px]"
                    >
                      <option selected>Monthly</option>
                      <option value="US">Yearly</option>
                      <option value="CA">Weekly</option>
                      <option value="FR">Daily</option>
                    </select>
                  </div>
                </div>
                <div className="w-[99%] h-[350px]">
                  <Line className="w-[100%]" options={options} data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SuperSidebar>
  );
};

export default SuperDashboard;
